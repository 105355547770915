export var gameFont = 'Verdana,Arial,Helvetica,sans-serif';
export function getUrlParams() {
    var queryString = window.location.search.slice(1);
    if (!queryString)
        return {};
    var params = {};
    queryString.split('&').forEach(function (pairString) {
        var pair = pairString.split('=');
        params[pair[0]] = pair[1];
    });
    return params;
}
export function roundedRect(ctx, x, y, width, height, cornerRadius) {
    var radius = { tl: cornerRadius, tr: cornerRadius, br: cornerRadius, bl: cornerRadius };
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
}
var countWrites = new Map();
var countReads = new Map();
function debugLogTotalReadsAndWrites(system) {
    /*
    console.log(`Firestore operations: ${total(countReads, system)} reads, ${
        total(countWrites, system)} writes`);
    */
}
var _firebaseRef;
export function utilSetFirebaseRef(firebaseRef) {
    _firebaseRef = firebaseRef;
}
function increment(count, system) {
    if (!count.has(system))
        count.set(system, 0);
    count.set(system, count.get(system) + 1);
}
function total(count, system) {
    if (count.has(system))
        return count.get(system);
    return 0;
}
export function logReadWriteOperation(type, system) {
    if (system === void 0) { system = 'firestore'; }
    increment(countWrites, system);
    increment(countReads, system);
    if (_firebaseRef) {
        _firebaseRef.analytics().logEvent(system + "_read", {
            'read_domain': type,
            'total_reads': total(countReads, system),
            'total_writes': total(countWrites, system)
        });
        _firebaseRef.analytics().logEvent(system + "_write", {
            'write_domain': type,
            'total_reads': total(countReads, system),
            'total_writes': total(countWrites, system),
        });
    }
    debugLogTotalReadsAndWrites(system);
}
export function logWriteOperation(type, system) {
    if (system === void 0) { system = 'firestore'; }
    increment(countWrites, system);
    if (_firebaseRef) {
        _firebaseRef.analytics().logEvent(system + "_write", {
            'write_domain': type,
            'total_reads': total(countReads, system),
            'total_writes': total(countWrites, system),
        });
    }
    debugLogTotalReadsAndWrites(system);
}
export function logReadOperation(type, system) {
    if (system === void 0) { system = 'firestore'; }
    increment(countReads, system);
    if (_firebaseRef) {
        _firebaseRef.analytics().logEvent(system + "_read", {
            'read_domain': type,
            'total_reads': total(countReads, system),
            'total_writes': total(countWrites, system),
        });
    }
    debugLogTotalReadsAndWrites(system);
}
export function keyValueArrayToObject(array) {
    var o = {};
    array.forEach(function (kv) {
        o[kv[0]] = kv[1];
    });
    return o;
}
var hexDigits = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'
];
// Function to convert rgb color to hex format
function rgb2hex(rgb) {
    var rgbVal = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (!rgbVal)
        return null;
    return '#' + hex(+rgbVal[1]) + hex(+rgbVal[2]) + hex(+rgbVal[3]);
}
function hex(x) {
    return isNaN(x) ? '00' : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
}
export function getAsHexColor(strColor) {
    var s = new Option().style;
    s.color = strColor;
    // return 'false' if color wasn't assigned
    if (!s.color) {
        return null;
    }
    var d = document.createElement('div');
    d.style.color = strColor;
    document.body.appendChild(d);
    var computedColor = window.getComputedStyle(d).color;
    document.body.removeChild(d);
    return rgb2hex(computedColor);
}
export function isColorLight(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        return false;
    }
    var r = parseInt(hex.slice(0, 2), 16);
    var g = parseInt(hex.slice(2, 4), 16);
    var b = parseInt(hex.slice(4, 6), 16);
    return (r + b + g > 600);
}
export function rangeArray(start, count) {
    var arr = new Array();
    for (var i = 0; i < count; i++) {
        arr.push(start + i);
    }
    return arr;
}
export function sleep(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
