import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import constants from './constants';
import { gameConverter, pileDataConverter, sheetConverter } from './converters';
import { firebase } from './firebase';
import { getGameInstance } from './game-page';
import { PileFacing, PlaceholderStyle, UXMode } from './types';
import { keyValueArrayToObject, logReadWriteOperation, rangeArray, sleep } from './utils';
var allowPushToRemote = true;
var pendingPushWhileDisallowed = false;
var pendingPiles;
function getPileStyle(piles, pileId) {
    var idx = piles.findIndex(function (pile) { return (pile.id == pileId); });
    if (idx == -1)
        return getGameInstance().pileStyles.get('default');
    if (piles[idx].overrideStyle)
        return piles[idx].overrideStyle;
    var styleId = piles[idx].style;
    if (!getGameInstance().pileStyles.has(styleId))
        return getGameInstance().pileStyles.get('default');
    return getGameInstance().pileStyles.get(styleId);
}
function cleanupEmptyPiles(piles) {
    var newPiles = new Array();
    piles.forEach(function (pile) {
        var style = getPileStyle(piles, pile.id);
        if (style.placeholder != PlaceholderStyle.None || pile.cards.length > 0) {
            newPiles.push(pile);
        }
    });
    return newPiles;
}
export function setAllowPushToRemote(gameId, b) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(!allowPushToRemote && b && pendingPushWhileDisallowed)) return [3 /*break*/, 2];
                    // If we tried to push while it was disallowed, and it's now allowed;
                    allowPushToRemote = true;
                    pendingPushWhileDisallowed = false;
                    return [4 /*yield*/, commitNoop(gameId, true)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    allowPushToRemote = b;
                    return [2 /*return*/];
            }
        });
    });
}
export function commitNoop(gameId, remoteOnly) {
    if (remoteOnly === void 0) { remoteOnly = false; }
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles, gameData) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            // no-op
                            if (pendingPiles) {
                                piles = pendingPiles;
                                pendingPiles = undefined;
                            }
                            return [2 /*return*/, piles];
                        });
                    }); }, !!pendingPiles)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function changePileStyles(gameId, pileStyles, clearVisibilityList, clearStyleOverrides) {
    var _this = this;
    if (clearVisibilityList === void 0) { clearVisibilityList = false; }
    if (clearStyleOverrides === void 0) { clearStyleOverrides = false; }
    changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
        var _loop_1, _i, _a, pileId;
        return __generator(this, function (_b) {
            _loop_1 = function (pileId) {
                var pile = piles.find(function (pile) { return pile.id == pileId; });
                var style = pileStyles.get(pileId);
                if (!pile) {
                    throw ('Could not find pile.');
                }
                pile.style = style;
                if (clearVisibilityList) {
                    pile.visibleToPlayers = new Array();
                }
                if (clearStyleOverrides) {
                    pile.overrideStyle = undefined;
                }
                // If the pile is UXMode.SortCards, sort it now.
                if (getPileStyle(piles, pile.id).uxMode & UXMode.SortCards) {
                    pile.cards = pile.cards.sort(function (a, b) {
                        var x = +a.id.replace(/card/, '');
                        var y = +b.id.replace(/card/, '');
                        return x - y;
                    });
                }
            };
            for (_i = 0, _a = Array.from(pileStyles.keys()); _i < _a.length; _i++) {
                pileId = _a[_i];
                _loop_1(pileId);
            }
            return [2 /*return*/, piles];
        });
    }); });
}
export function changePileStyleOverrides(gameId, pileStyles) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var _loop_2, _i, _a, pileId;
                        return __generator(this, function (_b) {
                            _loop_2 = function (pileId) {
                                var pile = piles.find(function (pile) { return pile.id == pileId; });
                                var style = pileStyles.get(pileId);
                                if (!pile) {
                                    throw ('Could not find pile.');
                                }
                                // set the style options now
                                if (style) {
                                    pile.overrideStyle = __assign({}, style);
                                }
                                else {
                                    pile.overrideStyle = undefined;
                                }
                                // If the pile is UXMode.SortCards, sort it now.
                                if (getPileStyle(piles, pile.id).uxMode & UXMode.SortCards) {
                                    pile.cards = pile.cards.sort(function (a, b) {
                                        var x = +a.id.replace(/card/, '');
                                        var y = +b.id.replace(/card/, '');
                                        return x - y;
                                    });
                                }
                            };
                            for (_i = 0, _a = Array.from(pileStyles.keys()); _i < _a.length; _i++) {
                                pileId = _a[_i];
                                _loop_2(pileId);
                            }
                            return [2 /*return*/, piles];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function changePileDrawCardsOnReset(gameId, drawSettings) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var _loop_3, _i, _a, pileId;
                        return __generator(this, function (_b) {
                            _loop_3 = function (pileId) {
                                var pile = piles.find(function (pile) { return pile.id == pileId; });
                                var setting = drawSettings.get(pileId);
                                if (!pile) {
                                    throw ('Could not find pile.');
                                }
                                // set the style options now
                                if (setting && setting.length > 0) {
                                    pile.dealSettings = __spreadArrays(setting);
                                }
                                else {
                                    pile.dealSettings = undefined;
                                }
                            };
                            for (_i = 0, _a = Array.from(drawSettings.keys()); _i < _a.length; _i++) {
                                pileId = _a[_i];
                                _loop_3(pileId);
                            }
                            return [2 /*return*/, piles];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function changePileRotations(gameId, pileRotations) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var _loop_4, _i, _a, pileId;
                        return __generator(this, function (_b) {
                            _loop_4 = function (pileId) {
                                var pile = piles.find(function (pile) { return pile.id == pileId; });
                                var rotation = pileRotations.get(pileId);
                                if (!pile) {
                                    throw ('Could not find pile.');
                                }
                                pile.rotation = (rotation >= 0 ? rotation : undefined);
                            };
                            for (_i = 0, _a = Array.from(pileRotations.keys()); _i < _a.length; _i++) {
                                pileId = _a[_i];
                                _loop_4(pileId);
                            }
                            return [2 /*return*/, piles];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function changePileLabelSizes(gameId, pileLabelSizes) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var _loop_5, _i, _a, pileId;
                        return __generator(this, function (_b) {
                            _loop_5 = function (pileId) {
                                var pile = piles.find(function (pile) { return pile.id == pileId; });
                                var labelSize = pileLabelSizes.get(pileId);
                                if (!pile) {
                                    throw ('Could not find pile.');
                                }
                                pile.labelSize = (labelSize >= 0 ? labelSize : undefined);
                            };
                            for (_i = 0, _a = Array.from(pileLabelSizes.keys()); _i < _a.length; _i++) {
                                pileId = _a[_i];
                                _loop_5(pileId);
                            }
                            return [2 /*return*/, piles];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function changePileScales(gameId, pileScales) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var _loop_6, _i, _a, pileId;
                        return __generator(this, function (_b) {
                            _loop_6 = function (pileId) {
                                var pile = piles.find(function (pile) { return pile.id == pileId; });
                                var scale = pileScales.get(pileId);
                                if (!pile) {
                                    throw ('Could not find pile.');
                                }
                                pile.cardScaleX = (scale.x > 0 ? scale.x : undefined);
                                pile.cardScaleY = (scale.y > 0 ? scale.y : undefined);
                            };
                            for (_i = 0, _a = Array.from(pileScales.keys()); _i < _a.length; _i++) {
                                pileId = _a[_i];
                                _loop_6(pileId);
                            }
                            return [2 /*return*/, piles];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function changePileLabels(gameId, pileLabels) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var _loop_7, _i, _a, pileId;
                        return __generator(this, function (_b) {
                            _loop_7 = function (pileId) {
                                var pile = piles.find(function (pile) { return pile.id == pileId; });
                                var label = pileLabels.get(pileId);
                                if (!pile) {
                                    throw ('Could not find pile.');
                                }
                                pile.label = label;
                            };
                            for (_i = 0, _a = Array.from(pileLabels.keys()); _i < _a.length; _i++) {
                                pileId = _a[_i];
                                _loop_7(pileId);
                            }
                            return [2 /*return*/, piles];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function changePileHelpTexts(gameId, pileHelpTexts) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var _loop_8, _i, _a, pileId;
                        return __generator(this, function (_b) {
                            _loop_8 = function (pileId) {
                                var pile = piles.find(function (pile) { return pile.id == pileId; });
                                var helpText = pileHelpTexts.get(pileId);
                                if (!pile) {
                                    throw ('Could not find pile.');
                                }
                                pile.helpText = helpText;
                            };
                            for (_i = 0, _a = Array.from(pileHelpTexts.keys()); _i < _a.length; _i++) {
                                pileId = _a[_i];
                                _loop_8(pileId);
                            }
                            return [2 /*return*/, piles];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function moveCardsBetweenPiles(gameId, sourcePileId, destinationPileId, indices, destinationIndex, reverseMovedCards) {
    if (destinationIndex === void 0) { destinationIndex = -1; }
    if (reverseMovedCards === void 0) { reverseMovedCards = false; }
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var sourcePile, destinationPile, movedCards, styles, gamePile, adjust_1, destinationStyle, sourceStyle, childId_1, newPile, gamePile_1, pileToShiftId_1, pilesToShift_1, pileToShift, i, idx;
                        var _a;
                        return __generator(this, function (_b) {
                            sourcePile = piles.find(function (pile) { return pile.id == sourcePileId; });
                            destinationPile = piles.find(function (pile) { return pile.id == destinationPileId; });
                            if (!sourcePile || !destinationPile) {
                                throw 'Could not find source/destination pile.';
                            }
                            movedCards = new Array();
                            indices = indices.filter(function (i) { return (i >= 0 && i < sourcePile.cards.length); });
                            // Sort in descending order of index, then get the cards.
                            indices.sort(function (a, b) { return b - a; }).forEach(function (idx) {
                                movedCards.push(sourcePile.cards[idx]);
                                sourcePile.cards.splice(idx, 1);
                            });
                            if (!reverseMovedCards) {
                                // movedCards is already in reverse order due to the sorting above.
                                movedCards = movedCards.reverse();
                            }
                            if (!destinationPile.cards) {
                                destinationPile.cards = [];
                            }
                            styles = getGameInstance().pileStyles;
                            gamePile = getGameInstance().piles.get(destinationPile.id);
                            movedCards.forEach(function (card) {
                                var destinationStyle = getPileStyle(piles, destinationPile.id);
                                var facing = destinationStyle.facing;
                                if (facing != PileFacing.Any) {
                                    card.faceUp = (facing == PileFacing.ForceFaceUp);
                                }
                                else if (destinationPile.visibleToPlayers &&
                                    destinationPile.visibleToPlayers.length > 0) {
                                    card.faceUp = false;
                                }
                                else {
                                    var sourceStyle_1 = getPileStyle(piles, sourcePileId);
                                    if (sourceStyle_1.facing != PileFacing.Any) {
                                        card.faceUp = (sourceStyle_1.facing == PileFacing.ForceFaceUp);
                                    }
                                }
                                var rotation = gamePile ? gamePile.getRotation() : -1;
                                if (rotation != -1) {
                                    card.rotation = rotation;
                                }
                            });
                            if (destinationIndex == -1) {
                                movedCards.forEach(function (card) { return destinationPile.cards.push(card); });
                            }
                            else {
                                adjust_1 = 0;
                                if (sourcePileId == destinationPileId) {
                                    // Adjust the target position by the number of source indices below the
                                    // destination index.
                                    indices.forEach(function (idx) {
                                        if (idx < destinationIndex)
                                            adjust_1++;
                                    });
                                }
                                (_a = destinationPile.cards).splice.apply(_a, __spreadArrays([destinationIndex - adjust_1, 0], movedCards));
                            }
                            sourcePile.editedTime = 0;
                            destinationPile.editedTime = 0;
                            destinationStyle = getPileStyle(piles, destinationPile.id);
                            sourceStyle = getPileStyle(piles, sourcePile.id);
                            // If the destination pile is UXMode.SortCards, sort it now.
                            if (destinationStyle.uxMode & UXMode.SortCards) {
                                destinationPile.cards = destinationPile.cards.sort(function (a, b) {
                                    var x = +a.id.replace(/card/, '');
                                    var y = +b.id.replace(/card/, '');
                                    return x - y;
                                });
                            }
                            // If the destination pile is a UXMode.AutoTableau and there is no
                            // child pile, create it now.
                            if (destinationStyle.uxMode & UXMode.AutoTableau) {
                                childId_1 = destinationPile.id + '+1';
                                if (!piles.find(function (pile) { return pile.id == childId_1; })) {
                                    newPile = __assign(__assign({}, destinationPile), { cards: [], id: childId_1 });
                                    gamePile_1 = getGameInstance().piles.get(destinationPile.id);
                                    if (gamePile_1) {
                                        if (destinationStyle.splayPctX) {
                                            newPile.y += gamePile_1.sizeHint.height * 1.1;
                                        }
                                        else {
                                            newPile.x += gamePile_1.sizeHint.width * 1.1;
                                        }
                                        piles.push(newPile);
                                    }
                                }
                            }
                            // If the source pile is auto-tableau and is now empty, shift all
                            // subsequent children down a notch.
                            if ((sourceStyle.uxMode & UXMode.AutoTableau) &&
                                sourcePile.cards.length == 0) {
                                pileToShiftId_1 = sourcePileId;
                                pilesToShift_1 = new Array();
                                do {
                                    pileToShift = piles.find(function (pile) { return pile.id == pileToShiftId_1; });
                                    if (!pileToShift)
                                        break;
                                    pilesToShift_1.push(pileToShift);
                                    pileToShiftId_1 += '+1';
                                } while (true);
                                // delete pilesToShift[n]
                                for (i = 0; i < pilesToShift_1.length - 1; i++) {
                                    pilesToShift_1[i].cards = __spreadArrays(pilesToShift_1[i + 1].cards);
                                }
                                idx = piles.findIndex(function (pile) { return pile.id == pilesToShift_1[pilesToShift_1.length - 1].id; });
                                if (idx != -1)
                                    piles.splice(idx, 1);
                            }
                            piles = cleanupEmptyPiles(piles);
                            return [2 /*return*/, piles];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function moveCardsToNewPile(gameId, sourcePileId, newPileId, x, y, indices, newPileStyle) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles) { return __awaiter(_this, void 0, void 0, function () {
                        var sourcePile, movedCards, sourceStyle, newPile, pileToShiftId_2, pilesToShift_2, pileToShift, i, idx;
                        return __generator(this, function (_a) {
                            sourcePile = piles.find(function (pile) { return pile.id == sourcePileId; });
                            if (!sourcePile) {
                                throw 'Could not find source pile.';
                            }
                            movedCards = new Array();
                            // Sort in descending order of index, then get the cards.
                            indices = indices.filter(function (i) { return (i >= 0 && i < sourcePile.cards.length); });
                            indices.sort(function (a, b) { return b - a; }).forEach(function (idx) {
                                movedCards.push(sourcePile.cards[idx]);
                                sourcePile.cards.splice(idx, 1);
                            });
                            movedCards = movedCards.reverse();
                            sourcePile.editedTime = 0;
                            sourceStyle = getPileStyle(piles, sourcePileId);
                            if (sourceStyle.facing != PileFacing.Any) {
                                // Make sure the cards have whatever their pile's last orientation is.
                                movedCards.forEach(function (card) {
                                    card.faceUp = (sourceStyle.facing == PileFacing.ForceFaceUp);
                                });
                            }
                            newPile = {
                                id: newPileId,
                                x: x,
                                y: y,
                                cards: movedCards,
                                style: 'default',
                                editedTime: 0,
                            };
                            if (newPileStyle) {
                                newPile['overrideStyle'] = newPileStyle;
                            }
                            // If the source pile is auto-tableau and is now empty, shift all
                            // subsequent children down a notch.
                            if ((sourceStyle.uxMode & UXMode.AutoTableau) &&
                                sourcePile.cards.length == 0) {
                                pileToShiftId_2 = sourcePileId;
                                pilesToShift_2 = new Array();
                                do {
                                    pileToShift = piles.find(function (pile) { return pile.id == pileToShiftId_2; });
                                    if (!pileToShift)
                                        break;
                                    pilesToShift_2.push(pileToShift);
                                    pileToShiftId_2 += '+1';
                                } while (true);
                                // delete pilesToShift[n]
                                for (i = 0; i < pilesToShift_2.length - 1; i++) {
                                    pilesToShift_2[i].cards = __spreadArrays(pilesToShift_2[i + 1].cards);
                                }
                                idx = piles.findIndex(function (pile) { return pile.id == pilesToShift_2[pilesToShift_2.length - 1].id; });
                                if (idx != -1)
                                    piles.splice(idx, 1);
                            }
                            piles = cleanupEmptyPiles(piles);
                            return [2 /*return*/, __spreadArrays(piles, [newPile])];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function shufflePile(gameId, pileId) {
    changeSinglePile(gameId, pileId, function (pile) {
        shuffleArray(pile.cards);
        pile.editedTime = 0;
    }, true);
}
export function updatePileTimestamp(gameId, pileId) {
    changeSinglePile(gameId, pileId, function (pile) {
        pile.editedTime = 0;
    }, true);
}
export function flipPile(gameId, pileId, indices, reverseCards) {
    if (indices === void 0) { indices = null; }
    if (reverseCards === void 0) { reverseCards = true; }
    changeSinglePile(gameId, pileId, function (pile) {
        if (indices == null)
            indices = rangeArray(0, pile.cards.length);
        // Sort in ascending order.
        indices = indices.filter(function (i) { return (i >= 0 && i < pile.cards.length); });
        indices = indices.sort(function (a, b) { return a - b; });
        indices.forEach(function (idx) {
            pile.cards[idx].faceUp = !pile.cards[idx].faceUp;
        });
        if (reverseCards) {
            var oldCards = __spreadArrays(pile.cards);
            for (var i = 0; i < indices.length; ++i) {
                var idx1 = indices[i];
                var idx2 = indices[indices.length - i - 1];
                if (idx1 != idx2)
                    oldCards[idx1] = __assign({}, pile.cards[idx2]);
                oldCards[idx2] = __assign({}, pile.cards[idx1]);
            }
            pile.cards = oldCards;
        }
        pile.editedTime = 0;
    });
}
export function movePile(gameId, pileId, x, y) {
    changeSinglePile(gameId, pileId, function (pile) {
        pile.x = x;
        pile.y = y;
        pile.editedTime = 0;
    });
}
export function rotatePile(gameId, pileId, angleDiff, indices) {
    changeSinglePile(gameId, pileId, function (pile) {
        if (indices == null)
            indices = rangeArray(0, pile.cards.length);
        indices = indices.filter(function (i) { return (i >= 0 && i < pile.cards.length); });
        indices.forEach(function (idx) {
            pile.cards[idx].rotation = ((pile.cards[idx].rotation + angleDiff) % 360);
        });
        pile.editedTime = 0;
    });
}
export function getNumPlayersForReset(piles) {
    var numPlayers = getGameInstance().players.keys().length;
    // Special handling, if any deals are visibility limited,
    // then we only count unique pile visibilities rather than players.
    var anyDealIsVisibilityLimited = false;
    piles.forEach(function (pile) {
        if (pile.dealSettings)
            pile.dealSettings.forEach(function (setting) {
                if (setting.onlyIfAssignedPlayer)
                    anyDealIsVisibilityLimited = true;
            });
    });
    if (anyDealIsVisibilityLimited) {
        var visibilities_1 = new Set();
        piles.forEach(function (pile) {
            if (!pile.visibleToPlayers)
                return;
            if (pile.visibleToPlayers.length == 0)
                return;
            visibilities_1.add(pile.visibleToPlayers.sort().join(','));
        });
        numPlayers = Math.min(numPlayers, visibilities_1.size);
    }
    return numPlayers;
}
export function resetGame(gameId, onlyIfSourceGame) {
    if (onlyIfSourceGame === void 0) { onlyIfSourceGame = false; }
    return __awaiter(this, void 0, void 0, function () {
        var alreadyShuffledCards, game, sourceGameData_1, pendingShuffles_1, pendingDraws_1, _loop_9, state_1;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    alreadyShuffledCards = new Map();
                    if (getGameInstance().animation) {
                        // When resetting, clear all animations.
                        getGameInstance().animation.deleteGame();
                    }
                    game = getGameInstance();
                    if (!(game && game.gameId == gameId && game.lastGameData &&
                        !game.lastGameData.sourceTemplateId)) return [3 /*break*/, 2];
                    return [4 /*yield*/, changePiles(gameId, function (oldPiles, gameData) { return __awaiter(_this, void 0, void 0, function () {
                            var piles, newPile_1;
                            return __generator(this, function (_a) {
                                if (!onlyIfSourceGame) {
                                    piles = oldPiles;
                                    piles.forEach(function (pile) {
                                        while (pile.cards.length > 0) {
                                            pile.cards.pop();
                                        }
                                    });
                                    piles = cleanupEmptyPiles(piles);
                                    newPile_1 = {
                                        id: getGameInstance().uniquePileId(),
                                        x: 0,
                                        y: 0,
                                        cards: [],
                                        style: 'default',
                                        editedTime: 0,
                                    };
                                    getGameInstance().cards.forEach(function (card) {
                                        newPile_1.cards.push({
                                            id: card.id,
                                            faceUp: true,
                                            rotation: card.getRotation() ? card.getRotation() : 0
                                        });
                                    });
                                    return [2 /*return*/, __spreadArrays(piles, [newPile_1])];
                                }
                                else {
                                    return [2 /*return*/, oldPiles];
                                }
                                return [2 /*return*/];
                            });
                        }); })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 2:
                    sourceGameData_1 = null;
                    return [4 /*yield*/, changePiles(gameId, function (oldPiles, gameData) { return __awaiter(_this, void 0, void 0, function () {
                            var pileVisibilies, sourceGame, piles;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        pileVisibilies = new Map();
                                        oldPiles.forEach(function (pile) {
                                            if (pile.visibleToPlayers)
                                                pileVisibilies.set(pile.id, __spreadArrays(pile.visibleToPlayers));
                                        });
                                        return [4 /*yield*/, firebase.firestore()
                                                .collection('games')
                                                .doc(gameData.sourceTemplateId)
                                                .withConverter(gameConverter)
                                                .get()];
                                    case 1:
                                        sourceGame = _a.sent();
                                        sourceGameData_1 = sourceGame.data() ? sourceGame.data() : null;
                                        piles = sourceGameData_1.piles;
                                        piles.forEach(function (pile) {
                                            if (pileVisibilies.has(pile.id))
                                                pile.visibleToPlayers = __spreadArrays(pileVisibilies.get(pile.id));
                                        });
                                        return [2 /*return*/, piles];
                                }
                            });
                        }); })];
                case 3:
                    _a.sent();
                    if (!sourceGameData_1) return [3 /*break*/, 5];
                    return [4 /*yield*/, firebase.firestore().collection('games').doc(gameId).update({
                            cardScale: sourceGameData_1.cardScale,
                            isDragVertical: sourceGameData_1.isDragVertical ? true : null,
                            isDropVertical: sourceGameData_1.isDropVertical ? true : null
                        })];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: 
                // We need to shuffle piles and deal out cards to piles.
                //
                // The plan is:
                //
                // 1. Add all piles that need to be shuffled to pendingShuffles.
                //
                // 2. Add all piles that need to draw cards to pendingDraws.
                //
                // 3. For each pendingShuffle piles, if it is not in pendingDraws, shuffle
                // it and remote it from pendingShuffles.
                //
                // 4. For all pendingDraw piles, if their source is NOT in pendingShuffle,
                // pull cards and remove pile from pendingDraw..
                //
                // 5. Loop until there are no more pendingShuffle or pendingDraw piles, OR
                // if we did a cycle and the number of pendingShuffle or pendingDraw piles
                // did not change (in which case there's a cycle in the graph, oops. Print
                // an error and abort).
                return [4 /*yield*/, sleep(constants.RESTART_GAME_DEAL_START_MS)];
                case 6:
                    // We need to shuffle piles and deal out cards to piles.
                    //
                    // The plan is:
                    //
                    // 1. Add all piles that need to be shuffled to pendingShuffles.
                    //
                    // 2. Add all piles that need to draw cards to pendingDraws.
                    //
                    // 3. For each pendingShuffle piles, if it is not in pendingDraws, shuffle
                    // it and remote it from pendingShuffles.
                    //
                    // 4. For all pendingDraw piles, if their source is NOT in pendingShuffle,
                    // pull cards and remove pile from pendingDraw..
                    //
                    // 5. Loop until there are no more pendingShuffle or pendingDraw piles, OR
                    // if we did a cycle and the number of pendingShuffle or pendingDraw piles
                    // did not change (in which case there's a cycle in the graph, oops. Print
                    // an error and abort).
                    _a.sent();
                    pendingShuffles_1 = new Set();
                    pendingDraws_1 = new Set();
                    return [4 /*yield*/, changePiles(gameId, function (piles, gameData) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                pendingShuffles_1 = new Set();
                                pendingDraws_1 = new Set();
                                piles.forEach(function (pile) {
                                    var style = getPileStyle(piles, pile.id);
                                    if ((style.uxMode & UXMode.ShuffleOnReset) != 0)
                                        pendingShuffles_1.add(pile.id);
                                    if (pile.dealSettings && pile.dealSettings.length > 0)
                                        pendingDraws_1.add(pile.id);
                                });
                                return [2 /*return*/, piles];
                            });
                        }); })];
                case 7:
                    _a.sent();
                    _loop_9 = function () {
                        var didShuffle, didDraw, shuffleWaits, wasShuffleVisible, wasDrawVisible;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    didShuffle = new Set();
                                    didDraw = new Set();
                                    shuffleWaits = new Array();
                                    wasShuffleVisible = false;
                                    return [4 /*yield*/, changePiles(gameId, function (piles, gameData) { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        didShuffle = new Set();
                                                        wasShuffleVisible = false;
                                                        pendingShuffles_1.forEach(function (pileId) {
                                                            if (!pendingDraws_1.has(pileId)) {
                                                                var pile = piles.find(function (pile) { return pile.id == pileId; });
                                                                if (pile.cards.length > 1) {
                                                                    if (!alreadyShuffledCards.has(pile.id)) {
                                                                        shuffleArray(pile.cards);
                                                                        alreadyShuffledCards.set(pile.id, __spreadArrays(pile.cards));
                                                                        // Animate the shuffle if the pile isn't hidden.
                                                                        if (!(getPileStyle(piles, pileId).uxMode & UXMode.Hidden)) {
                                                                            if (getGameInstance().animation) {
                                                                                shuffleWaits.push(getGameInstance().animation.uploadPileShuffling(gameId, pile.id, true, constants.RESTART_GAME_DEAL_SHUFFLE_MS));
                                                                                shuffleWaits.push(getGameInstance().animation.uploadPileShuffling(gameId, pileId, false));
                                                                            }
                                                                        }
                                                                    }
                                                                    else {
                                                                        pile.cards = __spreadArrays(alreadyShuffledCards.get(pile.id));
                                                                    }
                                                                }
                                                                if (!(getPileStyle(piles, pileId).uxMode & UXMode.Hidden)) {
                                                                    wasShuffleVisible = true;
                                                                }
                                                                didShuffle.add(pileId);
                                                            }
                                                        });
                                                        return [4 /*yield*/, Promise.all(shuffleWaits)];
                                                    case 1:
                                                        _a.sent();
                                                        return [2 /*return*/, piles];
                                                }
                                            });
                                        }); })];
                                case 1:
                                    _a.sent();
                                    if (didShuffle.size > 0) {
                                        didShuffle.forEach(function (pileId) {
                                            pendingShuffles_1.delete(pileId);
                                        });
                                    }
                                    if (!wasShuffleVisible) return [3 /*break*/, 3];
                                    return [4 /*yield*/, sleep(constants.RESTART_GAME_DEAL_SHUFFLE_WAIT_MS)];
                                case 2:
                                    _a.sent();
                                    _a.label = 3;
                                case 3:
                                    wasDrawVisible = false;
                                    return [4 /*yield*/, changePiles(gameId, function (piles, gameData) { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                didDraw = new Set();
                                                wasDrawVisible = false;
                                                // Deal out cards to destination piles.
                                                pendingDraws_1.forEach(function (targetPileId) {
                                                    var target = piles.find(function (pile) { return pile.id == targetPileId; });
                                                    if (!target.dealSettings)
                                                        return;
                                                    var canFullyDeal = true;
                                                    target.dealSettings.forEach(function (dealSetting) {
                                                        var source = piles.find(function (pile) { return pile.id == dealSetting.fromPile; });
                                                        if (!source)
                                                            return;
                                                        if (pendingShuffles_1.has(source.id))
                                                            canFullyDeal = false;
                                                        if (pendingDraws_1.has(source.id))
                                                            canFullyDeal = false;
                                                    });
                                                    if (canFullyDeal) {
                                                        target.dealSettings.forEach(function (dealSetting) {
                                                            var source = piles.find(function (pile) { return pile.id == dealSetting.fromPile; });
                                                            if (!source)
                                                                return;
                                                            var shouldDraw = true;
                                                            if (dealSetting.onlyIfAssignedPlayer &&
                                                                (!target.visibleToPlayers ||
                                                                    target.visibleToPlayers.length == 0)) {
                                                                shouldDraw = false;
                                                                // Don't deal to this pile if it has no players.
                                                            }
                                                            var numPlayers = getNumPlayersForReset(piles);
                                                            if (dealSetting.onlyIfPlayerCountAtLeast &&
                                                                numPlayers < dealSetting.onlyIfPlayerCountAtLeast) {
                                                                shouldDraw = false;
                                                            }
                                                            if (dealSetting.onlyIfPlayerCountAtMost &&
                                                                numPlayers > dealSetting.onlyIfPlayerCountAtMost) {
                                                                shouldDraw = false;
                                                            }
                                                            if (shouldDraw) {
                                                                for (var i = 0; i < dealSetting.numCards; i++) {
                                                                    var card = source.cards.pop();
                                                                    if (card) {
                                                                        target.cards.push(card);
                                                                        var targetStyle_1 = getPileStyle(piles, target.id);
                                                                        if (targetStyle_1.uxMode & UXMode.SortCards) {
                                                                            target.cards = target.cards.sort(function (a, b) {
                                                                                var x = +a.id.replace(/card/, '');
                                                                                var y = +b.id.replace(/card/, '');
                                                                                return x - y;
                                                                            });
                                                                        }
                                                                        if (targetStyle_1.facing != PileFacing.Any) {
                                                                            card.faceUp =
                                                                                (targetStyle_1.facing == PileFacing.ForceFaceUp);
                                                                        }
                                                                        else {
                                                                            if (target.visibleToPlayers &&
                                                                                target.visibleToPlayers.length > 0) {
                                                                                card.faceUp = false;
                                                                            }
                                                                        }
                                                                        if (target.rotation != undefined && target.rotation != -1) {
                                                                            card.rotation = target.rotation;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            didDraw.add(targetPileId);
                                                            var targetStyle = getPileStyle(piles, targetPileId);
                                                            var sourceStyle = getPileStyle(piles, source.id);
                                                            if (!(targetStyle.uxMode & UXMode.Hidden) ||
                                                                !(sourceStyle.uxMode & UXMode.Hidden)) {
                                                                wasDrawVisible = true;
                                                            }
                                                            if (targetStyle.uxMode & UXMode.AutoTableau) {
                                                                // Handle card moving onto an AutoTableau pile.
                                                                var childId_2 = targetPileId + '+1';
                                                                if (!piles.find(function (pile) { return pile.id == childId_2; })) {
                                                                    var newPile = __assign(__assign({}, target), { cards: [], id: childId_2 });
                                                                    var gamePile = getGameInstance().piles.get(targetPileId);
                                                                    if (gamePile) {
                                                                        if (targetStyle.splayPctX) {
                                                                            newPile.y += gamePile.sizeHint.height * 1.1;
                                                                        }
                                                                        else {
                                                                            newPile.x += gamePile.sizeHint.width * 1.1;
                                                                        }
                                                                        piles.push(newPile);
                                                                    }
                                                                }
                                                            }
                                                        });
                                                    }
                                                });
                                                return [2 /*return*/, piles];
                                            });
                                        }); })];
                                case 4:
                                    _a.sent();
                                    didDraw.forEach(function (pileId) {
                                        pendingDraws_1.delete(pileId);
                                    });
                                    if (!wasDrawVisible) return [3 /*break*/, 6];
                                    return [4 /*yield*/, sleep(constants.RESTART_GAME_DEAL_DEAL_MS)];
                                case 5:
                                    _a.sent();
                                    _a.label = 6;
                                case 6:
                                    if (didDraw.size == 0 && didShuffle.size == 0) {
                                        return [2 /*return*/, "break"];
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _a.label = 8;
                case 8:
                    if (!(pendingShuffles_1.size > 0 || pendingDraws_1.size > 0)) return [3 /*break*/, 10];
                    return [5 /*yield**/, _loop_9()];
                case 9:
                    state_1 = _a.sent();
                    if (state_1 === "break")
                        return [3 /*break*/, 10];
                    return [3 /*break*/, 8];
                case 10: return [2 /*return*/];
            }
        });
    });
}
export function addEmptyNewPile(gameId, newPileId, x, y, style, overrideStyle) {
    var _this = this;
    changePiles(gameId, function (piles, gameData) { return __awaiter(_this, void 0, void 0, function () {
        var newPile;
        return __generator(this, function (_a) {
            newPile = {
                id: newPileId,
                x: x,
                y: y,
                style: style,
                cards: [],
                editedTime: 0,
            };
            if (overrideStyle)
                newPile.overrideStyle = overrideStyle;
            return [2 /*return*/, __spreadArrays(piles, [newPile])];
        });
    }); });
}
export function addMissingCardsToNewPile(gameId, newPileId) {
    var _this = this;
    changePiles(gameId, function (piles, gameData) { return __awaiter(_this, void 0, void 0, function () {
        var usedCards, docSnap, sheetData, newPile;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    usedCards = [];
                    piles.forEach(function (pile) { return pile.cards.forEach(function (card) { return usedCards.push(card.id); }); });
                    return [4 /*yield*/, firebase.firestore()
                            .collection('sheets')
                            .doc(gameData.sheetId)
                            .withConverter(sheetConverter)
                            .get()];
                case 1:
                    docSnap = _a.sent();
                    sheetData = docSnap.data();
                    newPile = {
                        id: newPileId,
                        x: 0,
                        y: 0,
                        cards: [],
                        editedTime: 0,
                    };
                    getGameInstance().cards.forEach(function (card) {
                        newPile.cards.push({ id: card.id, faceUp: card.isFaceUp(), rotation: card.getRotation() });
                    });
                    return [2 /*return*/, __spreadArrays(piles, [newPile])];
            }
        });
    }); });
}
export function setPileVisibility(gameId, pileId, players) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changeSinglePile(gameId, pileId, function (pile) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            pile.visibleToPlayers = players;
                            return [2 /*return*/];
                        });
                    }); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function updatePlayerScore(gameId, playerId, scoreChange) {
    return __awaiter(this, void 0, void 0, function () {
        var ref;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ref = firebase.database().ref("players/" + gameId + "/" + playerId + "/score");
                    if (!(scoreChange.startsWith('+') || scoreChange.startsWith('-'))) return [3 /*break*/, 2];
                    return [4 /*yield*/, ref.transaction(function (score) {
                            score = +score + parseInt(scoreChange, 10);
                            return score;
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    ref.set(parseInt(scoreChange, 10));
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
;
export function updatePlayerColor(gameId, playerId, customColor) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            firebase.database()
                .ref("players/" + gameId + "/" + playerId + "/customColor")
                .set(customColor);
            return [2 /*return*/];
        });
    });
}
;
function changeSinglePile(gameId, pileId, fn, remoteOnly) {
    if (remoteOnly === void 0) { remoteOnly = false; }
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, changePiles(gameId, function (piles, gameData) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            return [2 /*return*/, cleanupEmptyPiles(piles.map(function (pile) {
                                    if (pile.id == pileId) {
                                        fn(pile, gameData);
                                    }
                                    return pile;
                                }))];
                        });
                    }); }, remoteOnly)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function changePiles(gameId, fn, remoteOnly) {
    if (remoteOnly === void 0) { remoteOnly = false; }
    return __awaiter(this, void 0, void 0, function () {
        var game, gameData, _a, localGameData, db, ref_1;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getGameInstance().isInitialized];
                case 1:
                    _b.sent();
                    if (!!remoteOnly) return [3 /*break*/, 3];
                    game = getGameInstance();
                    if (!(game && game.gameId == gameId && game.lastGameData)) return [3 /*break*/, 3];
                    gameData = __assign({}, game.lastGameData);
                    _a = gameData;
                    return [4 /*yield*/, fn(gameData.piles, gameData)];
                case 2:
                    _a.piles = _b.sent();
                    if (!allowPushToRemote) {
                        pendingPiles = __spreadArrays(gameData.piles);
                    }
                    localGameData = __assign({}, gameData);
                    localGameData.piles.forEach(function (pile) {
                        if (pile.editedTime == 0)
                            pile.editedTime = firebase.firestore.Timestamp.now().toMillis();
                    });
                    game.processGameData(localGameData, true); // true = is local
                    _b.label = 3;
                case 3:
                    if (!allowPushToRemote) return [3 /*break*/, 5];
                    db = firebase.firestore();
                    ref_1 = db.collection('games').doc(gameId).withConverter(gameConverter);
                    return [4 /*yield*/, db.runTransaction(function (transaction) { return __awaiter(_this, void 0, void 0, function () {
                            var doc, newPiles, newPilesObj;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        logReadWriteOperation('changePiles');
                                        return [4 /*yield*/, transaction.get(ref_1)];
                                    case 1:
                                        doc = _a.sent();
                                        if (!doc.exists) {
                                            throw 'Could not find game.';
                                        }
                                        return [4 /*yield*/, fn(doc.data().piles, doc.data())];
                                    case 2:
                                        newPiles = _a.sent();
                                        newPilesObj = keyValueArrayToObject(newPiles.map(function (pile) { return [pile.id, pileDataConverter.toFirestore(pile)]; }));
                                        /*
                                        // Any piles that are changed get a new timestamp, the rest keep their
                                        same timestamp. for (let [key, newPile] of Object.entries(newPilesObj)) {
                                          let updated = false;
                                          if (!oldPilesObj[key]) { // newly created pile
                                            updated = true;
                                          }
                                          else if (JSON.stringify(oldPilesObj[key]) != JSON.stringify(newPile)) {
                                            updated = true;
                                          }
                                          if (updated) (newPile as PileData).editedTime = 0;
                                        }*/
                                        transaction.update(ref_1, { piles: newPilesObj });
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 6];
                case 5:
                    pendingPushWhileDisallowed = true;
                    _b.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    });
}
export function shuffleArray(array) {
    var currentIndex = array.length;
    while (0 !== currentIndex) {
        var randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        var temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
}
