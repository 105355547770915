var specialHandlingAttrs = ['tag', 'text', 'classes', 'placeholder', 'disabled', 'href'];
export function createElement(params) {
    var tag = 'div';
    var classes = [];
    var text = '';
    var placeholder = '';
    var disabled = false;
    var href = null;
    if (params) {
        if (params.tag) {
            tag = params.tag;
        }
        var classParam = params.classes || params.class;
        if (classParam) {
            if (typeof classParam === 'string') {
                classes = classParam.split(' ');
            }
            else {
                classes = classParam;
            }
        }
        if (params.text) {
            text = params.text;
        }
        if (params.href) {
            href = params.href;
        }
        if (params.disabled) {
            disabled = params.disabled;
        }
        if (params.placeholder) {
            placeholder = params.placeholder;
        }
    }
    var element = document.createElement(tag);
    if (tag === 'a' && href) {
        element.href = href;
    }
    if (tag === 'input') {
        element.defaultValue = text;
    }
    else if (text.match(/&\w+;/)) {
        element.innerHTML = text;
    }
    else {
        element.textContent = text;
    }
    if (tag === 'input' && placeholder) {
        element.placeholder = placeholder;
    }
    if ((tag === 'input' || tag === 'button') && disabled) {
        element.disabled = disabled;
    }
    for (var _i = 0, classes_1 = classes; _i < classes_1.length; _i++) {
        var cl = classes_1[_i];
        if (!cl)
            continue;
        element.classList.add(cl);
    }
    for (var attrName in params) {
        if (specialHandlingAttrs.includes(attrName))
            continue;
        // @ts-ignore;
        element[attrName] = params[attrName];
    }
    return element;
}
