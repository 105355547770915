import firebase from 'firebase/app';
import { ConstantsManager } from './constants';
var config = {
    apiKey: 'AIzaSyB9TYdwIexSKcNYJNg3Pu8D9KAKbwz-264',
    authDomain: 'tabletop-friends.firebaseapp.com',
    databaseURL: 'https://tabletop-friends.firebaseio.com',
    projectId: 'tabletop-friends',
    storageBucket: 'tabletop-friends.appspot.com',
    messagingSenderId: '968897531208',
    appId: '1:968897531208:web:ccc47edab525f90c17b77d',
    measurementId: 'G-53Z3YVCP5X'
};
// Initializes Firebase SDK.
firebase.initializeApp(config);
firebase.analytics();
ConstantsManager.init();
export { firebase };
