import { __awaiter, __generator, __spreadArrays } from "tslib";
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import firebase from 'firebase/app';
import { logReadOperation, logWriteOperation } from './utils';
var Animation = /** @class */ (function () {
    function Animation(game) {
        var _this = this;
        this.allowUpload = true;
        this.game = game;
        var ref = firebase.database().ref("anim/" + this.game.gameId);
        ref.on('value', function (snap) { return _this.animationUpdate(snap); });
        var ref2 = firebase.database().ref("anim/" + this.game.gameId + "/" + this.game.user.uid);
        ref2.onDisconnect().remove();
    }
    Animation.prototype.uploadPileShuffling = function (uid, pileId, isShuffling, timeout) {
        if (timeout === void 0) { timeout = 0; }
        return __awaiter(this, void 0, void 0, function () {
            var pileId2, ref;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pileId2 = pileId.replace('.', '|');
                        ref = firebase.database().ref("anim/" + this.game.gameId + "/" + uid + "/shuffle/" + pileId2);
                        return [4 /*yield*/, ref.set(isShuffling ? timeout : null)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Animation.prototype.uploadHighlights = function (uid, cardIds) {
        return __awaiter(this, void 0, void 0, function () {
            var ref;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ref = firebase.database().ref("anim/" + this.game.gameId + "/" + uid + "/highlight");
                        logWriteOperation('uploadHighlights', 'rtdb');
                        return [4 /*yield*/, ref.set(cardIds ? cardIds : null)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Animation.prototype.uploadCardDrags = function (cardDrags) {
        var _this = this;
        if (this.allowUpload) {
            var same = !this.lastCardDrags && !cardDrags;
            if (this.lastCardDrags && cardDrags &&
                cardDrags.length == this.lastCardDrags.length) {
                same = true;
                // compare each element to see if they are really close.
                for (var i = 0; i < cardDrags.length; i++) {
                    var distance = Math.max(Math.abs(cardDrags[i].x - this.lastCardDrags[i].x), Math.abs(cardDrags[i].y - this.lastCardDrags[i].y));
                    if (distance > 5) {
                        // within a few pixels
                        same = false;
                    }
                }
            }
            if (same)
                return;
            this.allowUpload = false;
            var ref = firebase.database().ref("anim/" + this.game.gameId + "/" + this.game.user.uid + "/drag");
            this.lastCardDrags = cardDrags ? __spreadArrays(cardDrags) : undefined;
            logWriteOperation('uploadCardDrags', 'rtdb');
            ref.set(this.lastCardDrags ? this.lastCardDrags : []).then(function () {
                _this.allowUpload = true;
            });
        }
    };
    Animation.prototype.deleteGame = function () {
        var ref = firebase.database().ref("anim/" + this.game.gameId);
        ref.set(null);
    };
    Animation.prototype.animationUpdate = function (snap) {
        logReadOperation('animationUpdate', 'rtdb');
        this.game.updateShuffles(snap);
        this.game.updateHighlights(snap);
        this.game.updateDragAnimations(snap);
    };
    return Animation;
}());
export default Animation;
