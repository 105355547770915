import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import 'firebase/auth';
import 'firebase/remote-config';
import { addEmptyNewPile, addMissingCardsToNewPile, changePileDrawCardsOnReset, changePileHelpTexts, changePileLabels, changePileLabelSizes, changePileRotations, changePileScales, changePileStyleOverrides, changePileStyles, flipPile, moveCardsBetweenPiles, moveCardsToNewPile, movePile, rotatePile, setAllowPushToRemote, setPileVisibility, shufflePile, updatePileTimestamp } from './actions';
import Animation from './Animation';
import { drawArrow, loadImage } from './canvas-utils';
import CardCollision from './CardCollision';
import CardPile from './CardPile';
import CardSheet from './CardSheet';
import constants from './constants';
import { firebase } from './firebase';
import { GameButtons } from './game-buttons';
import { resubscribeToGame, unsubscribeFromGame } from './game-page';
import { Players } from './Players';
import { Spectators } from './Spectators';
import { EditClickState, PileFacing, PlaceholderStyle, UXMode } from './types';
import { rangeArray, roundedRect } from './utils';
var helpCardScreenRatio = 0.6;
var Game = /** @class */ (function () {
    function Game(gameId, sheetAsset, pileStyles, canvas, user, initialGameData) {
        var _this = this;
        this.pileStyles = pileStyles;
        this.user = user;
        this.isCanvasDirty = false;
        this.piles = new Map();
        this.internalPiles = {
            default: new CardPile('_default', 0, 0),
            dragging: new CardPile('_drag', 0, 0),
        };
        this.collisionManager = new CardCollision();
        this.gameScale = 1;
        this.lastGameScale = 1;
        this.clickedCardTime = 0; // timestamp from the event.
        this.clickedCardOffset = { x: 0, y: 0 };
        this.mouseDownPosition = { x: 0, y: 0 };
        this.doubleClickTime = 0;
        // Set with every click or move.
        this.currentMousePosition = { x: 0, y: 0 };
        this.currentMouseHoverPosition = { x: 0, y: 0 };
        this.highlightTimerId = null;
        this.waitingForShuffle = false;
        this.lastPileId = 0;
        this.editMode = false;
        // Card to show in the middle of the screen at full size when you hold Alt.
        this.helpCardId = '';
        this.tuckKeyHeld = false;
        this.zoomKeyHeld = false;
        /******** Edit mode methods ********/
        this.editClickState = EditClickState.Normal;
        this.editClickTime = 0;
        this.editChoosingPileToDrawFrom = false;
        // Last pile style and ID that we touched, so if we add a new pile
        // we can copy theirs.
        this.editLastPileStyle = '';
        this.editLastPileId = '';
        this.gameId = gameId;
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d');
        this.ctx.imageSmoothingEnabled = constants.IMAGE_SMOOTHING_ENABLED;
        this.sheetAsset = sheetAsset;
        if (!pileStyles.has('default')) {
            pileStyles.set('default', {
                id: 'default',
                name: 'Fallback Default',
                description: 'If this is visible, pile styles could not be loaded.',
                splayPctX: constants.FALLBACK_DEFAULT_SPLAY_PCT_X,
                splayPctY: constants.FALLBACK_DEFAULT_SPLAY_PCT_Y,
                splayMult: 1,
                splayMax: 0,
                facing: PileFacing.Any,
                placeholder: PlaceholderStyle.None,
                uxMode: UXMode.Default,
                layer: 0,
                hideFromList: true,
            });
        }
        if (!pileStyles.has('drag')) {
            pileStyles.set('drag', __assign({}, pileStyles.get('default')));
        }
        this.players = new Players(this, this.user);
        this.buttons = new GameButtons(this, this.canvas, []);
        this.spectators = new Spectators(this, this.user);
        this.cards = new Map();
        this.isInitialized = this.initializeGame();
        this.players.initialPlayerFetch.then(function () {
            if (_this.players.has(_this.user.uid)) {
                _this.players.addPlayer(_this.user.uid); // Ensure everything is up to date.
                _this.buttons.setUserIsPlayer(true);
            }
            else {
                _this.spectators.addSpectator(_this.user.uid);
                _this.buttons.setUserIsPlayer(false);
            }
        });
    }
    Game.prototype.createDeck = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, pos, backIdx, i, card;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.cards = new Map();
                        _a = this;
                        _b = CardSheet.bind;
                        return [4 /*yield*/, loadImage(this.sheetAsset.url)];
                    case 1:
                        _a.cardSheet = new (_b.apply(CardSheet, [void 0, _c.sent(), this.sheetAsset.cols,
                            this.sheetAsset.rows, this.sheetAsset.cols * this.sheetAsset.rows,
                            this.sheetAsset.scale]))();
                        this.lastGameScale = this.gameScale;
                        pos = 0;
                        backIdx = this.sheetAsset.backIndex;
                        for (i = 0; i < this.sheetAsset.count; i++) {
                            if (backIdx == pos)
                                pos++; // skip back index
                            card = this.cardSheet.createCard('card' + i, pos, backIdx >= 0 ? backIdx : pos + 1);
                            pos++;
                            if (backIdx == -1)
                                pos++; // if no back index set, go 2 at a time
                            card.collisionManager = this.collisionManager;
                            this.internalPiles.default.addCard(card);
                            this.cards.set(card.id, card);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.initializeGame = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createDeck()];
                    case 1:
                        _a.sent();
                        this.canvas.addEventListener('mousedown', function (e) { return _this.mousedown(e); });
                        this.canvas.addEventListener('mousemove', function (e) { return _this.mousemove(e); });
                        this.canvas.addEventListener('mouseout', function (e) { return _this.mouseout(e); });
                        this.canvas.addEventListener('mouseup', function (e) { return _this.mouseup(e); });
                        document.addEventListener('keydown', function (e) { return _this.keydown(e); });
                        document.addEventListener('keyup', function (e) { return _this.keyup(e); });
                        this.animation = new Animation(this);
                        setAllowPushToRemote(this.gameId, true);
                        this.markCanvasDirty();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.markCanvasDirty = function () {
        var _this = this;
        if (!this.isCanvasDirty) {
            this.isCanvasDirty = true;
            requestAnimationFrame(function () { return _this.renderCanvas(); });
        }
    };
    Game.prototype.processGameData = function (gameData, isLocal) {
        if (isLocal === void 0) { isLocal = false; }
        return __awaiter(this, void 0, void 0, function () {
            var defaultStyle, tmp, dragStyle, tmp, saveAnims;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isInitialized];
                    case 1:
                        _a.sent();
                        if (this.editMode && !isLocal) {
                            return [2 /*return*/]; // Ignore remote game data in edit mode.
                        }
                        this.lastGameData = undefined;
                        // Super basic placeholder version of import: delete all piles,
                        // moving all cards to _default. Then create all new piles. This
                        // is super hacky and should be fixed before this is used in any
                        // reasonable way.
                        this.gameScale = Math.min(Math.max(gameData.cardScale, constants.CARD_SCALE_MIN), constants.CARD_SCALE_MAX);
                        defaultStyle = this.pileStyles.get('default');
                        if ((defaultStyle.splayPctY > defaultStyle.splayPctX) !=
                            gameData.isDropVertical) {
                            tmp = defaultStyle.splayPctX;
                            defaultStyle.splayPctX = defaultStyle.splayPctY;
                            defaultStyle.splayPctY = tmp;
                            this.pileStyles.set('default', defaultStyle);
                        }
                        dragStyle = this.pileStyles.get('drag');
                        if ((dragStyle.splayPctY > dragStyle.splayPctX) !=
                            gameData.isDragVertical) {
                            tmp = dragStyle.splayPctX;
                            dragStyle.splayPctX = dragStyle.splayPctY;
                            dragStyle.splayPctY = tmp;
                            this.pileStyles.set('drag', dragStyle);
                        }
                        /*
                        if (this.gameScale != this.lastGameScale) {
                          this.internalPiles.default.hidden = true;
                          await this.createDeck();
                          this.internalPiles.default.hidden = false;
                        }*/
                        this.internalPiles.default.removeAllCards();
                        this.cards.forEach(function (card) {
                            if (card.cardPile != null)
                                card.cardPile.removeCard(card);
                            _this.internalPiles.default.addCard(card);
                        });
                        saveAnims = new Map();
                        Array.from(this.piles.keys()).forEach(function (key) {
                            saveAnims.set(key, _this.piles.get(key).saveAnimState());
                        });
                        this.piles.clear();
                        gameData.piles.forEach(function (pileData) {
                            var pileStyle = _this.pileStyles.get(pileData.style ? pileData.style : 'default');
                            if (!pileStyle) {
                                pileStyle = _this.pileStyles.get('default');
                            }
                            if (pileData.overrideStyle) {
                                pileStyle = __assign(__assign({}, pileStyle), pileData.overrideStyle);
                            }
                            var newPile = new CardPile(pileData.id, pileData.x, pileData.y, pileStyle, {
                                width: _this.cardSheet.cardWidth * _this.gameScale *
                                    (pileData.cardScaleX ? pileData.cardScaleX : 1),
                                height: _this.cardSheet.cardHeight * _this.gameScale *
                                    (pileData.cardScaleY ? pileData.cardScaleY : 1),
                            });
                            if (saveAnims.has(newPile.id)) {
                                newPile.restoreAnimState(saveAnims.get(newPile.id));
                            }
                            newPile.styleOverridden = !!pileData.overrideStyle;
                            newPile.label = pileData.label;
                            newPile.helpText = pileData.helpText;
                            if (pileData.labelSize)
                                newPile.labelSize = pileData.labelSize;
                            if (pileData.cardScaleX)
                                newPile.cardScaleX = pileData.cardScaleX;
                            if (pileData.cardScaleY)
                                newPile.cardScaleY = pileData.cardScaleY;
                            if (pileData.rotation != null)
                                newPile.setRotation(pileData.rotation);
                            if (pileData.dealSettings != null)
                                newPile.dealSettings = __spreadArrays(pileData.dealSettings);
                            if (pileData.editedTime) {
                                newPile.lastEditTimestamp = pileData.editedTime;
                            }
                            if (newPile.pileStyle.placeholder != PlaceholderStyle.None) {
                                newPile.collisionManager = _this.collisionManager;
                            }
                            newPile.visibilityList =
                                pileData.visibleToPlayers ? __spreadArrays(pileData.visibleToPlayers) : [];
                            if (pileData.visibleToPlayers && pileData.visibleToPlayers.length > 0 &&
                                newPile.pileStyle.facing == PileFacing.Any) {
                                if (pileData.visibleToPlayers.indexOf(_this.user.uid) != -1) {
                                    newPile.pileStyle.facing = PileFacing.ForceFaceUp;
                                }
                                else {
                                    newPile.pileStyle.facing = PileFacing.ForceFaceDown;
                                }
                            }
                            for (var i = 0; i < (pileData.cards ? pileData.cards.length : 0); i++) {
                                var card = _this.cards.get(pileData.cards[i].id);
                                if (card != null) {
                                    if (card.cardPile != null)
                                        card.cardPile.removeCard(card);
                                    if (newPile.pileStyle.facing == PileFacing.Any) {
                                        card.setFaceUp(pileData.cards[i].faceUp);
                                    }
                                    if (newPile.getRotation() == -1) {
                                        card.setRotation(pileData.cards[i].rotation);
                                    }
                                    newPile.addCard(card);
                                }
                                else {
                                    console.log('Card "' + pileData.cards[i].id + '" not found');
                                }
                            }
                            _this.piles.set(newPile.id, newPile);
                        });
                        if (this.internalPiles.default.numCards() > 0) {
                            addMissingCardsToNewPile(this.gameId, this.uniquePileId());
                        }
                        this.lastGameData = gameData;
                        this.markCanvasDirty();
                        this.buttons.renderAll();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.renderCanvas = function () {
        var _this = this;
        this.isCanvasDirty = false;
        if (!this.editMode) {
            if (this.animation && this.internalPiles.dragging.numCards() == 0) {
                this.animation.uploadCardDrags(undefined);
            }
            this.ctx.fillStyle = constants.TABLE_COLOR;
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            this.collisionManager.clear();
            // Render piles in this order:
            // Highest priority is piles with 0 cards.
            // Then, piles from lowest posY to highest posY.
            // (Ties in ascending order of posX).
            var pilesToRender = Array.from(this.piles.values())
                .sort(function (pile1, pile2) {
                var pile2OnTop = -1;
                var pile1OnTop = 1;
                if (pile1.pileStyle.layer > pile2.pileStyle.layer)
                    return pile1OnTop;
                if (pile2.pileStyle.layer > pile1.pileStyle.layer)
                    return pile2OnTop;
                // If one pile is shuffling, it goes on top.
                if (pile1.shufflingBy && !pile2.shufflingBy)
                    return pile1OnTop;
                if (pile2.shufflingBy && !pile1.shufflingBy)
                    return pile2OnTop;
                // if (pile1.isPlaceholderVisible() !=
                // pile2.isPlaceholderVisible())
                // return pile1.isPlaceholderVisible() ? -1 : 1;
                if (pile1.pileStyle.placeholder !=
                    pile2.pileStyle.placeholder) {
                    if (pile1.pileStyle.placeholder == PlaceholderStyle.None)
                        return pile1OnTop;
                    else if (pile2.pileStyle.placeholder == PlaceholderStyle.None)
                        return pile2OnTop;
                }
                // If only one of the piles is empty, render it second.
                if (pile2.numCards() == 0 && pile1.numCards() > 0)
                    return pile1OnTop;
                if (pile1.numCards() == 0 && pile2.numCards() > 0)
                    return pile2OnTop;
                // If a pile has any zoomed-up cards, render it first.
                if ((pile1.pileStyle.uxMode & UXMode.ZoomCard) &&
                    !(pile2.pileStyle.uxMode & UXMode.ZoomCard))
                    return pile1OnTop;
                if ((pile2.pileStyle.uxMode & UXMode.ZoomCard) &&
                    !(pile1.pileStyle.uxMode & UXMode.ZoomCard))
                    return pile2OnTop;
                // Pick the pile with the larger card scale.
                var p1 = 1, p2 = 1;
                _this.cards.forEach(function (card) {
                    if (card.cardPile == pile1)
                        p1 *= card.displayScale().x;
                    else if (card.cardPile == pile2)
                        p2 *= card.displayScale().x;
                });
                if (p1 < p2)
                    return pile2OnTop;
                if (p2 < p1)
                    return pile1OnTop;
                // If one of the piles is on our visibility list and the other
                // isn't, our pile goes on top.
                if ((pile1.visibilityList &&
                    pile1.visibilityList.indexOf(_this.user.uid) != -1) &&
                    (!pile2.visibilityList ||
                        pile2.visibilityList.indexOf(_this.user.uid) == -1))
                    return pile1OnTop;
                if ((pile2.visibilityList &&
                    pile2.visibilityList.indexOf(_this.user.uid) != -1) &&
                    (!pile1.visibilityList ||
                        pile1.visibilityList.indexOf(_this.user.uid) == -1))
                    return pile2OnTop;
                if (pile1.lastEditTimestamp > pile2.lastEditTimestamp)
                    return pile1OnTop;
                if (pile2.lastEditTimestamp > pile1.lastEditTimestamp)
                    return pile2OnTop;
                if (pile1.posY < pile2.posY)
                    return pile2OnTop;
                if (pile2.posY < pile1.posY)
                    return pile1OnTop;
                if (pile1.posX < pile2.posX)
                    return pile2OnTop;
                if (pile2.posX < pile1.posX)
                    return pile1OnTop;
                return 0;
            });
            var needRefresh_1 = false;
            pilesToRender.forEach(function (pile) {
                pile.drawLabel(_this.ctx);
            });
            pilesToRender.forEach(function (pile) {
                pile.drawPlaceholderIfPresent(_this.ctx);
            });
            if (this.clickedCard && this.internalPiles.dragging.numCards() > 0) {
                if (this.tuckKeyHeld) {
                    // If we are holding the "tuck" key then draw this underneath all the
                    // other piles.
                    this.drawDraggingPile();
                }
            }
            pilesToRender.forEach(function (pile) {
                needRefresh_1 = pile.draw(_this.ctx) || needRefresh_1;
            });
            // Internal piles are always drawn on top, due to dragging cards being in
            // the foreground.
            this.internalPiles.default.draw(this.ctx);
            pilesToRender.forEach(function (pile) {
                pile.drawHighlight(_this.ctx, true);
                needRefresh_1 = pile.draw(_this.ctx, true) || needRefresh_1;
            });
            if (this.clickedCard && this.internalPiles.dragging.numCards() > 0) {
                if (!this.tuckKeyHeld) {
                    this.drawDraggingPile();
                }
                if (this.animation) {
                    var drags = new Array();
                    for (var i = 0; i < this.internalPiles.dragging.numCards(); i++) {
                        var card = this.internalPiles.dragging.getCard(i);
                        drags.push({
                            x: Math.round(card.pos().x),
                            y: Math.round(card.pos().y),
                            cardId: card.id,
                            timestamp: firebase.database.ServerValue.TIMESTAMP,
                        });
                    }
                    this.animation.uploadCardDrags(drags);
                }
            }
            if (this.helpCardId != '' && this.cards.has(this.helpCardId)) {
                var card = this.cards.get(this.helpCardId);
                // If it's a card in the middle of a draw pile, show the top card
                // instead.
                if (card.cardPile.pileStyle.uxMode & UXMode.Draw) {
                    var pile = card.cardPile;
                    card = pile.getCard(pile.numCards() - 1);
                }
                var ctx = this.ctx;
                ctx.save();
                ctx.translate(this.canvas.width / 2, this.canvas.height / 2);
                ctx.rotate(card.getRotation() * Math.PI / 180);
                ctx.imageSmoothingEnabled = true;
                var size = {
                    width: card.imageSize().width,
                    height: card.imageSize().height
                };
                if ((size.width > size.height &&
                    size.width < this.canvas.width * helpCardScreenRatio) ||
                    size.width > this.canvas.width) {
                    var mult = (this.canvas.width * helpCardScreenRatio) / size.width;
                    size.width *= mult;
                    size.height *= mult;
                }
                if ((size.height > size.width &&
                    size.height < this.canvas.height * helpCardScreenRatio) ||
                    size.height > this.canvas.height) {
                    var mult = (this.canvas.height * helpCardScreenRatio) / size.height;
                    size.width *= mult;
                    size.height *= mult;
                }
                ctx.fillStyle = '#00000080';
                var border = Math.max(Math.ceil(Math.max(size.width, size.height) / 20), 10);
                roundedRect(ctx, -size.width / 2 - border, -size.height / 2 - border, size.width + 2 * border, size.height + 2 * border, 2 * border);
                ctx.drawImage(card.getCurrentImage(), -size.width / 2, -size.height / 2, size.width, size.height);
                ctx.restore();
            }
            if (needRefresh_1) {
                this.markCanvasDirty();
            }
        }
        else {
            // Render edit mode.
            this.ctx.fillStyle = 'darkgreen';
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            this.collisionManager.clear();
            // Render piles in this order:
            // Piles from lowest posY to highest posY.
            // (Ties in ascending order of posX).
            var pilesToRender = Array.from(this.piles.values())
                .sort(function (pile1, pile2) {
                if (pile1.posY < pile2.posY)
                    return -1;
                if (pile2.posY < pile1.posY)
                    return 1;
                if (pile1.posX < pile2.posX)
                    return -1;
                if (pile2.posX < pile1.posX)
                    return 1;
                return 0;
            });
            pilesToRender.forEach(function (pile) { pile.drawLabel(_this.ctx); });
            pilesToRender.forEach(function (pile) { pile.drawEditMode(_this.ctx); });
            // If there is a pile being edited that draws cards from another pile,
            // draw an arrow now.
            if (this.editClickState == EditClickState.EditingPile &&
                this.editClickStatePileId &&
                this.piles.has(this.editClickStatePileId) &&
                this.piles.get(this.editClickStatePileId).dealSettings) {
                this.piles.get(this.editClickStatePileId).dealSettings.forEach(function (dealSetting) {
                    var srcPileId = dealSetting.fromPile;
                    if (_this.piles.has(srcPileId)) {
                        var srcPile = _this.piles.get(srcPileId);
                        var destPile = _this.piles.get(_this.editClickStatePileId);
                        drawArrow(_this.ctx, srcPile.centerPos().x, srcPile.centerPos().y, destPile.centerPos().x, destPile.centerPos().y, '#00ffff60');
                    }
                });
            }
        }
    };
    Game.prototype.drawDraggingPile = function () {
        this.internalPiles.dragging.posX =
            this.currentMousePosition.x + this.clickedCardOffset.x;
        this.internalPiles.dragging.posY =
            this.currentMousePosition.y + this.clickedCardOffset.y;
        this.internalPiles.dragging.highlightColor =
            this.players.getPlayerColor(this.user.uid);
        this.internalPiles.dragging.drawHighlight(this.ctx);
        this.internalPiles.dragging.draw(this.ctx);
    };
    Game.prototype.isSpectator = function () {
        return (!this.user || !this.user.uid || this.spectators.has(this.user.uid));
    };
    Game.prototype.mousedown = function (e) {
        var _this = this;
        e.preventDefault();
        this.currentMouseHoverPosition = { x: e.offsetX, y: e.offsetY };
        if (this.isSpectator()) {
            // no interaction for spectators is allowed
            return;
        }
        if (this.editMode) {
            this.editModeMouseDown(e);
            return;
        }
        this.players.cancelClickUser();
        var collision = this.collisionManager.getCollisionAABB(e.offsetX, e.offsetY, e.offsetX, e.offsetY);
        if (collision != null && collision.card != null) {
            if (this.waitingForShuffle && collision.card.cardPile) {
                this.clearMyHighlightedCards();
                this.waitingForShuffle = false;
                this.buttons.setMessage(null);
                shufflePile(this.gameId, collision.card.cardPile.id);
                collision.card.cardPile.animateShuffle(this.user.uid);
                if (this.animation) {
                    var timeoutFrames = collision.card.cardPile.shuffleAnimState;
                    var pileId_1 = collision.card.cardPile.id;
                    var myUid_1 = this.user.uid;
                    this.animation.uploadPileShuffling(myUid_1, pileId_1, true).then(function () {
                        // As a backup, turn off the animation after a few seconds.
                        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.animation.uploadPileShuffling(myUid_1, pileId_1, false)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }, 2000);
                    });
                    ;
                }
            }
            else if (e.button != 2) {
                this.clickedCard = collision.card;
                this.clickedCardTime = e.timeStamp;
                this.clickedCardOffset = {
                    x: this.clickedCard.pos().x - e.offsetX,
                    y: this.clickedCard.pos().y - e.offsetY
                };
                this.currentMousePosition = { x: e.offsetX, y: e.offsetY };
                this.mouseDownPosition = { x: e.offsetX, y: e.offsetY };
                if (this.doubleClickCard != this.clickedCard ||
                    this.clickedCardTime >
                        this.doubleClickTime + constants.DOUBLE_CLICK_DELAY_MAX_MS) {
                    this.doubleClickCard = undefined;
                }
            }
            this.markCanvasDirty();
        }
        else {
            this.clearMyHighlightedCards();
            if (this.waitingForShuffle) {
                this.waitingForShuffle = false;
                this.buttons.setMessage(null);
            }
        }
    };
    Game.prototype.mouseout = function (e) {
        if (this.helpLabel) {
            this.helpLabel = undefined;
            this.buttons.renderAll();
        }
    };
    Game.prototype.getAllDraggingCardIds = function () {
        // Build a list of all cards being dragged by anyone.
        var draggingCardIds = [];
        for (var i = 0; i < this.internalPiles.dragging.numCards(); i++) {
            draggingCardIds.push(this.internalPiles.dragging.getCard(i).id);
        }
        this.cards.forEach(function (card) {
            if (card.isDragBeingAnimated()) {
                draggingCardIds.push(card.id);
            }
        });
        return draggingCardIds;
    };
    Game.prototype.keydown = function (e) {
        // alt key
        if (e.keyCode == 18) {
            this.zoomKeyHeld = true;
            this.handleZoomKey(this.currentMouseHoverPosition.x, this.currentMouseHoverPosition.y);
        }
        if (e.keyCode == 17) {
            this.tuckKeyHeld = true;
            this.markCanvasDirty();
        }
    };
    Game.prototype.keyup = function (e) {
        if (e.keyCode == 18) {
            this.zoomKeyHeld = false;
            this.handleZoomKey(this.currentMouseHoverPosition.x, this.currentMouseHoverPosition.y);
        }
        if (e.keyCode == 17) {
            this.tuckKeyHeld = false;
            this.markCanvasDirty();
        }
    };
    Game.prototype.handleZoomKey = function (x, y) {
        var prevHelpCard = this.helpCardId;
        this.helpCardId = '';
        if (this.zoomKeyHeld) {
            var ignoreList = new Array();
            var collision = this.collisionManager.getCollisionAABB(x, y, x, y, ignoreList, []);
            if (collision && collision.card) {
                this.helpCardId = collision.card.id;
            }
        }
        if (prevHelpCard != this.helpCardId) {
            this.markCanvasDirty();
        }
    };
    Game.prototype.handleHoverPilePeek = function (hoverPile, x, y, allowPeek) {
        var _this = this;
        if (allowPeek === void 0) { allowPeek = true; }
        this.cards.forEach(function (card) {
            card.setViewFlipped(false);
            if (card.cardPile) {
                if (card.setCardScale(1, 1)) {
                    _this.markCanvasDirty();
                }
            }
        });
        if (hoverPile && (hoverPile.pileStyle.uxMode & UXMode.ZoomCard)) {
            var ignoreList = new Array();
            /*
            this.cards.forEach(card => {
              if (card.cardPile != hoverPile) ignoreList.push(card.id);
            });
            */
            var collision = this.collisionManager.getCollisionAABB(x, y, x, y, ignoreList, []);
            if (collision && collision.card && allowPeek) {
                collision.card.setCardScale(Math.max(Math.min(1 / this.gameScale, 2), 1.25)); // Max twice size, min 1.25 size.
                this.markCanvasDirty();
            }
        }
        if (hoverPile && (hoverPile.pileStyle.uxMode & UXMode.SecretlyViewCard)) {
            var ignoreList = new Array();
            var collision = this.collisionManager.getCollisionAABB(x, y, x, y, ignoreList, []);
            if (collision && collision.card && collision.card.cardPile && allowPeek) {
                // If we are allowed to access the pile, or if anyone is allowed to
                // access the pile.
                if (!collision.card.cardPile.visibilityList ||
                    (collision.card.cardPile.visibilityList.length == 0 ||
                        collision.card.cardPile.visibilityList.indexOf(this.user.uid) !=
                            -1)) {
                    collision.card.setViewFlipped(true);
                    this.markCanvasDirty();
                }
            }
        }
        if (hoverPile && (hoverPile.pileStyle.uxMode & UXMode.PeekCard)) {
            // Peek on the card they are looking at.
            // Ignore all cards not in this pile.
            var ignoreList_1 = new Array();
            this.cards.forEach(function (card) {
                if (card.cardPile != hoverPile)
                    ignoreList_1.push(card.id);
            });
            var collision_1 = this.collisionManager.getCollisionAABB(x, y, x, y, ignoreList_1, []);
            if (collision_1 && collision_1.card && allowPeek) {
                if (!collision_1.card.peek) {
                    collision_1.card.peek = true;
                    this.markCanvasDirty();
                }
                this.cards.forEach(function (card) {
                    if (card.peek && card != collision_1.card) {
                        card.peek = false;
                        _this.markCanvasDirty();
                    }
                });
            }
        }
        else {
            this.cards.forEach(function (card) {
                if (card.peek) {
                    card.peek = false;
                    _this.markCanvasDirty();
                }
            });
        }
    };
    Game.prototype.mousemove = function (e) {
        var _this = this;
        e.preventDefault();
        this.currentMouseHoverPosition = { x: e.offsetX, y: e.offsetY };
        var draggingCardIds = this.getAllDraggingCardIds();
        var hoverPile = this.collisionManager.getPileAtPosition(e.offsetX, e.offsetY, draggingCardIds);
        var helpText = hoverPile ? hoverPile.helpText : undefined;
        if (helpText && helpText.indexOf('$') != -1) {
            // Text substitutions:
            //   $numCards: replaced with '_ card or _ cards'
            //   $num: replaced with just the number of cards.
            var sub = hoverPile.numCards() + " card";
            if (hoverPile.numCards() != 1)
                sub += 's';
            helpText = helpText.replace(/\$numCards/, sub);
            helpText = helpText.replace(/\$num/, "" + hoverPile.numCards());
        }
        if (this.helpLabel != helpText) {
            this.helpLabel = helpText;
            this.buttons.renderAll();
        }
        this.handleZoomKey(e.offsetX, e.offsetY);
        this.handleHoverPilePeek(hoverPile, e.offsetX, e.offsetY, !this.clickedCard);
        if (this.isSpectator()) {
            // no interaction for spectators is allowed
            return;
        }
        if (this.editMode) {
            this.editModeMouseMove(e);
            return;
        }
        if (this.clickedCard != undefined) {
            this.currentMousePosition = { x: e.offsetX, y: e.offsetY };
            if (this.doubleClickCard == this.clickedCard &&
                this.clickedCard.cardPile &&
                (this.clickedCard.cardPile.pileStyle.uxMode & UXMode.Draw)) {
                this.internalPiles.dragging.removeAllCards();
            }
            // If there is no pile being dragged, let's see how far the mouse has
            // moved.
            if (this.internalPiles.dragging.numCards() == 0) {
                // If we've moved more than like 10 pixels...
                var moveThreshold = constants.DRAG_MOVE_THRESHOLD;
                var sourcePile_1 = this.clickedCard.cardPile;
                var indices_1 = new Array();
                var distance = Math.abs(this.mouseDownPosition.x - this.currentMousePosition.x) +
                    Math.abs(this.mouseDownPosition.y - this.currentMousePosition.y);
                if (this.doubleClickCard == this.clickedCard &&
                    this.clickedCard.cardPile &&
                    (this.clickedCard.cardPile.pileStyle.uxMode & UXMode.Draw)) {
                    var distanceMult = Math.min(Math.ceil((distance - moveThreshold) /
                        (Math.min(this.clickedCard.renderSize().width, this.clickedCard.renderSize().height) /
                            2)), sourcePile_1.numCards());
                    if (distanceMult > 0) {
                        var start = sourcePile_1.numCards() - distanceMult;
                        var count = distanceMult;
                        indices_1 = rangeArray(start, count);
                        this.helpLabel = count > 1 ? "Drawing " + count + " cards" : undefined;
                        this.buttons.renderAll();
                    }
                    this.clearMyHighlightedCards();
                }
                else {
                    if (distance > moveThreshold) {
                        // Moved far enough to pick up the card.
                        this.doubleClickCard = undefined;
                        var pickupStackTime = constants.PICKUP_STACK_HOLD_TIME_MIN_MS;
                        // If it's a face-down draw pile, make it harder to pick up the
                        // whole stack.
                        if ((sourcePile_1.pileStyle.uxMode & UXMode.Draw) &&
                            sourcePile_1.pileStyle.facing == PileFacing.ForceFaceDown) {
                            pickupStackTime = constants.PICKUP_DRAW_STACK_HOLD_TIME_MIN_MS;
                        }
                        if (this.clickedCard.getHighlightedBy() != this.user.uid &&
                            e.timeStamp > this.clickedCardTime + pickupStackTime) {
                            // If /* the entire pile is face down, */
                            // it's a non-expanded Expand pile,
                            // or it's a Draw pile, pick up the whole pile.
                            if ( // sourcePile.hasEntirelyFaceUp(false) ||
                            (sourcePile_1.pileStyle.uxMode & UXMode.Expand &&
                                !sourcePile_1.isExpandingPile()) ||
                                (sourcePile_1.pileStyle.uxMode & UXMode.Draw)) {
                                indices_1 = rangeArray(0, sourcePile_1.numCards());
                                this.clickedCardOffset = {
                                    x: sourcePile_1.getCard(0).goal().x - e.offsetX,
                                    y: sourcePile_1.getCard(0).goal().y - e.offsetY
                                };
                            }
                            else {
                                var start = sourcePile_1.findCard(this.clickedCard.id);
                                var count = sourcePile_1.numCards() - start;
                                indices_1 = rangeArray(start, count);
                            }
                        }
                        else {
                            // Pick up just one card.
                            if (sourcePile_1.numCards() > 0 &&
                                (sourcePile_1.pileStyle.uxMode & UXMode.Draw)) {
                                // In a draw pile, pick up the top card only.
                                indices_1 = [sourcePile_1.numCards() - 1];
                            }
                            else {
                                // Other piles allow you to grab any card.
                                if (this.clickedCard.getHighlightedBy() == this.user.uid) {
                                    // If you're clicking on a card you highlighted, also pick up
                                    // any other cards in the same pile that are highlighted by
                                    // you.
                                    var cardIds = this.getMyHighlightedCards();
                                    console.log(cardIds);
                                    cardIds.forEach(function (cardId) {
                                        var idx = sourcePile_1.findCard(cardId);
                                        if (idx >= 0)
                                            indices_1.push(idx);
                                    });
                                    console.log(indices_1);
                                }
                                else {
                                    // Just pick up the card under the mouse.
                                    indices_1 = [sourcePile_1.findCard(this.clickedCard.id)];
                                }
                            }
                        }
                        this.clearMyHighlightedCards();
                    }
                }
                if (indices_1.length > 0) {
                    this.internalPiles.dragging.pileStyle = this.pileStyles.get('drag');
                    var cardIds_1 = Array();
                    indices_1.sort(function (a, b) { return a - b; }).forEach(function (idx) {
                        cardIds_1.push(sourcePile_1.getCard(idx).id);
                    });
                    cardIds_1.forEach(function (cardId) {
                        return _this.internalPiles.dragging.addCard(_this.cards.get(cardId));
                    });
                }
            }
            // If the user released the mouse while off of the canvas handle that when
            // the mouse returns to the canvas.
            if (e.buttons == 0) {
                this.mouseup(e);
            }
            this.markCanvasDirty();
        }
    };
    Game.prototype.clearMyHighlightedCards = function () {
        if (this.animation) {
            this.animation.uploadHighlights(this.user.uid);
        }
    };
    Game.prototype.doDoubleClick = function (card) {
        if (!card.canFlip())
            return;
        if (card.cardPile) {
            if (card.cardPile.pileStyle.uxMode & UXMode.Expand) {
                if (card.cardPile.isExpandingPile() &&
                    card.cardPile.pileStyle.facing == PileFacing.Any) {
                    var indices = [card.cardPile.findCard(card.id)];
                    if (card.getHighlightedBy() == this.user.uid) {
                        var cardIds = Array.from(this.getMyHighlightedCards());
                        indices = cardIds.map(function (cardId) { return card.cardPile.findCard(cardId); });
                    }
                    if (indices.length > 0) {
                        flipPile(this.gameId, card.cardPile.id, indices, false);
                        card.cardPile.expandPile();
                    }
                }
                else {
                    card.cardPile.expandPile();
                }
            }
            else {
                // Not Expand, double click to flip.
                if (card.cardPile.pileStyle.facing == PileFacing.Any) {
                    var cardIndex = card.cardPile.findCard(card.id);
                    console.log('cardIndex', cardIndex);
                    var indices = (cardIndex == card.cardPile.numCards() - 1 ? [cardIndex] : []);
                    console.log('indices', indices);
                    if (card.getHighlightedBy() == this.user.uid) {
                        var cardIds = Array.from(this.getMyHighlightedCards());
                        indices = cardIds.map(function (cardId) { return card.cardPile.findCard(cardId); });
                    }
                    if (indices.length > 0) {
                        flipPile(this.gameId, card.cardPile.id, indices, false);
                    }
                    else {
                        flipPile(this.gameId, card.cardPile.id);
                    }
                }
            }
        }
    };
    Game.prototype.getAllPilesThatCantReleaseCards = function () {
        var piles = new Array();
        this.piles.forEach(function (pile) {
            if (!pile.canReleaseCards())
                piles.push(pile.id);
        });
        return piles;
    };
    Game.prototype.getAllPilesThatCantAcceptCards = function () {
        var piles = new Array();
        this.piles.forEach(function (pile) {
            if (!pile.canAcceptCards())
                piles.push(pile.id);
        });
        return piles;
    };
    Game.prototype.mouseup = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var draggingCardIds, destinationPile, dropIndex, firstDraggedCard, sourcePile_2, indices, reverseOrder, newPileStyle, allHighlightedCards_1, myCards_1, collision, card_1, indices, cardIds, angle;
            var _this = this;
            return __generator(this, function (_a) {
                e.preventDefault();
                this.currentMouseHoverPosition = { x: e.offsetX, y: e.offsetY };
                if (this.isSpectator()) {
                    // no interaction for spectators is allowed
                    return [2 /*return*/];
                }
                if (this.editMode) {
                    this.editModeMouseUp(e);
                    return [2 /*return*/];
                }
                if (this.clickedCard) {
                    if (this.internalPiles.dragging.numCards() > 0) {
                        draggingCardIds = this.getAllDraggingCardIds();
                        destinationPile = this.collisionManager.getOverlappingPile(this.internalPiles.dragging.getCard(0), draggingCardIds, this.getAllPilesThatCantAcceptCards());
                        if (destinationPile &&
                            (destinationPile.pileStyle.uxMode & UXMode.Draw) &&
                            destinationPile ==
                                this.internalPiles.dragging.getCard(0).cardPile &&
                            !this.tuckKeyHeld) {
                            // Dropping a card from a draw pile onto itself aborts the draw. But
                            // let's update the pile's timestamp anyway.
                            updatePileTimestamp(this.gameId, destinationPile.id);
                        }
                        else {
                            dropIndex = (destinationPile && !this.tuckKeyHeld) ?
                                this.collisionManager.calculateDropIndex(this.internalPiles.dragging.getCard(0), destinationPile, draggingCardIds) :
                                0;
                            firstDraggedCard = this.internalPiles.dragging.getCard(0);
                            sourcePile_2 = firstDraggedCard.cardPile;
                            indices = draggingCardIds.map(function (cardId) { return sourcePile_2.findCard(cardId); });
                            if (destinationPile && !this.doubleClickCard) {
                                reverseOrder = false;
                                if ((sourcePile_2.hasEntirelyFaceUp(true) &&
                                    destinationPile.pileStyle.facing ==
                                        PileFacing.ForceFaceDown) ||
                                    (sourcePile_2.hasEntirelyFaceUp(false) &&
                                        destinationPile.pileStyle.facing == PileFacing.ForceFaceUp))
                                    reverseOrder = true;
                                moveCardsBetweenPiles(this.gameId, sourcePile_2.id, destinationPile.id, indices, dropIndex, reverseOrder)
                                    .then(function () {
                                    if (_this.animation)
                                        _this.animation.uploadCardDrags(undefined);
                                });
                                this.handleHoverPilePeek(destinationPile, e.offsetX, e.offsetY);
                            }
                            else {
                                newPileStyle = void 0;
                                if (this.tuckKeyHeld) {
                                    newPileStyle = this.pileStyles.get('default');
                                    newPileStyle.layer = -100;
                                }
                                moveCardsToNewPile(this.gameId, sourcePile_2.id, this.uniquePileId(), Math.round(firstDraggedCard.pos().x), Math.round(firstDraggedCard.pos().y), indices, newPileStyle)
                                    .then(function () {
                                    if (_this.animation)
                                        _this.animation.uploadCardDrags(undefined);
                                });
                            }
                        }
                        this.helpLabel = undefined;
                        this.buttons.renderAll();
                        this.internalPiles.dragging.removeAllCards();
                    }
                    else {
                        if (this.doubleClickCard == this.clickedCard) {
                            if (e.timeStamp < this.clickedCardTime +
                                constants.DOUBLE_CLICK_SECOND_CLICK_MAX_MS) {
                                if (this.highlightTimerId) {
                                    clearTimeout(this.highlightTimerId);
                                    this.highlightTimerId = null;
                                }
                                this.doubleClickCard = undefined;
                                this.doDoubleClick(this.clickedCard);
                            }
                        }
                        else {
                            this.doubleClickCard = undefined;
                            if (this.highlightTimerId) {
                                clearTimeout(this.highlightTimerId);
                                this.highlightTimerId = null;
                            }
                            if (e.timeStamp < this.clickedCardTime +
                                constants.DOUBLE_CLICK_FIRST_CLICK_MAX_MS) {
                                this.doubleClickCard = this.clickedCard;
                                this.doubleClickTime = e.timeStamp;
                            }
                            if (e.timeStamp >
                                this.clickedCardTime + constants.HIGHLIGHT_CLICK_MIN_MS &&
                                e.timeStamp <
                                    this.clickedCardTime + constants.HIGHLIGHT_CLICK_MAX_MS) {
                                if (this.animation) {
                                    allHighlightedCards_1 = this.getAllHighlightedCards();
                                    if (this.clickedCard.getHighlightedBy() == this.user.uid) {
                                        // highlighted by us, unhighlight unless we are possibly
                                        // doubleclicking. To check that, don't do the unhighlight until
                                        // we'd have done the double click.
                                        this.clickedCard.overrideHighlight('', constants.DOUBLE_CLICK_HIGHLIGHT_DELAY_MAX_MS);
                                        this.highlightTimerId = setTimeout(function (card, doubleClick) {
                                            if (doubleClick == undefined ||
                                                _this.doubleClickCard != undefined) {
                                                var myCards = allHighlightedCards_1.get(_this.user.uid);
                                                if (myCards) {
                                                    myCards.delete(card.id);
                                                    _this.animation.uploadHighlights(_this.user.uid, Array.from(myCards));
                                                }
                                            }
                                        }, constants.DOUBLE_CLICK_HIGHLIGHT_DELAY_MAX_MS, this.clickedCard, this.doubleClickCard);
                                    }
                                    else if (this.clickedCard.getHighlightedBy()) {
                                        // highlighted by someone else, take over
                                        this.clickedCard.overrideHighlight(this.user.uid, constants.DOUBLE_CLICK_HIGHLIGHT_DELAY_MAX_MS);
                                        this.highlightTimerId = setTimeout(function (card, doubleClick) {
                                            if (doubleClick == undefined ||
                                                _this.doubleClickCard != undefined) {
                                                var id_1 = card.id;
                                                _this.animation
                                                    .uploadHighlights(card.getHighlightedBy())
                                                    .then(function () {
                                                    if (_this.animation)
                                                        _this.animation.uploadHighlights(_this.user.uid, [id_1]);
                                                });
                                            }
                                        }, constants.DOUBLE_CLICK_HIGHLIGHT_DELAY_MAX_MS, this.clickedCard, this.doubleClickCard);
                                    }
                                    else {
                                        myCards_1 = allHighlightedCards_1.get(this.user.uid);
                                        if (!myCards_1) {
                                            myCards_1 = new Set();
                                        }
                                        myCards_1.forEach(function (cardId) {
                                            // Delete any cards we are highlighting from a
                                            // different pile.
                                            if (_this.cards.get(cardId).cardPile !=
                                                _this.clickedCard.cardPile) {
                                                myCards_1.delete(cardId);
                                            }
                                        });
                                        this.animation.uploadHighlights(this.user.uid, Array.from(myCards_1));
                                        this.clickedCard.overrideHighlight(this.user.uid, constants.DOUBLE_CLICK_HIGHLIGHT_DELAY_MAX_MS);
                                        // But wait to highlight the new card until we're sure it's not
                                        // a double-click.
                                        this.highlightTimerId = setTimeout(function (card, doubleClick) {
                                            if (doubleClick == undefined ||
                                                _this.doubleClickCard != undefined) {
                                                var myCards_2 = allHighlightedCards_1.get(_this.user.uid);
                                                if (!myCards_2) {
                                                    myCards_2 = new Set();
                                                }
                                                myCards_2.forEach(function (cardId) {
                                                    // Delete any cards we are highlighting from a
                                                    // different pile.
                                                    if (_this.cards.get(cardId).cardPile !=
                                                        card.cardPile) {
                                                        myCards_2.delete(cardId);
                                                    }
                                                });
                                                myCards_2.add(card.id);
                                                _this.animation.uploadHighlights(_this.user.uid, Array.from(myCards_2));
                                            }
                                        }, constants.DOUBLE_CLICK_HIGHLIGHT_DELAY_MAX_MS, this.clickedCard, this.doubleClickCard);
                                    }
                                }
                            }
                        }
                    }
                    this.clickedCard = undefined;
                }
                else {
                    // we were not clicking on a card
                    if (e.button == 2) {
                        collision = this.collisionManager.getCollisionAABB(e.offsetX, e.offsetY, e.offsetX, e.offsetY);
                        if (collision != null && collision.card != null) {
                            card_1 = collision.card;
                            if (card_1 && card_1.cardPile && card_1.canRotate()) {
                                indices = [card_1.cardPile.findCard(card_1.id)];
                                if (card_1.getHighlightedBy() == this.user.uid) {
                                    cardIds = Array.from(this.getMyHighlightedCards());
                                    indices =
                                        cardIds.map(function (cardId) { return card_1.cardPile.findCard(cardId); });
                                }
                                if (indices.length > 0) {
                                    angle = e.shiftKey ? -90 : 90;
                                    rotatePile(this.gameId, card_1.cardPile.id, angle, indices);
                                }
                            }
                        }
                    }
                }
                this.markCanvasDirty();
                return [2 /*return*/];
            });
        });
    };
    Game.prototype.uniquePileId = function () {
        var prefix = '.pile';
        var newPileId;
        do {
            newPileId = this.lastPileId + 1;
            this.lastPileId = newPileId;
            if (this.lastPileId > 1000) {
                // I dunno, a thousand card piles seems like plenty.
                this.lastPileId = 0;
            }
        } while (this.piles.has(this.user.uid.substring(0, 7) + prefix + newPileId));
        return this.user.uid.substring(0, 7) + prefix + newPileId;
    };
    Game.prototype.triggerShuffle = function (b) {
        this.waitingForShuffle = b;
        this.buttons.setMessage(b ? 'Click a pile to shuffle.' : null);
    };
    Game.prototype.toggleEditMode = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isInitialized];
                    case 1:
                        _a.sent();
                        if (!this.editMode) {
                            if (this.isSpectator()) {
                                // no interaction for spectators is allowed
                                return [2 /*return*/, false];
                            }
                        }
                        this.editMode = !this.editMode;
                        if (!this.editMode) return [3 /*break*/, 3];
                        unsubscribeFromGame();
                        return [4 /*yield*/, setAllowPushToRemote(this.gameId, !this.editMode)];
                    case 2:
                        _a.sent();
                        this.setEditClickState(EditClickState.Normal);
                        this.editDraggingPile = undefined;
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, setAllowPushToRemote(this.gameId, !this.editMode)];
                    case 4:
                        _a.sent();
                        resubscribeToGame(this.gameId, this.user);
                        _a.label = 5;
                    case 5:
                        this.markCanvasDirty();
                        this.players.renderAll();
                        return [2 /*return*/, this.editMode];
                }
            });
        });
    };
    // Set the edit click state, when you click a button that puts you into a
    // specific UI state in the editor.
    Game.prototype.setEditClickState = function (state, id) {
        var oldState = this.editClickState;
        this.editClickState = state;
        this.editClickStatePileId = undefined;
        this.editClickStateUserId = undefined;
        this.editChoosingPileToDrawFrom = false;
        switch (state) {
            case EditClickState.Normal: {
                this.buttons.setMessage(null);
                break;
            }
            case EditClickState.DeletingPile: {
                this.buttons.setMessage('Click a pile to remove.');
                break;
            }
            case EditClickState.AddingPile: {
                this.buttons.setMessage('Click location to add a pile.');
                break;
            }
            case EditClickState.TogglingUser: {
                if (id == undefined) {
                    break;
                }
                var uid = id;
                if (uid == '') {
                    this.buttons.setMessage('Click a pile to remove all custom visibility selections.');
                }
                else {
                    this.buttons.setMessage('Click a pile to toggle its visibility for ' +
                        this.players.getDisplayName(uid) + '.');
                }
                this.editClickStateUserId = uid;
                break;
            }
            case EditClickState.ChoosingPileToEdit: {
                this.buttons.setMessage('Click a pile to edit.');
                break;
            }
            case EditClickState.EditingPile: {
                this.editClickStatePileId = id;
                this.editLastPileId = id;
                this.editChoosingPileToDrawFrom = false;
                this.buttons.showOverrideStyleOptions =
                    (this.piles.get(id).styleOverridden);
                this.buttons.setMessage('Editing pile settings.');
                break;
            }
        }
        this.buttons.renderAll();
        this.markCanvasDirty();
    };
    Game.prototype.setPileStyleId = function (pileId, styleId) {
        return __awaiter(this, void 0, void 0, function () {
            var newStyles;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!pileId)
                            return [2 /*return*/];
                        if (!this.pileStyles.has(styleId)) {
                            return [2 /*return*/];
                        }
                        newStyles = new Map();
                        newStyles.set(pileId, styleId);
                        return [4 /*yield*/, changePileStyles(this.gameId, newStyles, true, true)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.setPileStyleOverride = function (pileId, style) {
        return __awaiter(this, void 0, void 0, function () {
            var newStyles;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!pileId)
                            return [2 /*return*/];
                        newStyles = new Map();
                        newStyles.set(pileId, style);
                        return [4 /*yield*/, changePileStyleOverrides(this.gameId, newStyles)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.setPileDrawCardsOnReset = function (pileId, dealOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var newDealOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!pileId)
                            return [2 /*return*/];
                        newDealOptions = new Map();
                        newDealOptions.set(pileId, dealOptions);
                        return [4 /*yield*/, changePileDrawCardsOnReset(this.gameId, newDealOptions)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.setPileLabel = function (pileId, label) {
        return __awaiter(this, void 0, void 0, function () {
            var newLabels;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!pileId)
                            return [2 /*return*/];
                        if (this.piles.has(pileId))
                            this.piles.get(pileId).label = label;
                        newLabels = new Map();
                        newLabels.set(pileId, label);
                        return [4 /*yield*/, changePileLabels(this.gameId, newLabels)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.getPileLabel = function (pileId) {
        if (!pileId || !this.piles.has(pileId))
            return '';
        var label = this.piles.get(pileId).label;
        return label ? label : '';
    };
    Game.prototype.setPileHelpText = function (pileId, helpText) {
        if (!pileId)
            return;
        if (this.piles.has(pileId))
            this.piles.get(pileId).helpText = helpText;
        var newHelpTexts = new Map();
        newHelpTexts.set(pileId, helpText);
        changePileHelpTexts(this.gameId, newHelpTexts);
    };
    Game.prototype.getPileHelpText = function (pileId) {
        if (!pileId || !this.piles.has(pileId))
            return '';
        var helpText = this.piles.get(pileId).helpText;
        return helpText ? helpText : '';
    };
    Game.prototype.setPileScale = function (pileId, scaleX, scaleY) {
        if (!pileId)
            return;
        if (this.piles.has(pileId)) {
            this.piles.get(pileId).cardScaleX = scaleX;
            this.piles.get(pileId).cardScaleY = scaleY;
        }
        var newScales = new Map();
        newScales.set(pileId, { x: scaleX, y: scaleY });
        changePileScales(this.gameId, newScales);
    };
    Game.prototype.getPileScale = function (pileId) {
        if (!pileId || !this.piles.has(pileId))
            return undefined;
        return {
            x: this.piles.get(pileId).cardScaleX,
            y: this.piles.get(pileId).cardScaleY
        };
    };
    Game.prototype.setPileRotation = function (pileId, rotation) {
        return __awaiter(this, void 0, void 0, function () {
            var newRotations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!pileId)
                            return [2 /*return*/];
                        if (this.piles.has(pileId)) {
                            this.piles.get(pileId).setRotation(rotation);
                        }
                        newRotations = new Map();
                        newRotations.set(pileId, rotation);
                        return [4 /*yield*/, changePileRotations(this.gameId, newRotations)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.getPileRotation = function (pileId) {
        if (!pileId || !this.piles.has(pileId))
            return 0;
        return this.piles.get(pileId).getRotation();
    };
    Game.prototype.setPileLabelSize = function (pileId, size) {
        return __awaiter(this, void 0, void 0, function () {
            var newSizes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!pileId)
                            return [2 /*return*/];
                        if (this.piles.has(pileId)) {
                            this.piles.get(pileId).labelSize = size;
                        }
                        newSizes = new Map();
                        newSizes.set(pileId, size);
                        return [4 /*yield*/, changePileLabelSizes(this.gameId, newSizes)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.getPileLabelSize = function (pileId) {
        if (!pileId || !this.piles.has(pileId))
            return 0;
        return this.piles.get(pileId).labelSize;
    };
    Game.prototype.editAddNewPileAtPosition = function (x, y) {
        return __awaiter(this, void 0, void 0, function () {
            var style, overrideStyle, lastPile, allStyles, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        overrideStyle = undefined;
                        if (this.piles.has(this.editLastPileId)) {
                            lastPile = this.piles.get(this.editLastPileId);
                            style = lastPile.pileStyle.id;
                            if (lastPile.styleOverridden)
                                overrideStyle = lastPile.pileStyle;
                        }
                        else if (this.editLastPileStyle != '') {
                            style = this.editLastPileStyle;
                        }
                        else {
                            allStyles = Array.from(this.pileStyles.keys()).sort();
                            for (i = 0; i < allStyles.length; i++) {
                                if (!this.pileStyles.get(allStyles[i]).hideFromList) {
                                    style = allStyles[i];
                                    break;
                                }
                            }
                        }
                        if (!style) {
                            // Super fallback, just manually force this style.
                            style = 'drawDeck';
                        }
                        return [4 /*yield*/, addEmptyNewPile(this.gameId, this.uniquePileId(), x - this.cardSheet.cardWidth / 2, y - this.cardSheet.cardHeight / 2, style, overrideStyle)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.editTogglePileStyle = function (pile, reverse) {
        if (reverse === void 0) { reverse = false; }
        return __awaiter(this, void 0, void 0, function () {
            var newStyle, allStyles, start, validStyles, i, idx, newStyles;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (pile.pileStyle.id == '')
                            return [2 /*return*/];
                        if (pile.pileStyle.placeholder == PlaceholderStyle.None)
                            return [2 /*return*/];
                        newStyle = undefined;
                        allStyles = Array.from(this.pileStyles.keys()).sort();
                        start = allStyles.findIndex(function (id) { return id == pile.pileStyle.id; });
                        if (start < 0) {
                            return [2 /*return*/];
                        }
                        validStyles = [];
                        for (i = 0; i < allStyles.length; i++) {
                            if (this.pileStyles.get(allStyles[i]).placeholder !=
                                PlaceholderStyle.None &&
                                !this.pileStyles.get(allStyles[i]).hideFromList) {
                                validStyles.push(allStyles[i]);
                            }
                        }
                        idx = validStyles.indexOf(pile.pileStyle.id);
                        if (idx == -1) {
                            idx = 0;
                        }
                        idx = idx + (reverse ? -1 : 1);
                        if (idx < 0)
                            idx += validStyles.length;
                        if (idx >= validStyles.length)
                            idx -= validStyles.length;
                        newStyle = validStyles[idx];
                        if (!newStyle) return [3 /*break*/, 2];
                        newStyles = new Map();
                        newStyles.set(pile.id, newStyle);
                        this.editLastPileStyle = newStyle;
                        this.editLastPileId = pile.id;
                        return [4 /*yield*/, changePileStyles(this.gameId, newStyles, true, true)];
                    case 1:
                        _a.sent(); // clear visibility list and overrides too
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.editModeMouseUp = function (e) {
        if (this.editDraggingPile) {
            if (this.editClickTime + constants.EDIT_TOGGLE_PILE_TYPE_MAX_MS >
                e.timeStamp &&
                !this.editClickStatePileId) {
                this.editTogglePileStyle(this.editDraggingPile, e.button == 2);
                this.buttons.renderAll();
            }
            else {
                movePile(this.gameId, this.editDraggingPile.id, this.editDraggingPile.posX, this.editDraggingPile.posY);
            }
            this.editDraggingPile = undefined;
            this.markCanvasDirty();
        }
    };
    Game.prototype.editModeMouseDown = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var collision, pile, uidToToggle, players, idx;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        collision = this.collisionManager.getCollisionAABB(e.offsetX, e.offsetY, e.offsetX, e.offsetY);
                        if (!(!collision || !collision.pile)) return [3 /*break*/, 4];
                        if (!(this.editClickState == EditClickState.AddingPile)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.editAddNewPileAtPosition(Math.round(e.offsetX), Math.round(e.offsetY))];
                    case 1:
                        _a.sent();
                        this.setEditClickState(EditClickState.Normal);
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.editClickState == EditClickState.ChoosingPileToEdit ||
                            // this.editClickState == EditClickState.EditingPile ||
                            this.editClickState == EditClickState.TogglingUser ||
                            this.editClickState == EditClickState.DeletingPile) {
                            this.setEditClickState(EditClickState.Normal);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                    case 4:
                        // If we clicked on a pile in the Add Pile state, exit the state.
                        if (this.editClickState == EditClickState.AddingPile) {
                            this.setEditClickState(EditClickState.Normal);
                        }
                        if (!(this.editClickState == EditClickState.DeletingPile)) return [3 /*break*/, 5];
                        // We don't actually delete the pile, just return it to the 'default'
                        // style, which doesn't appear in the editor. It still contains its cards
                        // though.
                        this.setPileStyleId(collision.pile.id, 'default');
                        this.setEditClickState(EditClickState.Normal);
                        return [2 /*return*/];
                    case 5:
                        if (!(this.editClickState == EditClickState.ChoosingPileToEdit)) return [3 /*break*/, 6];
                        this.setEditClickState(EditClickState.EditingPile, collision.pile.id);
                        return [2 /*return*/];
                    case 6:
                        if (!(this.editClickState == EditClickState.EditingPile &&
                            this.editClickStatePileId &&
                            this.piles.has(this.editClickStatePileId) &&
                            this.editChoosingPileToDrawFrom &&
                            collision.pile.id != this.editClickStatePileId)) return [3 /*break*/, 8];
                        pile = this.piles.get(this.editClickStatePileId);
                        pile.dealSettings.push({
                            fromPile: collision.pile.id,
                            numCards: 1,
                            onlyIfAssignedPlayer: false,
                            onlyIfPlayerCountAtLeast: 0,
                            onlyIfPlayerCountAtMost: 0,
                        });
                        return [4 /*yield*/, this.setPileDrawCardsOnReset(pile.id, pile.dealSettings)];
                    case 7:
                        _a.sent();
                        this.buttons.showDealSettingsNum = pile.dealSettings.length - 1;
                        this.setEditClickState(EditClickState.EditingPile, this.editClickStatePileId);
                        return [2 /*return*/];
                    case 8:
                        this.editChoosingPileToDrawFrom = false;
                        _a.label = 9;
                    case 9:
                        if (!(this.editClickState == EditClickState.TogglingUser)) return [3 /*break*/, 14];
                        uidToToggle = this.editClickStateUserId;
                        if (!(uidToToggle != undefined)) return [3 /*break*/, 13];
                        if (!(uidToToggle == '')) return [3 /*break*/, 11];
                        collision.pile.visibilityList = new Array();
                        return [4 /*yield*/, setPileVisibility(this.gameId, collision.pile.id, new Array())];
                    case 10:
                        _a.sent();
                        return [3 /*break*/, 13];
                    case 11:
                        if (!collision.pile.visibilityList) {
                            collision.pile.visibilityList = new Array();
                        }
                        players = __spreadArrays(collision.pile.visibilityList);
                        idx = void 0;
                        if ((idx = players.indexOf(uidToToggle)) != -1) {
                            players.splice(idx, 1);
                        }
                        else {
                            players.push(uidToToggle);
                        }
                        return [4 /*yield*/, setPileVisibility(this.gameId, collision.pile.id, players)];
                    case 12:
                        _a.sent();
                        _a.label = 13;
                    case 13:
                        this.setEditClickState(EditClickState.Normal);
                        return [2 /*return*/];
                    case 14:
                        this.editDraggingPile = collision.pile;
                        this.editLastPileStyle = this.editDraggingPile.pileStyle.id;
                        this.editLastPileId = this.editDraggingPile.id;
                        this.editClickTime = e.timeStamp;
                        this.mouseDownPosition = { x: e.offsetX, y: e.offsetY };
                        this.clickedCardOffset = {
                            x: this.editDraggingPile.posX - e.offsetX,
                            y: this.editDraggingPile.posY - e.offsetY
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    Game.prototype.editModeMouseMove = function (e) {
        if (this.editDraggingPile) {
            var moveThreshold = constants.EDIT_DRAG_MOVE_THRESHOLD;
            var distance = Math.abs(this.mouseDownPosition.x - e.offsetX) +
                Math.abs(this.mouseDownPosition.y - e.offsetY);
            if (distance > moveThreshold) {
                this.editClickTime = 0;
            }
            if (this.editClickTime + constants.EDIT_TOGGLE_PILE_TYPE_MAX_MS <
                e.timeStamp) {
                this.editDraggingPile.posX = e.offsetX + this.clickedCardOffset.x;
                this.editDraggingPile.posY = e.offsetY + this.clickedCardOffset.y;
                if (e.shiftKey) {
                    this.editDraggingPile.posX =
                        Math.round(this.editDraggingPile.posX / 8) * 8;
                    this.editDraggingPile.posY =
                        Math.round(this.editDraggingPile.posY / 8) * 8;
                }
                this.markCanvasDirty();
            }
        }
        else if (e.buttons == 0) {
            var hoverPile = this.collisionManager.getPileAtPosition(e.offsetX, e.offsetY);
            var helpText = hoverPile ? hoverPile.helpText : undefined;
            if (this.helpLabel != helpText) {
                this.helpLabel = helpText;
                this.buttons.renderAll();
            }
        }
    };
    Game.prototype.editMakeSelectedUserSpectator = function () {
        if (this.editClickState != EditClickState.TogglingUser ||
            !this.editClickStateUserId) {
            return;
        }
        var uidToEdit = this.editClickStateUserId;
        this.players.removePlayer(uidToEdit);
        this.spectators.addSpectator(uidToEdit);
        this.setEditClickState(EditClickState.Normal);
    };
    Game.prototype.leaveGame = function () {
        this.players.removePlayer(this.user.uid);
    };
    Game.prototype.updateShuffles = function (snap) {
        var _this = this;
        var shufflingPiles = new Set();
        if (!snap)
            return;
        snap.forEach(function (firstChild) {
            if (!firstChild || !firstChild.hasChild('shuffle')) {
                return;
            }
            var uid = firstChild.key;
            var child = firstChild.child('shuffle');
            if (!uid || !child || !child.val()) {
                return;
            }
            if (uid == _this.user.uid)
                return;
            child.forEach(function (pileSnap) {
                if (!pileSnap || !pileSnap.ref.key)
                    return;
                var pileId = pileSnap.ref.key.replace('|', '.');
                if (_this.piles.has(pileId)) {
                    _this.piles.get(pileId).animateShuffle(uid, pileSnap.val());
                }
            });
        });
    };
    Game.prototype.updateHighlights = function (snap) {
        var _this = this;
        var affectedCardIds = new Set();
        this.markCanvasDirty();
        if (!snap) {
            this.cards.forEach(function (card) {
                card.setHighlightedBy();
            });
            return;
        }
        snap.forEach(function (firstChild) {
            if (!firstChild || !firstChild.hasChild('highlight')) {
                return;
            }
            var uid = firstChild.key;
            var child = firstChild.child('highlight');
            if (!uid || !child || !child.val() || !child.key) {
                return;
            }
            var cardIds = child.val();
            cardIds.forEach(function (cardId) {
                var card = _this.cards.get(cardId);
                if (card) {
                    card.setHighlightedBy(uid);
                    affectedCardIds.add(card.id);
                }
            });
        });
        this.cards.forEach(function (card) {
            if (!affectedCardIds.has(card.id)) {
                // Any other cards, turn off the highlight.
                card.setHighlightedBy();
            }
        });
    };
    Game.prototype.updateDragAnimations = function (snap) {
        var _this = this;
        this.cards.forEach(function (card) {
            if (card.isDragBeingAnimated())
                // Will stop after a few updates if it doesn't get restarted.
                card.finishDragPosition();
        });
        this.markCanvasDirty();
        if (!snap)
            return;
        snap.forEach(function (firstChild) {
            if (!firstChild || !firstChild.hasChild('drag')) {
                return;
            }
            var uid = firstChild.key;
            var child = firstChild.child('drag');
            if (!uid || uid == _this.user.uid) {
                return;
            }
            if (!_this.players.players[uid] || !_this.players.players[uid].isOnline) {
                return; // don't let offline players move things
            }
            _this.applyDragAnimation(uid, child.val());
        });
    };
    Game.prototype.applyDragAnimation = function (uid, dragData) {
        for (var i = 0; i < dragData.length; i++) {
            if (this.cards.has(dragData[i].cardId) &&
                this.internalPiles.dragging.findCard(dragData[i].cardId) == -1) {
                this.cards.get(dragData[i].cardId).setDragPosition(dragData[i].x, dragData[i].y, uid);
                this.cards.get(dragData[i].cardId).setCardScale(1, 1); // scale down cards that are being dragged
            }
        }
    };
    Game.prototype.getAllHighlightedCards = function () {
        var allHighlightedCards = new Map();
        this.cards.forEach(function (card) {
            if (card.getHighlightedBy()) {
                if (!allHighlightedCards.has(card.getHighlightedBy())) {
                    allHighlightedCards.set(card.getHighlightedBy(), new Set());
                }
                allHighlightedCards.get(card.getHighlightedBy()).add(card.id);
            }
        });
        return allHighlightedCards;
    };
    Game.prototype.getMyHighlightedCards = function () {
        var myCards = this.getAllHighlightedCards();
        return myCards.has(this.user.uid) ? myCards.get(this.user.uid) : [];
    };
    return Game;
}());
export default Game;
