import { __awaiter, __generator } from "tslib";
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/remote-config';
import { createUserProfile, getUserProfile, setUserConnected, updateLoginUI } from './auth-stuff';
import { createElement } from './dom-helpers';
import { firebase } from './firebase';
import { GamesList } from './game-list';
import { clearGame, gamePage } from './game-page';
import { ProfilePage } from './profile-page';
import SheetEditor from './SheetEditor';
import { getUrlParams, utilSetFirebaseRef } from './utils';
var currentUser = null;
var gamesList = null;
var loginCheckComplete = false;
/**
 * Sets up a listener triggered whenever user logs in or out.
 * When this happens, the UI is updated accordingly, including
 * what the login button does.
 */
utilSetFirebaseRef(firebase);
firebase.analytics().setAnalyticsCollectionEnabled(true);
firebase.auth().onAuthStateChanged(function (user) { return __awaiter(void 0, void 0, void 0, function () {
    var topEl, userProfile, _a, gameId, profile, sheet, archive;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                loginCheckComplete = true;
                currentUser = user;
                updateLoginUI(currentUser);
                if (!!user) return [3 /*break*/, 1];
                // logged out
                if (gamesList)
                    gamesList.unsubscribeFromGamesList();
                clearGame();
                topEl = document.getElementById('htmlcontent');
                if (topEl) {
                    topEl.innerHTML = '';
                    topEl.appendChild(createElement({
                        text: 'You must log in to access this site.',
                        classes: ['not-logged-in-error-message']
                    }));
                }
                return [3 /*break*/, 5];
            case 1: return [4 /*yield*/, getUserProfile(user.uid)];
            case 2:
                userProfile = _b.sent();
                if (!(!userProfile || !userProfile.uid)) return [3 /*break*/, 4];
                return [4 /*yield*/, createUserProfile(user)];
            case 3:
                userProfile = _b.sent();
                _b.label = 4;
            case 4:
                // Set up presence detection.
                setUserConnected(user);
                _a = getUrlParams(), gameId = _a.game, profile = _a.profile, sheet = _a.sheet, archive = _a.archive;
                if (gameId && currentUser) {
                    gamesList = null;
                    gamePage(gameId, currentUser);
                }
                else if (profile && currentUser) {
                    new ProfilePage(currentUser.uid);
                }
                else if (sheet && currentUser) {
                    new SheetEditor(currentUser.uid, document.getElementById('htmlcontent'));
                }
                else {
                    if (currentUser) {
                        gamesList = new GamesList(currentUser, !!archive);
                    }
                }
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}); });
