import { __awaiter, __generator } from "tslib";
import 'firebase/database';
import 'firebase/auth';
import firebase from 'firebase/app';
import { subscribeToUserProfile, updateLoginUI } from './auth-stuff';
import { createElement } from './dom-helpers';
var ProfilePage = /** @class */ (function () {
    function ProfilePage(userId) {
        var _this = this;
        this.userId = userId;
        this.message = '';
        subscribeToUserProfile(this.userId, function (userProfile) {
            _this.userProfile = userProfile;
            _this.render();
        });
    }
    ProfilePage.prototype.setMessage = function (message) {
        this.message = message;
        this.render();
    };
    ProfilePage.prototype.render = function () {
        var _this = this;
        var topEl = document.getElementById('htmlcontent');
        if (!topEl) {
            console.error('htmlcontent element missing');
            return;
        }
        if (!this.userProfile) {
            return;
        }
        topEl.innerHTML = '';
        var containerEl = createElement({ tag: 'form', classes: 'profile-container' });
        var labelEl = createElement({ text: 'Display name', classes: 'display-name-label' });
        var inputEl = createElement({
            tag: 'input',
            text: this.userProfile.displayName,
            classes: 'display-name-input'
        });
        inputEl.addEventListener('input', function (e) {
            messageEl.textContent = '';
            if (e.target.value !==
                _this.userProfile.displayName) {
                submitButton.disabled = false;
            }
            else {
                submitButton.disabled = true;
            }
        });
        var submitButton = createElement({
            tag: 'button',
            text: 'change',
            classes: 'display-name-button',
            disabled: true
        });
        var messageEl = createElement({ text: this.message });
        containerEl.appendChild(labelEl);
        containerEl.appendChild(inputEl);
        containerEl.appendChild(submitButton);
        containerEl.appendChild(messageEl);
        containerEl.addEventListener('submit', function (e) { return __awaiter(_this, void 0, void 0, function () {
            var databaseChange, authChange, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        e.preventDefault();
                        this.setMessage('');
                        submitButton.disabled = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        databaseChange = firebase.database().ref("users/" + this.userId).update({
                            displayName: inputEl.value
                        });
                        return [4 /*yield*/, ((_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.updateProfile({ displayName: inputEl.value }))];
                    case 2:
                        authChange = _b.sent();
                        return [4 /*yield*/, Promise.all([databaseChange, authChange])];
                    case 3:
                        _b.sent();
                        updateLoginUI(firebase.auth().currentUser);
                        this.setMessage('Name change successful!');
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        this.setMessage('Name change updated failed.');
                        return [3 /*break*/, 5];
                    case 5:
                        submitButton.disabled = false;
                        return [2 /*return*/];
                }
            });
        }); });
        topEl.appendChild(containerEl);
    };
    return ProfilePage;
}());
export { ProfilePage };
