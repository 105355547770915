import { __assign, __awaiter, __generator } from "tslib";
import 'firebase/firestore';
import 'firebase/database';
import firebase from 'firebase/app';
import { updatePlayerColor, updatePlayerScore } from './actions';
import { subscribeToUserProfile } from './auth-stuff';
import constants from './constants';
import { createElement } from './dom-helpers';
import { getGameInstance } from './game-page';
import { EditClickState } from './types';
import { getAsHexColor, isColorLight, logReadWriteOperation, logWriteOperation } from './utils';
var Players = /** @class */ (function () {
    function Players(game, user) {
        var _this = this;
        var _a;
        this.game = game;
        this.user = user;
        this.players = {};
        this.playerSubscriptions = {};
        this.playerScores = {};
        this.editScoreForUser = '';
        this.editColorForUser = '';
        this.playerListRef = firebase.database().ref("players/" + this.game.gameId);
        // this.gameRef =
        // firebase.firestore().collection('games').doc(this.game.gameId).withConverter(gameConverter);
        this.initialPlayerFetch = this.subscribeToPlayerData();
        this.container = document.createElement('div');
        this.container.className = 'players-container';
        this.container.setAttribute('style', "width: " + game.canvas.width + "px");
        var gameButtonsEl = document.querySelector('.game-buttons-container');
        (_a = game.canvas.parentElement) === null || _a === void 0 ? void 0 : _a.insertBefore(this.container, gameButtonsEl || game.canvas);
        this.renderAll();
        // Cancel subscriptions if user navigates to another page.
        window.addEventListener('beforeunload', function () {
            // RTDB subscriptions
            for (var id in _this.playerSubscriptions) {
                _this.playerSubscriptions[id].off();
                delete _this.playerSubscriptions[id];
            }
            _this.playerListRef.off();
        });
    }
    Players.prototype.addPlayer = function (uid) {
        return __awaiter(this, void 0, void 0, function () {
            var playerRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logWriteOperation('addPlayer', 'rtdb');
                        playerRef = this.playerListRef.child(uid);
                        return [4 /*yield*/, this.playerListRef.transaction(function (players) {
                                if (players == null) {
                                    players = new Object();
                                }
                                // If we're already in the list, just update it.
                                if (players[uid]) {
                                    return players;
                                }
                                // Find first available player index.
                                var i = 0;
                                var _loop_1 = function () {
                                    var available = true;
                                    Object.keys(players).forEach(function (key) {
                                        var user = players[key];
                                        if (user.idx == i) {
                                            available = false;
                                        }
                                    });
                                    if (available) {
                                        return "break";
                                    }
                                    i++;
                                };
                                do {
                                    var state_1 = _loop_1();
                                    if (state_1 === "break")
                                        break;
                                } while (i < Object.keys(players).length + 1);
                                var idx = i;
                                players[uid] = {
                                    role: 'player',
                                    idx: idx,
                                    colorIndex: idx % constants.PLAYER_COLORS.length,
                                    score: 0,
                                };
                                return players;
                            })];
                    case 1:
                        _a.sent();
                        this.renderAll();
                        return [2 /*return*/];
                }
            });
        });
    };
    Players.prototype.removePlayer = function (uid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // if (this.playerSubscriptions[uid]) {
                        // this.playerSubscriptions[uid].off();
                        // delete this.playerSubscriptions[uid];
                        //}
                        logWriteOperation('removePlayer', 'rtdb');
                        return [4 /*yield*/, this.playerListRef.child(uid).remove()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Players.prototype.keys = function () {
        return Object.keys(this.players);
    };
    // True if the ID is in this players list.
    Players.prototype.has = function (userId) {
        if (this.players[userId]) {
            return true;
        }
        return false;
    };
    Object.defineProperty(Players.prototype, "size", {
        get: function () {
            return Object.keys(this.players).length;
        },
        enumerable: true,
        configurable: true
    });
    Players.prototype.subscribeToSingleUser = function (userId, playerInfo) {
        var _this = this;
        if (!this.players[userId])
            this.players[userId] = playerInfo;
        this.playerSubscriptions[userId] =
            subscribeToUserProfile(userId, function (userProfile) {
                if (!userId)
                    return;
                _this.players[userId] = __assign(__assign({}, playerInfo), userProfile);
                _this.renderAll();
            });
    };
    Players.prototype.subscribeToPlayerData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logReadWriteOperation('updatePlayers', 'rtdb');
                        // One-time initial fetch of entire list.
                        // Triggers when a player ID is added to the list.
                        this.playerListRef.on('child_added', function (playerSnap) {
                            if (playerSnap.ref.key) {
                                var uid = playerSnap.ref.key;
                                _this.subscribeToSingleUser(uid, playerSnap.val());
                                _this.playerScores[uid] = playerSnap.val().score;
                                _this.players[uid].score = _this.playerScores[uid];
                                _this.players[uid].customColor = playerSnap.val().customColor;
                            }
                            _this.renderAll();
                        });
                        // Triggers when a player ID is removed from the list.
                        this.playerListRef.on('child_removed', function (playerSnap) {
                            if (playerSnap.ref.key) {
                                var uid = playerSnap.ref.key;
                                delete _this.players[uid];
                                // this.playerSubscriptions[uid]?.off(); // don't unsubscribe as they
                                // are a spectator
                                delete _this.playerSubscriptions[uid];
                                _this.renderAll();
                                if (uid == _this.user.uid && getGameInstance()) {
                                    getGameInstance().buttons.setUserIsPlayer(false);
                                }
                            }
                        });
                        this.playerListRef.on('child_changed', function (playerSnap) {
                            if (playerSnap.ref.key) {
                                var uid = playerSnap.ref.key;
                                if (_this.playerScores[uid] != playerSnap.val().score) {
                                    _this.playerScores[uid] = playerSnap.val().score;
                                    _this.players[uid].score = _this.playerScores[uid];
                                }
                                else if (_this.players[uid].customColor != playerSnap.val().customColor) {
                                    _this.players[uid].customColor = playerSnap.val().customColor;
                                }
                                _this.renderAll();
                            }
                        });
                        return [4 /*yield*/, this.playerListRef.once('value', function (listSnap) {
                                listSnap.forEach(function (item) {
                                    if (item.ref.key) {
                                        var uid = item.ref.key;
                                        _this.subscribeToSingleUser(uid, item.val());
                                        _this.playerScores[uid] = item.val().score;
                                        _this.players[uid].score = _this.playerScores[uid];
                                        _this.players[uid].customColor = item.val().customColor;
                                    }
                                });
                                _this.renderAll();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Players.prototype.getDisplayName = function (uid) {
        if (this.players[uid]) {
            return this.players[uid].displayName;
        }
        return '[unknown]';
    };
    Players.prototype.getPlayerColor = function (uid) {
        if (this.players[uid]) {
            return this.players[uid].customColor ?
                this.players[uid].customColor :
                constants.PLAYER_COLORS[this.players[uid].colorIndex % constants.PLAYER_COLORS.length];
        }
        return 'transparent';
    };
    Players.prototype.rightClickUser = function (uid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.game.editMode) {
                }
                else {
                    if (this.editColorForUser != uid) {
                        this.editColorForUser = uid;
                        this.renderAll();
                        try {
                            document.getElementById('color-input').focus();
                            document.getElementById('color-input').select();
                        }
                        catch (e) {
                        }
                    }
                    else {
                        this.editScoreForUser = '';
                        this.renderAll();
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    Players.prototype.cancelClickUser = function () {
        this.editColorForUser = '';
        this.editScoreForUser = '';
        this.renderAll();
    };
    Players.prototype.clickUser = function (uid, onInputField) {
        if (onInputField === void 0) { onInputField = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.has(this.game.user.uid)) {
                    return [2 /*return*/]; // Can't edit score as a spectator
                }
                if (this.game.editMode) {
                    this.game.setEditClickState(EditClickState.TogglingUser, uid);
                }
                else {
                    if (this.editColorForUser == '') {
                        if (!onInputField && this.editScoreForUser == uid) {
                            this.cancelClickUser();
                        }
                        else if (this.editScoreForUser != uid) {
                            this.editScoreForUser = uid;
                            this.renderAll();
                            try {
                                document.getElementById('score-input')
                                    .focus();
                                document.getElementById('score-input')
                                    .select();
                            }
                            catch (e) {
                                alert(e);
                            }
                        }
                    }
                    else {
                        if (!onInputField)
                            this.cancelClickUser();
                    }
                    this.editColorForUser = '';
                }
                return [2 /*return*/];
            });
        });
    };
    Players.prototype.renderAll = function () {
        var _this = this;
        this.container.innerHTML = '';
        var otherPlayerBoxes = [];
        var _loop_2 = function (playerId) {
            var player = this_1.players[playerId];
            var el = createElement({ classes: 'player-box' });
            el.setAttribute('style', "color: " + this_1.getPlayerColor(playerId) + "; border-color: " + this_1.getPlayerColor(playerId) + ";");
            var nameEl = createElement();
            if (!player.isOnline) {
                el.classList.add('offline');
                nameEl.textContent = player.displayName + ' (offline)';
            }
            else {
                nameEl.textContent = player.displayName || '';
            }
            if (isColorLight(this_1.getPlayerColor(playerId))) {
                el.classList.add('dark');
            }
            el.appendChild(nameEl);
            if (!this_1.game.editMode) {
                if (this_1.editColorForUser == playerId) {
                    nameEl.textContent += ' color';
                    var colorEl_1 = createElement({
                        tag: 'input',
                        classes: ['color-input'],
                        id: 'color-input',
                        defaultValue: "" + this_1.getPlayerColor(playerId),
                    });
                    this_1.game.buttons.addHelpText([el], 'Enter a new color for this user, or blank to restore default.');
                    colorEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                        var colorHex, newColor;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    colorHex = getAsHexColor(colorEl_1.value);
                                    if (!(colorEl_1.value == '' || colorHex != null)) return [3 /*break*/, 2];
                                    newColor = colorEl_1.value == '' ? null : colorHex;
                                    if (!(this.players[playerId].customColor != newColor)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, updatePlayerColor(this.game.gameId, playerId, newColor)];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2:
                                    this.editColorForUser = '';
                                    this.renderAll();
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    colorEl_1.onkeydown = function (e) {
                        if (e.keyCode == 13) {
                            _this.editColorForUser = '';
                            _this.renderAll();
                        }
                    };
                    colorEl_1.addEventListener('contextmenu', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        _this.rightClickUser(playerId);
                        return false;
                    });
                    colorEl_1.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            e.stopPropagation();
                            return [2 /*return*/];
                        });
                    }); });
                    el.append(colorEl_1);
                }
                else if (this_1.editScoreForUser == playerId) {
                    nameEl.textContent += ' score';
                    var scoreEl_1 = createElement({
                        tag: 'input',
                        classes: ['score-input'],
                        id: 'score-input',
                        defaultValue: player.score.toString(),
                    });
                    this_1.game.buttons.addHelpText([el], 'Enter a new score, or an amount to change the score by (ex: 30, +10, -20)');
                    scoreEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(scoreEl_1.value != '' &&
                                        scoreEl_1.value.match(/^(\+|\-|)[0-9]+$/))) return [3 /*break*/, 2];
                                    return [4 /*yield*/, updatePlayerScore(this.game.gameId, playerId, scoreEl_1.value)];
                                case 1:
                                    _a.sent();
                                    this.editScoreForUser = '';
                                    this.renderAll();
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); };
                    scoreEl_1.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            e.stopPropagation();
                            e.preventDefault();
                            this.clickUser(playerId, true);
                            return [2 /*return*/];
                        });
                    }); });
                    el.append(scoreEl_1);
                }
                else {
                    var scoreEl = createElement({ classes: 'score', text: player.score.toString() });
                    el.append(scoreEl);
                    this_1.game.buttons.addHelpText([el], 'Click on a player to modify their score. Right-click to change their color.');
                    el.addEventListener('contextmenu', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        _this.rightClickUser(playerId);
                        return false;
                    });
                    el.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            e.stopPropagation();
                            e.preventDefault();
                            this.clickUser(playerId, true);
                            return [2 /*return*/];
                        });
                    }); });
                }
            }
            nameEl.addEventListener('contextmenu', function (e) {
                e.preventDefault();
                e.stopPropagation();
                _this.rightClickUser(playerId);
                return false;
            });
            nameEl.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.clickUser(playerId, false);
                    return [2 /*return*/];
                });
            }); });
            if (playerId === this_1.game.user.uid) {
                this_1.container.appendChild(el);
            }
            else {
                otherPlayerBoxes.push(el);
            }
            this_1.container.onclick = function () {
                _this.cancelClickUser();
            };
        };
        var this_1 = this;
        for (var playerId in this.players) {
            _loop_2(playerId);
        }
        for (var _i = 0, otherPlayerBoxes_1 = otherPlayerBoxes; _i < otherPlayerBoxes_1.length; _i++) {
            var otherPlayerBox = otherPlayerBoxes_1[_i];
            this.container.appendChild(otherPlayerBox);
        }
        if (this.game.editMode) {
            var hint = createElement({
                text: 'Click a user to toggle pile visibility',
                classes: 'arrow-box'
            });
            this.container.appendChild(hint);
        }
        if (this.game.editMode) {
            var el = createElement({
                classes: 'player-clear-box',
                style: '',
                text: 'reset pile visibility'
            });
            el.setAttribute('style', 'color: gray; border-color: gray');
            el.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.clickUser('')];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            }); }); });
            this.container.appendChild(el);
        }
        this.game.markCanvasDirty();
    };
    return Players;
}());
export { Players };
