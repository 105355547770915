import { __awaiter, __generator } from "tslib";
import 'firebase/database';
import { subscribeToUserProfile } from './auth-stuff';
import { createElement } from './dom-helpers';
import { firebase } from './firebase';
import { logReadWriteOperation, logWriteOperation } from './utils';
var Spectators = /** @class */ (function () {
    function Spectators(game, user) {
        var _this = this;
        var _a;
        this.game = game;
        this.user = user;
        this.spectators = {};
        this.spectatorSubscriptions = {};
        this.spectatorListRef =
            firebase.database().ref("spectators/" + this.game.gameId);
        this.subscribeToSpectatorData();
        this.container = document.createElement('div');
        this.container.className = 'spectators-section';
        this.container.setAttribute('style', "width: " + game.canvas.width + "px");
        (_a = game.canvas.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(this.container);
        this.renderAll();
        // Cancel subscriptions if user navigates to another page.
        window.addEventListener('beforeunload', function () {
            // RTDB subscriptions
            for (var id in _this.spectatorSubscriptions) {
                _this.spectatorSubscriptions[id].off();
            }
            _this.spectatorListRef.off();
        });
    }
    Spectators.prototype.addSpectator = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var spectatorRef;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logWriteOperation('addSpectator', 'rtdb');
                        spectatorRef = this.spectatorListRef.child(userId);
                        if (userId === this.user.uid) {
                            spectatorRef.onDisconnect().remove();
                        }
                        return [4 /*yield*/, spectatorRef.set(true)];
                    case 1:
                        _a.sent();
                        this.renderAll();
                        return [2 /*return*/];
                }
            });
        });
    };
    Spectators.prototype.removeSpectator = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logWriteOperation('removeSpectator', 'rtdb');
                        if (userId === this.user.uid) {
                            this.spectatorListRef.child(userId).onDisconnect().cancel();
                        }
                        if (this.spectatorSubscriptions[userId]) {
                            this.spectatorSubscriptions[userId].off();
                            delete this.spectatorSubscriptions[userId];
                        }
                        logReadWriteOperation('removeSpectator', 'rtdb');
                        return [4 /*yield*/, this.spectatorListRef.child(userId).remove()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // True if the ID is in spectators.
    Spectators.prototype.has = function (userId) {
        if (this.spectators[userId]) {
            return true;
        }
        return false;
    };
    Object.defineProperty(Spectators.prototype, "size", {
        get: function () {
            return Object.keys(this.spectators).length;
        },
        enumerable: true,
        configurable: true
    });
    Spectators.prototype.subscribeToSingleUser = function (userId) {
        var _this = this;
        {
            if (this.spectatorSubscriptions[userId])
                return;
            this.spectatorSubscriptions[userId] =
                subscribeToUserProfile(userId, function (userProfile) {
                    if (!userId)
                        return;
                    _this.spectators[userId] = userProfile;
                    _this.renderAll();
                });
        }
    };
    Spectators.prototype.subscribeToSpectatorData = function () {
        var _this = this;
        logReadWriteOperation('updateSpectators', 'rtdb');
        // One-time initial fetch of entire list.
        this.spectatorListRef.once('value', function (listSnap) {
            listSnap.forEach(function (item) {
                if (item.ref.key) {
                    _this.subscribeToSingleUser(item.ref.key);
                }
            });
        });
        // Triggers when a spectator ID is added to the list.
        this.spectatorListRef.on('child_added', function (spectatorSnap) {
            if (spectatorSnap.ref.key) {
                _this.subscribeToSingleUser(spectatorSnap.ref.key);
            }
        });
        // Triggers when a spectator ID is removed from the list.
        this.spectatorListRef.on('child_removed', function (spectatorSnap) {
            var _a;
            if (spectatorSnap.ref.key) {
                delete _this.spectators[spectatorSnap.ref.key];
                (_a = _this.spectatorSubscriptions[spectatorSnap.ref.key]) === null || _a === void 0 ? void 0 : _a.off();
                delete _this.spectatorSubscriptions[spectatorSnap.ref.key];
                _this.renderAll();
            }
        });
    };
    Spectators.prototype.renderAll = function () {
        this.container.innerHTML = '';
        var otherSpectatorBoxes = [];
        this.container.appendChild(createElement({ text: "spectators (" + this.size + ")", classes: 'spectator-title' }));
        var spectatorsContainerEl = createElement({ classes: 'spectators-container' });
        this.container.appendChild(spectatorsContainerEl);
        for (var spectatorId in this.spectators) {
            var spectator = this.spectators[spectatorId];
            var el = createElement({ classes: 'spectator-box', text: spectator.displayName || '' });
            if (!spectator.isOnline) {
                el.classList.add('offline');
                el.textContent = spectator.displayName + ' (offline)';
            }
            else {
                el.textContent = spectator.displayName || '';
            }
            if (spectatorId === this.game.user.uid) {
                spectatorsContainerEl.appendChild(el);
            }
            else {
                otherSpectatorBoxes.push(el);
            }
        }
        for (var _i = 0, otherSpectatorBoxes_1 = otherSpectatorBoxes; _i < otherSpectatorBoxes_1.length; _i++) {
            var otherSpectatorBox = otherSpectatorBoxes_1[_i];
            spectatorsContainerEl.appendChild(otherSpectatorBox);
        }
    };
    return Spectators;
}());
export { Spectators };
