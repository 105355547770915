export function loadImage(src) {
    return new Promise(function (resolve, reject) {
        var image = new Image();
        image.onload = function () { return resolve(image); };
        image.onerror = function () { return reject(); };
        image.src = src;
    });
}
export function drawArrow(ctx, fx, fy, tx, ty, color, width) {
    if (color === void 0) { color = 'red'; }
    if (width === void 0) { width = 7; }
    ctx.save();
    var angle = Math.atan2(ty - fy, tx - fx);
    ctx.beginPath();
    ctx.moveTo(fx, fy);
    ctx.lineTo(tx, ty);
    var w = width / 2; // width of arrow to one side. 7 pixels wide arrow is pretty
    angle = angle + Math.PI / 2;
    tx = tx + w * Math.cos(angle);
    ty = ty + w * Math.sin(angle);
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.lineWidth = w;
    ctx.lineTo(tx, ty);
    // Drawing an isosceles triangle of sides proportional to 2:7:2
    angle = angle - 1.849096;
    tx = tx + w * 3.5 * Math.cos(angle);
    ty = ty + w * 3.5 * Math.sin(angle);
    ctx.lineTo(tx, ty);
    angle = angle - 2.584993;
    tx = tx + w * 3.5 * Math.cos(angle);
    ty = ty + w * 3.5 * Math.sin(angle);
    ctx.lineTo(tx, ty);
    angle = angle - 1.849096;
    tx = tx + w * Math.cos(angle);
    ty = ty + w * Math.sin(angle);
    ctx.lineTo(tx, ty);
    ctx.stroke();
    ctx.fill();
    ctx.closePath();
    ctx.restore();
}
