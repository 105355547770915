import { __awaiter, __generator } from "tslib";
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import { firebase } from './firebase';
import { logReadOperation, logReadWriteOperation } from './utils';
var provider = new firebase.auth.GoogleAuthProvider();
export function updateLoginUI(currentUser) {
    var rightNav = document.getElementById('rightnav');
    if (!rightNav)
        return;
    rightNav.innerHTML = '';
    if (currentUser) {
        var usernameEl = document.createElement('div');
        usernameEl.classList.add('username');
        usernameEl.textContent = "logged in as " + currentUser.displayName;
        var logoutButton = document.createElement('button');
        logoutButton.textContent = 'log out';
        logoutButton.addEventListener('click', signOut);
        var profileButton = document.createElement('button');
        profileButton.textContent = 'edit profile';
        profileButton.addEventListener('click', function () { return window.location.href = '?profile=true'; });
        rightNav.appendChild(usernameEl);
        rightNav.appendChild(profileButton);
        rightNav.appendChild(logoutButton);
    }
    else {
        var loginButton = document.createElement('button');
        loginButton.textContent = 'log in';
        loginButton.addEventListener('click', signIn);
        rightNav.appendChild(loginButton);
    }
}
function signOut() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, firebase.auth().signOut()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function signIn() {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, firebase.auth().signInWithPopup(provider)];
                case 1:
                    result = _b.sent();
                    firebase.analytics().logEvent('login', { 'method': provider.providerId });
                    // Create new RTDB user profile if this is a new user.
                    if (result.user && ((_a = result.additionalUserInfo) === null || _a === void 0 ? void 0 : _a.isNewUser)) {
                        createUserProfile(result.user);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _b.sent();
                    // TODO: Show in the page.
                    console.error('Error logging in with Google.', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function createUserProfile(user) {
    return __awaiter(this, void 0, void 0, function () {
        var initialUserProfile;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    initialUserProfile = {
                        email: user.email || '',
                        displayName: user.displayName || '',
                        uid: user.uid
                    };
                    return [4 /*yield*/, firebase.database().ref("users/" + user.uid).set(initialUserProfile)];
                case 1:
                    _a.sent();
                    return [2 /*return*/, initialUserProfile];
            }
        });
    });
}
export function setUserConnected(user) {
    var userConnectedRef = firebase.database().ref("users/" + user.uid + "/connected");
    var lastOnlineRef = firebase.database().ref("users/" + user.uid + "/lastOnline");
    var connectedRef = firebase.database().ref('.info/connected');
    connectedRef.on('value', function (snap) {
        if (snap.val() === true) {
            logReadWriteOperation('userConnected', 'rtdb');
            userConnectedRef.onDisconnect().remove();
            userConnectedRef.set(true);
            lastOnlineRef.onDisconnect().set(firebase.database.ServerValue.TIMESTAMP);
        }
    });
}
export function subscribeToUserProfile(uid, onValue) {
    var ref = firebase.database().ref("users/" + uid);
    ref.on('value', function (snap) {
        logReadOperation('readUserProfile', 'rtdb');
        if (!snap.val()) {
            // invalid user, never mind
        }
        else {
            onValue({
                email: snap.val().email,
                uid: snap.val().uid,
                displayName: snap.val().displayName,
                lastOnline: snap.val().lastOnline,
                isOnline: !!snap.val().connected
            });
        }
    });
    return ref;
}
export function getUserProfile(uid) {
    logReadOperation('getUserProfile', 'rtdb');
    return firebase.database()
        .ref("users/" + uid)
        .once('value')
        .then(function (snap) { return snap.val(); });
}
