import constants from './constants';
import { getGameInstance } from './game-page';
import { UXMode } from './types';
import { roundedRect } from './utils';
var Card = /** @class */ (function () {
    function Card(id, frontImage, backImage) {
        this.faceUp = true;
        // Used for animating the flip.
        this.flipAnimState = -1;
        this.rotationCooldown = -1;
        this.goalX = -9999;
        this.goalY = -9999;
        this.x = 0;
        this.y = 0;
        this.cardScaleX = 1;
        this.cardScaleY = 1;
        this.cardScaleGoalX = -1; // multiplied by pile.cardScaleX
        this.cardScaleGoalY = -1; // multiplied by pile.cardScaleY
        this.flipAnimMax = 100; // Doesn't affect the speed, just used for ratio.
        this.viewFlipped = false; // View this card flipped the opposite.
        this.lastEditTimestamp = 0; // When was this card last updated?
        this.peek = false;
        this.isBeingDragged = false;
        // Settings for when the server is overriding our location/highlight.
        this.overridePos = false;
        this.stopOverridingPosTimer = 0;
        this.overrideX = 0;
        this.overrideY = 0;
        this.id = id;
        this.frontImage = frontImage;
        this.backImage = backImage;
        this.width = frontImage.width;
        this.height = frontImage.height;
        this.faceUp = true;
        this.goalRotation = this.currRotation = 0;
    }
    Card.prototype.upperLeft = function () {
        return {
            x: this.center().x - this.displaySize().width / 2,
            y: this.center().y - this.displaySize().height / 2,
        };
    };
    Card.prototype.pos = function () {
        return { x: this.x, y: this.y };
    };
    Card.prototype.goal = function () {
        return { x: this.goalX, y: this.goalY };
    };
    Card.prototype.bottomRight = function () {
        return {
            x: this.center().x + this.displaySize().width / 2,
            y: this.center().y + this.displaySize().height / 2,
        };
    };
    Card.prototype.center = function () {
        return {
            x: Math.round(this.x +
                this.renderScale().x * this.imageSize().width / this.cardScaleX / 2),
            y: Math.round(this.y +
                this.renderScale().y * this.imageSize().height / this.cardScaleY / 2)
        };
    };
    Card.prototype.getRotation = function () {
        return this.goalRotation;
    };
    Card.prototype.setCardScale = function (x, y) {
        if (y === void 0) { y = x; }
        if (this.cardScaleGoalX == -1 || this.cardScaleGoalY == -1) {
            this.cardScaleGoalX = x;
            this.cardScaleGoalY = y;
            this.cardScaleX =
                this.cardScaleGoalX * (this.cardPile ? this.cardPile.cardScaleX : 1);
            this.cardScaleY =
                this.cardScaleGoalY * (this.cardPile ? this.cardPile.cardScaleY : 1);
            return true;
        }
        else {
            var change = (this.cardScaleGoalX != x || this.cardScaleGoalY != y);
            this.cardScaleGoalX = x;
            this.cardScaleGoalY = y;
            return change;
        }
    };
    Card.prototype.setViewFlipped = function (b) {
        this.viewFlipped = b;
    };
    Card.prototype.getCardScale = function () {
        return {
            x: this.cardScaleX,
            y: this.cardScaleY,
        };
    };
    Card.prototype.renderScale = function () {
        var currScale = getGameInstance().gameScale;
        return { x: currScale * this.cardScaleX, y: currScale * this.cardScaleY };
    };
    Card.prototype.displayScale = function () {
        var currScale = getGameInstance().gameScale;
        return (this.goalRotation == 90 || this.goalRotation == 270) ?
            { y: currScale * this.cardScaleX, x: currScale * this.cardScaleY } :
            { x: currScale * this.cardScaleX, y: currScale * this.cardScaleY };
    };
    Card.prototype.setRotation = function (r) {
        if (this.rotationCooldown == -1) {
            this.rotationCooldown = 0;
            this.goalRotation = this.currRotation =
                ((r == 90 || r == 180 || r == 270) ? r : 0);
            return;
        }
        if (this.goalRotation != r) {
            this.pauseRotation();
        }
        if (r == 90 || r == 180 || r == 270)
            this.goalRotation = r;
        else
            this.goalRotation = 0;
    };
    Card.prototype.isRotationAnimating = function () {
        return this.goalRotation != this.currRotation;
    };
    Card.prototype.setHighlightedBy = function (u) {
        if (u && this.overrideHighlightedByTimerId) {
            clearTimeout(this.overrideHighlightedByTimerId);
            this.overrideHighlightedByTimerId = undefined;
            this.overrideHighlightedById = undefined;
        }
        this.highlightedBy = u;
    };
    Card.prototype.getHighlightedBy = function () {
        return this.highlightedBy;
    };
    Card.prototype.overrideHighlight = function (uid, timeout) {
        var _this = this;
        if (this.overrideHighlightedByTimerId)
            clearTimeout(this.overrideHighlightedByTimerId);
        this.overrideHighlightedById = uid;
        this.overrideHighlightedByTimerId = setTimeout(function () {
            _this.overrideHighlightedById = undefined;
            _this.overrideHighlightedByTimerId = undefined;
            getGameInstance().markCanvasDirty();
        }, timeout);
    };
    // If rotated 90 degrees, swap width and height for some calculations.
    Card.prototype.displaySize = function () {
        return { width: this.getDisplayWidth(), height: this.getDisplayHeight() };
    };
    Card.prototype.renderSize = function () {
        // similar to imageSize() but applies scale.
        return {
            width: this.width * this.renderScale().x,
            height: this.height * this.renderScale().y
        };
    };
    Card.prototype.imageSize = function () {
        return { width: this.width, height: this.height };
    };
    Card.prototype.getDisplayWidth = function () {
        return this.displayScale().x *
            ((this.goalRotation == 90 || this.goalRotation == 270) ? this.height :
                this.width);
    };
    Card.prototype.getDisplayHeight = function () {
        return this.displayScale().y *
            ((this.goalRotation == 90 || this.goalRotation == 270) ? this.width :
                this.height);
    };
    Card.prototype.getCurrentImage = function () {
        var faceUp = this.isFaceUp();
        if (this.viewFlipped)
            faceUp = !faceUp;
        return faceUp ? this.frontImage : this.backImage;
    };
    Card.prototype.isFaceUp = function () {
        return this.faceUp;
    };
    Card.prototype.pauseRotation = function () {
        this.rotationCooldown = constants.ROTATION_COOLDOWN_FRAMES;
    };
    Card.prototype.setPosition = function (x, y) {
        this.x = this.goalX = x;
        this.y = this.goalY = y;
    };
    Card.prototype.movePosition = function (x, y) {
        if (this.goalX == -9999 || this.goalY == -9999) {
            this.setPosition(x, y);
        }
        else {
            this.goalX = x;
            this.goalY = y;
        }
    };
    Card.prototype.setFaceUp = function (faceUp) {
        if (this.flipAnimState == -1) {
            this.faceUp = faceUp;
            this.flipAnimState = 0;
        }
        else if (this.faceUp != faceUp) {
            this.faceUp = faceUp;
            this.flipAnimState = this.flipAnimMax - this.flipAnimState;
        }
    };
    Card.prototype.isAnimating = function () {
        if (this.x != this.goalX)
            return true;
        if (this.y != this.goalY)
            return true;
        if (this.flipAnimState > 0)
            return true;
        return this.isRotationAnimating();
    };
    Card.prototype.canRotate = function () {
        return !this.isAnimating() && !this.isRotationAnimating() &&
            this.rotationCooldown == 0 &&
            (!this.cardPile || this.cardPile.getRotation() == -1);
    };
    Card.prototype.canFlip = function () {
        return !this.isAnimating() && !this.isRotationAnimating() &&
            this.rotationCooldown == 0;
    };
    Card.prototype.finishDragPosition = function () {
        if (this.stopOverridingPosTimer == 0) {
            this.stopOverridingPosTimer = constants.ANIMATION_SNAP_BACK_DELAY_FRAMES;
        }
    };
    Card.prototype.clearDragPosition = function () {
        if (this.overridePos) {
            this.overridePos = false;
            this.stopOverridingPosTimer = 0;
            this.goalX = this.overrideX;
            this.goalY = this.overrideY;
        }
    };
    Card.prototype.setDragPosition = function (x, y, uid) {
        this.overridePos = true;
        this.stopOverridingPosTimer = 0;
        this.overrideX = x;
        this.overrideY = y;
        this.highlightedBy = uid;
    };
    Card.prototype.isDragBeingAnimated = function () {
        return this.overridePos;
    };
    Card.prototype.isDragEnding = function () {
        return this.stopOverridingPosTimer;
    };
    Card.prototype.draw = function (ctx, updateOnly) {
        if (updateOnly === void 0) { updateOnly = false; }
        var animationSpeed = constants.ANIMATION_SPEED_PCT;
        var isMoving = false;
        if (this.overridePos) {
            this.goalX = this.overrideX;
            this.goalY = this.overrideY;
        }
        if (this.goalX != -9999 && this.goalY != -9999) {
            if (this.x != this.goalX) {
                isMoving = true;
                this.x =
                    Math.round(animationSpeed * this.goalX + (100 - animationSpeed) * this.x) /
                        100;
                if (Math.abs(this.x - this.goalX) <= 2) {
                    this.x = this.goalX;
                }
            }
            if (this.y != this.goalY) {
                isMoving = true;
                this.y =
                    Math.round(animationSpeed * this.goalY + (100 - animationSpeed) * this.y) /
                        100;
                if (Math.abs(this.y - this.goalY) <= 2) {
                    this.y = this.goalY;
                }
            }
        }
        // currentlyFaceUp updates a little behind this.faceUp due to animation.
        var currentlyFaceUp = this.viewFlipped ? !this.faceUp : this.faceUp;
        var scaleX = this.renderScale().x;
        var scaleY = this.renderScale().y;
        if (this.flipAnimState > 0) {
            isMoving = true;
            if (this.flipAnimState > this.flipAnimMax / 2) {
                currentlyFaceUp = !currentlyFaceUp;
                scaleX *= (this.flipAnimState - this.flipAnimMax / 2) /
                    (this.flipAnimMax / 2);
            }
            else {
                scaleX *= (this.flipAnimMax / 2 - this.flipAnimState) /
                    (this.flipAnimMax / 2);
            }
            this.flipAnimState =
                (100 - animationSpeed) * this.flipAnimState / this.flipAnimMax;
            if (this.flipAnimState < 2) {
                this.flipAnimState = 0;
            }
        }
        var scaleOffsetX = (this.width - this.width * scaleX) / 2;
        var scaleOffsetY = (this.height - this.height * scaleY) / 2;
        if (this.stopOverridingPosTimer > 0) {
            isMoving = true;
            this.stopOverridingPosTimer--;
            if (this.stopOverridingPosTimer == 0) {
                this.clearDragPosition();
            }
        }
        // If set to -1, set immediately.
        if (this.cardScaleGoalX == -1 || this.cardScaleGoalY == -1) {
            this.setCardScale(this.cardScaleX, this.cardScaleY);
        }
        // Unlike the other animating bits, scale always goes to cardScaleGoal *
        // pileScale.
        var goalX = this.cardScaleGoalX * (this.cardPile ? this.cardPile.cardScaleX : 1);
        var goalY = this.cardScaleGoalY * (this.cardPile ? this.cardPile.cardScaleY : 1);
        if (this.cardScaleX != goalX || this.cardScaleY != goalY) {
            isMoving = true;
            this.cardScaleX =
                (animationSpeed * goalX + (100 - animationSpeed) * this.cardScaleX) /
                    100;
            this.cardScaleY =
                (animationSpeed * goalY + (100 - animationSpeed) * this.cardScaleX) /
                    100;
            if (Math.abs(this.cardScaleX - goalX) <= 0.01)
                this.cardScaleX = goalX;
            if (Math.abs(this.cardScaleY - goalY) <= 0.01)
                this.cardScaleY = goalY;
        }
        var rotation = this.currRotation;
        var goalRotation = this.goalRotation;
        if (rotation != goalRotation) {
            isMoving = true;
            // Some adjustments for the 0<-->360 degree border.
            if (rotation > 225 && goalRotation < 45)
                goalRotation += 360;
            else if (rotation < 135 && goalRotation > 315)
                goalRotation -= 360;
            rotation =
                (animationSpeed * goalRotation + (100 - animationSpeed) * rotation) /
                    100;
            if (Math.abs(rotation - goalRotation) <= 5) {
                this.currRotation = this.goalRotation;
            }
            else {
                this.currRotation = rotation;
            }
        }
        if (this.rotationCooldown > 0 && this.currRotation == this.goalRotation) {
            this.rotationCooldown--;
            isMoving = true;
        }
        if (updateOnly)
            return isMoving;
        var x = this.x;
        var y = this.y;
        var highlightedBy = this.highlightedBy;
        if (this.overrideHighlightedById != undefined) {
            highlightedBy = this.overrideHighlightedById == '' ?
                undefined :
                this.overrideHighlightedById;
        }
        if (highlightedBy && this.flipAnimState == 0) {
            var color = getGameInstance().players.getPlayerColor(highlightedBy);
            this.drawHighlight(ctx, 4, 8, color, color);
        }
        x += this.renderSize().width / this.cardScaleX / 2;
        y += this.renderSize().height / this.cardScaleY / 2;
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(rotation * Math.PI / 180);
        ctx.imageSmoothingEnabled = true;
        if (currentlyFaceUp) {
            ctx.drawImage(this.frontImage, -this.imageSize().width / 2 + scaleOffsetX, -this.imageSize().height / 2 + scaleOffsetY, this.imageSize().width * scaleX, this.imageSize().height * scaleY);
        }
        else {
            ctx.drawImage(this.backImage, -this.imageSize().width / 2 + scaleOffsetX, -this.imageSize().height / 2 + scaleOffsetY, this.imageSize().width * scaleX, this.imageSize().height * scaleY);
        }
        ctx.restore();
        if (this.collisionManager != null) {
            if (this.cardPile &&
                this.cardPile.pileStyle.uxMode & UXMode.GreedyCollision) {
                var isLastCard = (this.cardPile.findCard(this.id) == this.cardPile.numCards() - 1);
                // If this is the last card, make the collision wider.
                var extendLeft = -Math.min(0, this.cardPile.pileStyle.splayPctX *
                    this.displaySize().width / 100) /
                    2;
                var extendRight = Math.max(0, this.cardPile.pileStyle.splayPctX *
                    this.displaySize().width / 100) /
                    2;
                var extendUp = -Math.min(0, this.cardPile.pileStyle.splayPctY *
                    this.displaySize().height / 100) /
                    2;
                var extendDown = Math.max(0, this.cardPile.pileStyle.splayPctY *
                    this.displaySize().height / 100) /
                    2;
                this.collisionManager.addCard(this.upperLeft().x - extendLeft, this.bottomRight().x + extendRight, this.upperLeft().y - extendUp, this.bottomRight().y + extendDown, this);
            }
            else {
                this.collisionManager.addCard(this.upperLeft().x, this.bottomRight().x, this.upperLeft().y, this.bottomRight().y, this);
            }
        }
        return isMoving;
    };
    Card.prototype.drawHighlight = function (ctx, strokeWidth, cornerRadius, outerColor, innerColor) {
        if (outerColor === void 0) { outerColor = 'lightcyan'; }
        if (innerColor === void 0) { innerColor = 'deepskyblue'; }
        var x = this.center().x;
        var y = this.center().y;
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate(this.currRotation * Math.PI / 180);
        var width = this.renderSize().width + strokeWidth * 2;
        var height = this.renderSize().height + strokeWidth * 2;
        ctx.fillStyle = innerColor;
        ctx.strokeStyle = outerColor;
        ctx.lineWidth = strokeWidth / 2;
        roundedRect(ctx, -this.renderSize().width / 2 - strokeWidth, -this.renderSize().height / 2 - strokeWidth, width, height, cornerRadius);
        ctx.restore();
    };
    Card.prototype.saveAnimState = function () {
        return {
            x: this.x,
            goalX: this.goalX,
            y: this.y,
            goalY: this.goalY,
            flipAnimState: this.flipAnimState,
            overridePos: this.overridePos,
            overrideX: this.overrideX,
            overrideY: this.overrideY,
            stopOverridingPosTimer: this.stopOverridingPosTimer,
            currRotation: this.currRotation,
            goalRotation: this.goalRotation,
            rotationCooldown: this.rotationCooldown,
        };
    };
    Card.prototype.restoreAnimState = function (s) {
        this.x = s.x;
        this.goalX = s.goalX;
        this.y = s.y;
        this.goalY = s.goalY;
        this.flipAnimState = s.flipAnimState;
        this.overridePos = s.overridePos;
        this.overrideX = s.overrideX;
        this.overrideY = s.overrideY;
        this.stopOverridingPosTimer = s.stopOverridingPosTimer;
        this.currRotation = s.currRotation;
        this.goalRotation = s.goalRotation;
        this.rotationCooldown = s.rotationCooldown;
    };
    return Card;
}());
export default Card;
