import { __awaiter, __generator } from "tslib";
import 'firebase/remote-config';
import firebase from 'firebase/app';
import { getGameInstance } from './game-page';
var Type;
(function (Type) {
    Type[Type["String"] = 0] = "String";
    Type[Type["Number"] = 1] = "Number";
    Type[Type["Bool"] = 2] = "Bool";
    Type[Type["JSON"] = 3] = "JSON";
})(Type || (Type = {}));
;
var ConstantsManager = /** @class */ (function () {
    function ConstantsManager() {
    }
    ConstantsManager.init = function () {
        var rc = firebase.remoteConfig();
        rc.defaultConfig = ConstantsManager.getDefaults();
        rc.settings = {
            minimumFetchIntervalMillis: ConstantsManager.fetchIntervalMilliseconds,
            fetchTimeoutMillis: ConstantsManager.fetchTimeoutMilliseconds,
        };
        ConstantsManager.apply(); // apply defaults
        ConstantsManager.fetch();
    };
    ConstantsManager.getDefaults = function () {
        var def = {};
        Array.from(Object.keys(ConstantsManager.defaults))
            .forEach(function (key) {
            def[key] = ConstantsManager.defaults[key][1];
        });
        return def;
    };
    ConstantsManager.apply = function () {
        var rc = firebase.remoteConfig();
        var keys = Array.from(Object.keys(rc.getAll()));
        keys.forEach(function (key) {
            var type = ConstantsManager.defaults[key][0];
            var constName = key.toUpperCase();
            switch (type) {
                case Type.JSON:
                    ConstantsManager.constants[constName] = JSON.parse(rc.getString(key));
                    break;
                case Type.String:
                    ConstantsManager.constants[constName] = rc.getString(key);
                    break;
                case Type.Number:
                    ConstantsManager.constants[constName] = rc.getNumber(key);
                    break;
                case Type.Bool:
                    ConstantsManager.constants[constName] = rc.getBoolean(key);
                    break;
            }
        });
    };
    ConstantsManager.fetch = function () {
        return __awaiter(this, void 0, void 0, function () {
            var rc, game;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        rc = firebase.remoteConfig();
                        return [4 /*yield*/, rc.fetchAndActivate()];
                    case 1:
                        _a.sent();
                        game = getGameInstance();
                        if (game) {
                            game.markCanvasDirty();
                            if (game.buttons)
                                game.buttons.renderAll();
                            if (game.players)
                                game.players.renderAll();
                            if (game.spectators)
                                game.spectators.renderAll();
                        }
                        // Schedule the next fetch.
                        ConstantsManager.refetchTimerId = setTimeout(function () { return ConstantsManager.fetch(); }, ConstantsManager.refetchPeriodMilliseconds);
                        return [2 /*return*/];
                }
            });
        });
    };
    ConstantsManager.defaults = {
        'table_color': [Type.String, 'green'],
        'player_colors': [
            Type.JSON,
            '["#f44336","#4caf50","#2196F3","#795548","#3F51B5","#FF9800","#9C27B0","#009688"]'
        ],
        // Overall image smoothing during gameplay.
        'image_smoothing_enabled': [Type.Bool, true],
        'max_players': [Type.Number, 16],
        // Allow storing card sheets in dataUrl format if Firebase Storage fails?
        'allow_storage_dataurl_workaround': [Type.Bool, false],
        // Mouse interaction settings.
        'pickup_stack_hold_time_min_ms': [Type.Number, 300],
        'pickup_draw_stack_hold_time_min_ms': [Type.Number, 1000],
        'double_click_first_click_max_ms': [Type.Number, 350],
        'double_click_delay_max_ms': [Type.Number, 350],
        'double_click_second_click_max_ms': [Type.Number, 500],
        'highlight_click_min_ms': [Type.Number, 40],
        'highlight_click_max_ms': [Type.Number, 2000],
        'double_click_highlight_delay_max_ms': [Type.Number, 400],
        // Min and max card scale (for overall game scale).
        'card_scale_min': [Type.Number, 0.25],
        'card_scale_max': [Type.Number, 2.0],
        // Don't allow cards to be rotated more often than this.
        'rotation_cooldown_frames': [Type.Number, 20],
        'expand_pile_time_frames': [Type.Number, 600],
        'fallback_default_splay_pct_x': [Type.Number, 20],
        'fallback_default_splay_pct_y': [Type.Number, 0],
        'drag_move_threshold': [Type.Number, 10],
        'edit_drag_move_threshold': [Type.Number, 10],
        // Click limit for toggling type, past this we drag the pile.
        'edit_toggle_pile_type_max_ms': [Type.Number, 300],
        // Animation options.
        'shuffle_anim_frames': [Type.Number, 120],
        'animation_speed_pct': [Type.Number, 35],
        // When a card that is remote-animated is dropped, it will snap back to its
        // canonical position after this many frames.  If a server update happens
        // first, the card will be snapped to its position immediately.
        'animation_snap_back_delay_frames': [Type.Number, 90],
        // Sheet editor.
        'sheet_editor_card_width': [Type.Number, 130],
        'sheet_editor_cutout_radius': [Type.Number, 10],
        'sheet_editor_border_color': [Type.String, '000000A0'],
        'sheet_editor_column_count': [Type.Number, 10],
        // Delays and such for animating game restart.
        'restart_game_deal_start_ms': [Type.Number, 100],
        'restart_game_deal_shuffle_ms': [Type.Number, 200],
        'restart_game_deal_shuffle_wait_ms': [Type.Number, 1000],
        'restart_game_deal_deal_ms': [Type.Number, 100],
        // Should we cache pile styles locally in session data,
        // or load them each time?
        'cache_pile_styles': [Type.Bool, true],
    };
    // Cache data if we fetch more often than every 20 minutes
    ConstantsManager.fetchIntervalMilliseconds = 20 * 60 * 1000;
    // Actually fetch every 25 minutes.
    ConstantsManager.refetchPeriodMilliseconds = 25 * 60 * 1000;
    ConstantsManager.fetchTimeoutMilliseconds = 60 * 1000;
    ConstantsManager.constants = {};
    return ConstantsManager;
}());
export { ConstantsManager };
var constants = ConstantsManager.constants;
export default constants;
