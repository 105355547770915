import Card from './Card';
var CardSheet = /** @class */ (function () {
    function CardSheet(img, colCount, rowCount, cardCount, scale) {
        this.img = img;
        this.rowCount = rowCount;
        this.colCount = colCount;
        this.scale = scale;
        this.cardHeight = img.height / rowCount * this.scale;
        this.cardWidth = img.width / colCount * this.scale;
        this.cardCount = cardCount;
    }
    CardSheet.prototype.createCard = function (id, frontIdx, backIdx) {
        var frontCanvas = document.createElement('canvas');
        frontCanvas.width = this.cardWidth;
        frontCanvas.height = this.cardHeight;
        this.draw(frontCanvas.getContext('2d'), frontIdx, 0, 0);
        var backCanvas = document.createElement('canvas');
        backCanvas.width = this.cardWidth;
        backCanvas.height = this.cardHeight;
        this.draw(backCanvas.getContext('2d'), backIdx, 0, 0);
        return new Card(id, frontCanvas, backCanvas);
    };
    CardSheet.prototype.draw = function (ctx, cardIndex, x, y) {
        var leftOffset = (cardIndex % this.colCount) * (this.cardWidth / this.scale);
        var topOffset = Math.floor(cardIndex / this.colCount) * (this.cardHeight / this.scale);
        ctx.save();
        ctx.imageSmoothingEnabled = true;
        ctx.drawImage(this.img, 
        // X/Y Offset within this.img to draw from
        leftOffset, topOffset, 
        // Height and width within this.img
        this.cardWidth / this.scale, this.cardHeight / this.scale, 
        // Canvas destination coordinates
        x, y, 
        // Size to draw onto the canvas
        this.cardWidth, this.cardHeight);
        ctx.restore();
    };
    return CardSheet;
}());
export default CardSheet;
