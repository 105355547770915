import { __assign, __awaiter, __generator } from "tslib";
import 'firebase/firestore';
import { gameConverter } from './converters';
import { createElement } from './dom-helpers';
import { firebase } from './firebase';
import { logReadOperation, logWriteOperation } from './utils';
/**
 * Get a json snapshot for easy diffing of basic game properties.
 * @param gamesList
 */
function getSnapshot(gamesList) {
    return JSON.stringify(gamesList.map(function (game) { return ({
        description: game.description,
        id: game.id,
        isTemplate: game.isTemplate
    }); }));
}
function gameSorter(a, b) {
    return a.description < b.description ? -1 :
        a.description > b.description ? 1 : 0;
}
var GamesList = /** @class */ (function () {
    function GamesList(user, showArchived) {
        var _this = this;
        this.user = user;
        this.showArchived = showArchived;
        this.gamesUnsubscribe = null;
        this.mode = 'list';
        this.gamesList = [];
        this.gamePlayers = new Map();
        this.gamePlayersRef = firebase.database().ref("players");
        this.gamesUnsubscribe = this.subscribeToGamesList();
        this.subscribeToGamePlayers();
        window.addEventListener('beforeunload', function () {
            _this.unsubscribeFromGamesList();
            _this.unsubscribeFromGamePlayers();
        });
    }
    GamesList.prototype.unsubscribeFromGamesList = function () {
        if (this.gamesUnsubscribe) {
            this.gamesUnsubscribe();
            this.gamesUnsubscribe = null;
        }
    };
    GamesList.prototype.subscribeToGamesList = function () {
        var _this = this;
        this.errorMessage = undefined;
        return firebase.firestore()
            .collection('games')
            .withConverter(gameConverter)
            .onSnapshot(function (collectionSnap) {
            logReadOperation('updateGameList');
            var newGamesList = [];
            collectionSnap.forEach(function (docSnap) {
                newGamesList.push(docSnap.data());
            });
            // TODO: This prevents this list from re-rendering every time a
            // card moves.
            var oldListSnapshot = getSnapshot(_this.gamesList);
            var newListSnapshot = getSnapshot(newGamesList);
            if (oldListSnapshot !== newListSnapshot) {
                _this.gamesList = newGamesList;
                _this.render();
            }
        }, function (error) {
            _this.errorMessage = "Cannot read game list: " + error.message;
            _this.render();
        });
    };
    GamesList.prototype.unsubscribeFromGamePlayers = function () {
        this.gamePlayersRef.off();
    };
    GamesList.prototype.subscribeToGamePlayers = function () {
        var _this = this;
        // One-time initial fetch of entire list.
        this.gamePlayersRef.once('value', function (snap) {
            snap.forEach(function (item) {
                // key is gameID
                if (!item.ref.key)
                    return;
                var gameID = item.ref.key;
                if (!_this.gamePlayers.has(gameID)) {
                    _this.gamePlayers.set(gameID, new Map());
                }
                _this.gamePlayers.get(gameID).clear();
                item.forEach(function (user) {
                    _this.gamePlayers.get(gameID).set(user.ref.key, user.val());
                });
                // value is players object
            });
            _this.render();
        });
        // Triggers when a new game is added to the list.
        this.gamePlayersRef.on('child_added', function (item) {
            if (!item.ref.key)
                return;
            var gameID = item.ref.key;
            if (!_this.gamePlayers.has(gameID)) {
                _this.gamePlayers.set(gameID, new Map());
            }
            _this.gamePlayers.get(gameID).clear();
            item.forEach(function (user) {
                _this.gamePlayers.get(gameID).set(user.ref.key, user.val());
            });
            _this.render();
        });
        // Triggers when a game is removed from the list.
        this.gamePlayersRef.on('child_removed', function (item) {
            if (!item.ref.key)
                return;
            var gameID = item.ref.key;
            if (_this.gamePlayers.has(gameID)) {
                _this.gamePlayers.delete(gameID);
            }
            _this.render();
        });
        // Triggers when the players in a game change.
        this.gamePlayersRef.on('child_changed', function (item) {
            _this.render();
            if (!item.ref.key)
                return;
            var gameID = item.ref.key;
            if (!_this.gamePlayers.has(gameID)) {
                _this.gamePlayers.set(gameID, new Map());
            }
            _this.gamePlayers.get(gameID).clear();
            item.forEach(function (user) {
                _this.gamePlayers.get(gameID).set(user.ref.key, user.val());
            });
            _this.render();
        });
    };
    GamesList.prototype.isUserPlayer = function (gameID, uid) {
        return this.gamePlayers.has(gameID) &&
            this.gamePlayers.get(gameID).has(uid);
    };
    GamesList.prototype.getNumPlayers = function (gameID) {
        return this.gamePlayers.has(gameID) ? this.gamePlayers.get(gameID).size :
            0;
    };
    GamesList.prototype.render = function () {
        var topEl = document.getElementById('htmlcontent');
        if (!topEl) {
            console.error('htmlcontent element missing');
            return;
        }
        topEl.innerHTML = '';
        if (this.errorMessage) {
            var errorEl = createElement({ text: this.errorMessage, classes: ['game-list-error-message'] });
            topEl.appendChild(errorEl);
        }
        else {
            if (this.mode === 'list') {
                this.renderList(topEl);
            }
            else if (this.mode === 'create') {
                this.renderCreateScreen(topEl);
            }
        }
    };
    GamesList.prototype.renderCreateScreen = function (topEl) {
        var _this = this;
        var formEl = createElement({ tag: 'form', classes: ['new-game-form'] });
        var titleEl = createElement({ tag: 'h2', text: 'Create a New Game', classes: ['new-game-form'] });
        var nameInputEl = createElement({
            tag: 'input',
            placeholder: 'Game name (Example: "A Test Game")',
            value: this.nameInput || ''
        });
        nameInputEl.addEventListener('input', function (e) { return _this.nameInput = e.target.value; });
        var templateTitleEl = createElement({ tag: 'h3', text: 'Choose a template' });
        var listEl = createElement({ classes: 'template-list-container' });
        listEl.appendChild(titleEl);
        var allTemplates = this.gamesList.filter(function (game) { return !game.isArchived && game.isTemplate; });
        allTemplates.sort(gameSorter);
        var _loop_1 = function (game) {
            var listItemEl = createElement({
                classes: 'template-list-item'
            });
            if (game.id === this_1.selectedTemplateId) {
                listItemEl.classList.add('selected');
            }
            listItemEl.appendChild(createElement({ text: "" + game.description, classes: ['desc'] }));
            listItemEl.appendChild(createElement({ text: "id: " + game.id, classes: ['id'] }));
            listItemEl.addEventListener('click', function () {
                _this.selectedTemplateId =
                    _this.selectedTemplateId === game.id ? undefined : game.id;
                _this.render();
            });
            listEl.appendChild(listItemEl);
        };
        var this_1 = this;
        for (var _i = 0, allTemplates_1 = allTemplates; _i < allTemplates_1.length; _i++) {
            var game = allTemplates_1[_i];
            _loop_1(game);
        }
        var buttonContainerEl = createElement({ classes: 'new-game-button-container' });
        var submitButtonEl = createElement({
            tag: 'button',
            classes: 'create-submit-button',
            text: 'Create This Game'
        });
        var cancelButtonEl = createElement({
            tag: 'button',
            classes: 'cancel-button',
            text: 'Cancel',
            type: 'button'
        });
        cancelButtonEl.addEventListener('click', function () {
            _this.mode = 'list';
            _this.render();
        });
        formEl.appendChild(titleEl);
        formEl.appendChild(nameInputEl);
        formEl.appendChild(templateTitleEl);
        formEl.appendChild(listEl);
        if (this.newGameErrorMessage) {
            var errorEl = createElement({ text: this.newGameErrorMessage, classes: 'new-game-error-message' });
            formEl.appendChild(errorEl);
        }
        buttonContainerEl.appendChild(submitButtonEl);
        buttonContainerEl.appendChild(cancelButtonEl);
        formEl.appendChild(buttonContainerEl);
        formEl.addEventListener('submit', function (e) { return __awaiter(_this, void 0, void 0, function () {
            var gameData, ref, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        if (!this.nameInput) {
                            this.newGameErrorMessage = 'Please enter a name for this game.';
                            this.render();
                            return [2 /*return*/];
                        }
                        else if (!this.selectedTemplateId) {
                            this.newGameErrorMessage = 'Please select a template for the new game.';
                            this.render();
                            return [2 /*return*/];
                        }
                        else {
                            this.newGameErrorMessage = undefined;
                            this.render();
                        }
                        gameData = this.gamesList.find(function (game) { return game.id === _this.selectedTemplateId; });
                        if (!gameData) {
                            this.newGameErrorMessage = 'Error finding game data for this ID.';
                            this.render();
                            return [2 /*return*/];
                        }
                        ref = firebase.firestore()
                            .collection('games')
                            .withConverter(gameConverter)
                            .doc();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        // Copy game, set `isTemplate` to false and add user as owner.
                        logWriteOperation('createGameFromTemplate');
                        return [4 /*yield*/, ref.set(__assign(__assign({}, gameData), { isTemplate: false, sourceTemplateId: this.selectedTemplateId, owner: this.user.uid, description: this.nameInput, isEditLocked: false, isJoinLocked: false }))];
                    case 2:
                        _a.sent();
                        window.location.href = "/?game=" + ref.id;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.newGameErrorMessage = "Error writing new game to Firestore: " + e_1;
                        this.render();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
        topEl.appendChild(formEl);
    };
    GamesList.prototype.renderList = function (topEl) {
        var _this = this;
        var createFromTemplateButtonEl = createElement({
            tag: 'button',
            classes: 'new-game-button',
            text: 'Create Game from Template'
        });
        createFromTemplateButtonEl.onclick = function () {
            _this.mode = 'create';
            _this.render();
        };
        var createFromScratchButtonEl = createElement({
            tag: 'button',
            classes: 'new-game-button',
            text: 'Create Template from Scratch'
        });
        createFromScratchButtonEl.onclick = function () {
            window.location.href = '/?sheet=true';
        };
        var listEl = createElement({ classes: 'games-list-container' });
        var titleEl = createElement({ tag: 'h2', text: 'List of Games' });
        listEl.appendChild(titleEl);
        if (this.showArchived) {
            var archiveLink = createElement({
                tag: 'a',
                text: 'Return to main games list',
                classes: 'see-archive-link',
                href: '/'
            });
            listEl.appendChild(archiveLink);
            var ownedArchivedGames = this.gamesList.filter(function (game) { return game.isArchived && game.owner === _this.user.uid; });
            ownedArchivedGames.sort(gameSorter);
            for (var _i = 0, ownedArchivedGames_1 = ownedArchivedGames; _i < ownedArchivedGames_1.length; _i++) {
                var game = ownedArchivedGames_1[_i];
                var listItemEl = this.renderListItem(game);
                listEl.appendChild(listItemEl);
            }
        }
        else {
            var archiveLink = createElement({
                tag: 'a',
                text: 'See your archived games',
                classes: 'see-archive-link',
                href: '/?archive=true'
            });
            listEl.appendChild(archiveLink);
            var templates = this.gamesList.filter(function (game) { return !game.isArchived && game.isTemplate &&
                game.owner === _this.user.uid; });
            templates.sort(gameSorter);
            var nonTemplates = this.gamesList.filter(function (game) { return !game.isArchived && !game.isTemplate &&
                (!game.isPrivate || game.owner === _this.user.uid ||
                    _this.isUserPlayer(game.id, _this.user.uid)); });
            nonTemplates.sort(gameSorter);
            for (var _a = 0, nonTemplates_1 = nonTemplates; _a < nonTemplates_1.length; _a++) {
                var game = nonTemplates_1[_a];
                var listItemEl = this.renderListItem(game);
                listEl.appendChild(listItemEl);
            }
            for (var _b = 0, templates_1 = templates; _b < templates_1.length; _b++) {
                var game = templates_1[_b];
                var listItemEl = this.renderListItem(game);
                listEl.appendChild(listItemEl);
            }
        }
        listEl.appendChild(createFromTemplateButtonEl);
        listEl.appendChild(createFromScratchButtonEl);
        topEl.appendChild(listEl);
    };
    GamesList.prototype.renderListItem = function (game) {
        var listItemEl = createElement({ tag: 'a', href: "/?game=" + game.id, classes: 'games-list-item' });
        var descEl = createElement({ text: game.description, classes: 'desc' });
        var idEl = createElement({ text: "id: " + game.id, classes: 'id' });
        var numPlayers = this.getNumPlayers(game.id);
        var numPlayersText = numPlayers + " player";
        if (numPlayers != 1)
            numPlayersText += 's';
        listItemEl.appendChild(descEl);
        if (!game.isTemplate) {
            var playersEl = createElement({ text: numPlayersText, classes: 'players' });
            listItemEl.appendChild(playersEl);
            if (this.isUserPlayer(game.id, this.user.uid)) {
                listItemEl.appendChild(createElement({ text: 'you\'re playing', classes: 'participant' }));
            }
            if (game.owner === this.user.uid) {
                listItemEl.appendChild(createElement({ text: 'owner', classes: 'owner' }));
            }
        }
        if (game.isPrivate) {
            listItemEl.appendChild(createElement({ text: 'private', classes: 'private' }));
        }
        if (game.isTemplate) {
            listItemEl.appendChild(createElement({ text: 'template', classes: 'template' }));
        }
        listItemEl.appendChild(idEl);
        return listItemEl;
    };
    return GamesList;
}());
export { GamesList };
