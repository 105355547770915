import { firebase } from './firebase';
import { PileFacing, PlaceholderStyle, UXMode } from './types';
import { keyValueArrayToObject } from './utils';
export var gameConverter = {
    toFirestore: function (gameData) {
        return {
            description: gameData.description,
            sheetId: gameData.sheetId,
            piles: keyValueArrayToObject(gameData.piles.map(function (pile) { return [pile.id, pileDataConverter.toFirestore(pile)]; })),
            cardScale: gameData.cardScale ? gameData.cardScale : 1,
            isTemplate: !!gameData.isTemplate,
            owner: gameData.owner,
            sourceTemplateId: gameData.sourceTemplateId,
            isPrivate: !!gameData.isPrivate,
            isArchived: !!gameData.isArchived,
            isEditLocked: !!gameData.isEditLocked,
            isJoinLocked: !!gameData.isJoinLocked,
            isDragVertical: !!gameData.isDragVertical,
            isDropVertical: !!gameData.isDropVertical,
            helpUrl: gameData.helpUrl ? gameData.helpUrl : null,
            helpRef: gameData.helpRef ? gameData.helpRef : null,
        };
    },
    fromFirestore: function (snapshot, options) {
        var data = snapshot.data(options);
        return {
            id: snapshot.id,
            sheetId: data.sheetId,
            description: data.description,
            piles: !data.piles ?
                new Array() :
                (Array.isArray(data.piles) ?
                    data.piles.map(pileDataConverter.fromFirestoreData) :
                    Object.entries(data.piles)
                        .map(function (kv) {
                        return pileDataConverter.fromFirestoreData(kv[1]);
                    })),
            cardScale: data.cardScale ? data.cardScale : 1,
            isTemplate: !!data.isTemplate,
            owner: data.owner,
            sourceTemplateId: data.sourceTemplateId,
            isPrivate: !!data.isPrivate,
            isArchived: !!data.isArchived,
            isEditLocked: !!data.isEditLocked,
            isJoinLocked: !!data.isJoinLocked,
            isDragVertical: !!data.isDragVertical,
            isDropVertical: !!data.isDropVertical,
            helpUrl: data.helpUrl ? data.helpUrl : '',
            helpRef: data.helpRef ? data.helpRef : '',
        };
    }
};
export var sheetConverter = {
    toFirestore: function (sheetAsset) {
        return {
            cards: sheetAsset.cards,
            url: sheetAsset.url,
            rows: sheetAsset.rows,
            cols: sheetAsset.cols,
            count: sheetAsset.count,
            backIndex: sheetAsset.backIndex,
            scale: sheetAsset.scale
        };
    },
    fromFirestore: function (snapshot, options) {
        var data = snapshot.data(options);
        return {
            id: snapshot.id,
            cards: data.cards,
            url: data.url,
            rows: data.rows,
            cols: data.cols,
            count: data.count,
            backIndex: data.backIndex,
            scale: data.scale || 1
        };
    }
};
export var pileDataConverter = {
    toFirestore: function (pileData) {
        return {
            id: pileData.id,
            x: pileData.x,
            y: pileData.y,
            cards: pileData.cards,
            style: pileData.style ? pileData.style : null,
            label: pileData.label ? pileData.label : null,
            helpText: pileData.helpText ? pileData.helpText : null,
            visibleToPlayers: pileData.visibleToPlayers ? pileData.visibleToPlayers :
                null,
            overrideStyle: pileData.overrideStyle ?
                pileStyleConverter.toFirestore(pileData.overrideStyle) :
                null,
            cardScaleX: pileData.cardScaleX ? pileData.cardScaleX : null,
            cardScaleY: pileData.cardScaleY ? pileData.cardScaleY : null,
            labelSize: pileData.labelSize ? pileData.labelSize : null,
            rotation: (pileData.rotation != undefined && pileData.rotation >= 0) ?
                pileData.rotation :
                null,
            dealSettings: pileData.dealSettings ? pileData.dealSettings : null,
            editedTime: pileData.editedTime ?
                firebase.firestore.Timestamp.fromMillis(pileData.editedTime) :
                firebase.firestore.FieldValue.serverTimestamp(),
        };
    },
    fromFirestore: function (snapshot, options) {
        var data = snapshot.data(options);
        return this.fromFirestoreData(data);
    },
    fromFirestoreData: function (data) {
        return {
            id: data.id,
            x: data.x,
            y: data.y,
            cards: data.cards,
            style: data.style ? data.style : 'default',
            label: data.label ? data.label : '',
            helpText: data.helpText ? data.helpText : '',
            visibleToPlayers: data.visibleToPlayers ? data.visibleToPlayers :
                undefined,
            overrideStyle: data.overrideStyle ?
                pileStyleConverter.fromFirestoreData('', data.overrideStyle) :
                undefined,
            cardScaleX: data.cardScaleX ? data.cardScaleX : 1,
            cardScaleY: data.cardScaleY ? data.cardScaleY : 1,
            labelSize: data.labelSize ? data.labelSize : 0,
            rotation: data.rotation != null ? data.rotation : -1,
            dealSettings: data.dealSettings ?
                data.dealSettings :
                data.dealCardsOnReset ? [data.dealCardsOnReset] :
                    new Array(),
            editedTime: data.editedTime ?
                (data.editedTime.seconds ? data.editedTime.toMillis() :
                    data.editedTime) :
                0,
        };
    }
};
export var pileStyleConverter = {
    toFirestore: function (pileStyle) {
        var uxModes = [];
        if (pileStyle.uxMode) {
            if (pileStyle.uxMode & UXMode.Draw)
                uxModes.push('draw');
            if (pileStyle.uxMode & UXMode.Expand)
                uxModes.push('expand');
            if (pileStyle.uxMode & UXMode.ZoomCard)
                uxModes.push('zoom');
            if (pileStyle.uxMode & UXMode.PeekCard)
                uxModes.push('peek');
            if (pileStyle.uxMode & UXMode.ShuffleOnReset)
                uxModes.push('shuffle');
            if (pileStyle.uxMode & UXMode.GreedyCollision)
                uxModes.push('greedy');
            if (pileStyle.uxMode & UXMode.FlipTopCard)
                uxModes.push('flipTop');
            if (pileStyle.uxMode & UXMode.SortCards)
                uxModes.push('sort');
            if (pileStyle.uxMode & UXMode.OneCard)
                uxModes.push('oneCard');
            if (pileStyle.uxMode & UXMode.AutoTableau)
                uxModes.push('autoTableau');
            if (pileStyle.uxMode & UXMode.SecretlyViewCard)
                uxModes.push('secretView');
            if (pileStyle.uxMode & UXMode.Hidden)
                uxModes.push('hidden');
            if (pileStyle.uxMode & UXMode.Locked)
                uxModes.push('locked');
        }
        return {
            id: pileStyle.id,
            name: pileStyle.name,
            description: pileStyle.description,
            splayPctX: pileStyle.splayPctX,
            splayPctY: pileStyle.splayPctY,
            splayMult: pileStyle.splayMult,
            splayMax: pileStyle.splayMax,
            layer: pileStyle.layer ? pileStyle.layer : null,
            facing: (pileStyle.facing == PileFacing.ForceFaceUp ?
                'up' :
                pileStyle.facing == PileFacing.ForceFaceDown ? 'down' : null),
            uxMode: uxModes.length > 0 ? uxModes.join(',') : '',
            hideFromList: pileStyle.hideFromList == true ? pileStyle.hideFromList :
                null,
            placeholder: (pileStyle.placeholder == PlaceholderStyle.None ?
                null :
                pileStyle.placeholder == PlaceholderStyle.Single ?
                    'single' :
                    pileStyle.placeholder == PlaceholderStyle.Wide ?
                        'wide' :
                        pileStyle.placeholder == PlaceholderStyle.Tall ? 'tall' : null)
        };
    },
    fromFirestore: function (snapshot, options) {
        var data = snapshot.data(options);
        return this.fromFirestoreData(snapshot.id, data);
    },
    fromFirestoreData: function (id, data) {
        var uxModeValue = UXMode.Default;
        if (data.uxMode) {
            var uxModes = data.uxMode.split(',');
            uxModeValue |= ((uxModes.indexOf('draw') != -1) ? UXMode.Draw : 0);
            uxModeValue |= ((uxModes.indexOf('expand') != -1) ? UXMode.Expand : 0);
            uxModeValue |= ((uxModes.indexOf('zoom') != -1) ? UXMode.ZoomCard : 0);
            uxModeValue |= ((uxModes.indexOf('peek') != -1) ? UXMode.PeekCard : 0);
            uxModeValue |=
                ((uxModes.indexOf('greedy') != -1) ? UXMode.GreedyCollision : 0);
            uxModeValue |=
                ((uxModes.indexOf('shuffle') != -1) ? UXMode.ShuffleOnReset : 0);
            uxModeValue |=
                ((uxModes.indexOf('flipTop') != -1) ? UXMode.FlipTopCard : 0);
            uxModeValue |= ((uxModes.indexOf('sort') != -1) ? UXMode.SortCards : 0);
            uxModeValue |= ((uxModes.indexOf('oneCard') != -1) ? UXMode.OneCard : 0);
            uxModeValue |=
                ((uxModes.indexOf('autoTableau') != -1) ? UXMode.AutoTableau : 0);
            uxModeValue |=
                ((uxModes.indexOf('secretView') != -1) ? UXMode.SecretlyViewCard : 0);
            uxModeValue |= ((uxModes.indexOf('hidden') != -1) ? UXMode.Hidden : 0);
            uxModeValue |= ((uxModes.indexOf('locked') != -1) ? UXMode.Locked : 0);
        }
        return {
            id: data.id ? data.id : id,
            name: data.name,
            description: data.description,
            splayPctX: data.splayPctX ? data.splayPctX : 0,
            splayPctY: data.splayPctY ? data.splayPctY : 0,
            splayMax: data.splayMax ? data.splayMax : 0,
            splayMult: data.splayMult ? data.splayMult : 1,
            layer: data.layer ? data.layer : 0,
            facing: (data.facing == 'up' ?
                PileFacing.ForceFaceUp :
                data.facing == 'down' ? PileFacing.ForceFaceDown :
                    PileFacing.Any),
            uxMode: uxModeValue,
            hideFromList: !!data.hideFromList,
            placeholder: (data.placeholder == 'single' ?
                PlaceholderStyle.Single :
                data.placeholder == 'wide' ?
                    PlaceholderStyle.Wide :
                    data.placeholder == 'tall' ? PlaceholderStyle.Tall :
                        PlaceholderStyle.None)
        };
    }
};
