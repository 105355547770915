import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import 'firebase/firestore';
import { getNumPlayersForReset, resetGame } from './actions';
import constants from './constants';
import { createElement } from './dom-helpers';
import { firebase } from './firebase';
import { updateBreadcrumb } from './game-page';
import { EditClickState, PileFacing, PlaceholderStyle, UXMode } from './types';
import { logWriteOperation } from './utils';
var path = require('path');
var optionOnlyAvailableForTemplatesText = 'This option is only available for templates. Enable the ✅ Template option to use this.';
var GameButton = /** @class */ (function () {
    function GameButton(text, action, mode, // default or edit or spectator
    side, className, helpText) {
        if (mode === void 0) { mode = 'default'; }
        if (side === void 0) { side = 'left'; }
        if (className === void 0) { className = ''; }
        this.text = text;
        this.action = action;
        this.mode = mode;
        this.side = side;
        this.className = className;
        this.helpText = helpText;
    }
    return GameButton;
}());
var GameButtons = /** @class */ (function () {
    function GameButtons(game, canvas, playerList) {
        var _a;
        this.game = game;
        this.buttons = [];
        this.message = null;
        this.cancelText = 'cancel';
        this.isEditMode = false;
        this.userIsPlayer = false;
        this.showOverrideStyleOptions = false;
        this.editModeMessage = 'Edit mode: drag piles, click pile to toggle type.';
        this.showDealSettingsNum = 0;
        this.container = document.createElement('div');
        this.container.classList.add('game-buttons-container');
        this.container.setAttribute('style', "width: " + canvas.width + "px");
        (_a = canvas.parentElement) === null || _a === void 0 ? void 0 : _a.insertBefore(this.container, canvas);
        if (playerList.indexOf(this.game.user.uid) != -1) {
            this.userIsPlayer = true;
        }
        this.isInitialized = this.initializeButtons();
    }
    GameButtons.prototype.initializeButtons = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.game.isInitialized];
                    case 1:
                        _a.sent();
                        this.addButton(new GameButton('restart game', function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(this.game.lastGameData && !this.game.lastGameData.sourceTemplateId)) return [3 /*break*/, 3];
                                        if (!confirm('Are you sure you want to reset this game completely?')) return [3 /*break*/, 2];
                                        if (!confirm('Are you REALLY sure you want to reset this game? This game has NO TEMPLATE, so you\'ll lose ALL your piles and settings.')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, resetGame(this.game.gameId)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [3 /*break*/, 5];
                                    case 3:
                                        if (!confirm("Are you sure you want to restart this game for " + getNumPlayersForReset(this.game.lastGameData.piles) + " players?")) return [3 /*break*/, 5];
                                        return [4 /*yield*/, resetGame(this.game.gameId)];
                                    case 4:
                                        _a.sent();
                                        _a.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); }, 'default', 'left', '', 'Reset this game to its original state.'));
                        this.addButton(new GameButton('shuffle pile', this.onShuffleClick.bind(this), 'default', 'left', '', 'Shuffle a pile of cards.'));
                        this.addButton(this.editModeButton = new GameButton('edit game', this.onEditModeClick.bind(this), 'default', 'right', 'edit-button', 'Move and change piles, modify options, add labels, etc.'));
                        this.addButton(new GameButton('leave game', this.quitGame.bind(this), 'default', 'right', 'quit-button', 'Stop playing in this game and become a spectator.'));
                        this.addButton(new GameButton('add pile', this.onNewPileClick.bind(this), 'edit'));
                        this.addButton(new GameButton('delete pile', this.onDeletePileClick.bind(this), 'edit'));
                        this.addButton(new GameButton('edit pile', this.onEditPileClick.bind(this), 'edit'));
                        /*
                        this.addButton(
                            new GameButton('label pile', this.onLabelPileClick.bind(this), 'edit'));
                        this.addButton(new GameButton(
                            'edit pile hover text', this.onHelpTextPileClick.bind(this), 'edit'));
                        */
                        // this.addButton(new GameButton(
                        //'cancel edits', this.onAbortEdits.bind(this), 'edit', 'right'));
                        this.addButton(new GameButton('back to game', this.onEditModeClick.bind(this), 'edit', 'right', 'edit-button'));
                        this.addButton(new GameButton('join game', this.joinGame.bind(this), 'spectator', 'left'));
                        return [2 /*return*/];
                }
            });
        });
    };
    GameButtons.prototype.addButton = function (button) {
        this.buttons.push(button);
        this.renderAll();
    };
    GameButtons.prototype.setUserIsPlayer = function (b) {
        // Only call this if you know the database is up-to-date.
        if (this.userIsPlayer != b) {
            this.userIsPlayer = b;
            this.renderAll();
        }
    };
    GameButtons.prototype.joinGame = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var isJoinLocked, add, remove;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.game.isInitialized];
                    case 1:
                        _b.sent();
                        if (this.game.players.size >= constants.MAX_PLAYERS) {
                            this.cancelText = 'Try again later!';
                            this.setMessage('Sorry, too many players.');
                            return [2 /*return*/];
                        }
                        isJoinLocked = !!((_a = this.game.lastGameData) === null || _a === void 0 ? void 0 : _a.isJoinLocked);
                        if (isJoinLocked && this.game.user.uid != this.game.lastGameData.owner) {
                            this.cancelText = 'Can\'t join.';
                            this.setMessage('Sorry, this game is locked to new players.');
                            return [2 /*return*/];
                        }
                        this.userIsPlayer = true;
                        add = this.game.players.addPlayer(this.game.user.uid);
                        remove = this.game.spectators.removeSpectator(this.game.user.uid);
                        return [4 /*yield*/, Promise.all([add, remove])];
                    case 2:
                        _b.sent();
                        this.renderAll();
                        return [2 /*return*/];
                }
            });
        });
    };
    GameButtons.prototype.quitGame = function () {
        return __awaiter(this, void 0, void 0, function () {
            var add, remove;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.userIsPlayer = false;
                        add = this.game.spectators.addSpectator(this.game.user.uid);
                        remove = this.game.players.removePlayer(this.game.user.uid);
                        return [4 /*yield*/, Promise.all([add, remove])];
                    case 1:
                        _a.sent();
                        this.renderAll();
                        return [2 /*return*/];
                }
            });
        });
    };
    GameButtons.prototype.setMessage = function (message, cancelButtonText) {
        this.message = message;
        this.renderAll();
        this.cancelText = cancelButtonText ? cancelButtonText : 'cancel';
    };
    GameButtons.prototype.onShuffleClick = function () {
        // set a flag in the game that the next click means shuffle
        // have the game call setMessage(null) here when it is done
        this.game.triggerShuffle(true);
    };
    GameButtons.prototype.onEditModeClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(!this.game.lastGameData.isEditLocked ||
                            this.game.user.uid == this.game.lastGameData.owner ||
                            this.isEditMode)) return [3 /*break*/, 2];
                        this.game.helpLabel = undefined;
                        _a = this;
                        return [4 /*yield*/, this.game.toggleEditMode()];
                    case 1:
                        _a.isEditMode = _b.sent();
                        _b.label = 2;
                    case 2:
                        this.renderAll();
                        return [2 /*return*/];
                }
            });
        });
    };
    GameButtons.prototype.onAbortEdits = function () {
        window.location.reload();
    };
    GameButtons.prototype.onNewPileClick = function () {
        this.game.setEditClickState(EditClickState.AddingPile);
    };
    GameButtons.prototype.onDeletePileClick = function () {
        this.game.setEditClickState(EditClickState.DeletingPile);
    };
    GameButtons.prototype.onEditPileClick = function () {
        this.game.setEditClickState(EditClickState.ChoosingPileToEdit);
        this.showOverrideStyleOptions = false;
        this.showDealSettingsNum = 0;
    };
    GameButtons.prototype.onUploadRulebook = function () { };
    // setting label:
    // this.game.setPileLabel(this.game.editClickStatePileId, label);
    // setting helptext:
    // this.game.setPileHelpText(this.game.editClickStatePileId, helpText);
    GameButtons.prototype.onRemoveUserClick = function () {
        this.game.editMakeSelectedUserSpectator();
    };
    GameButtons.prototype.onCancelButtonClick = function () {
        if (!this.userIsPlayer) {
            this.setMessage(null);
        }
        else if (!this.isEditMode) {
            this.game.triggerShuffle(false);
        }
        else {
            if (this.game.editClickState == EditClickState.EditingPile &&
                this.game.editClickStatePileId &&
                this.game.editChoosingPileToDrawFrom) {
                this.game.setEditClickState(EditClickState.EditingPile, this.game.editClickStatePileId);
            }
            else {
                this.game.setEditClickState(EditClickState.Normal);
            }
        }
        this.renderAll();
        this.game.markCanvasDirty();
    };
    GameButtons.prototype.hideUIHelpText = function (helpText) {
        if (this.game.helpLabel == helpText) {
            this.game.helpLabel = undefined;
            this.renderAll();
        }
    };
    GameButtons.prototype.showUIHelpText = function (helpText) {
        if (this.game.helpLabel != helpText) {
            this.game.helpLabel = helpText;
            this.renderAll();
        }
    };
    GameButtons.prototype.addHelpText = function (els, helpText) {
        var _this = this;
        if (helpText) {
            els.forEach(function (el) {
                el.addEventListener('mouseleave', function (e) {
                    _this.hideUIHelpText(helpText);
                });
                el.addEventListener('mouseenter', function (e) {
                    _this.showUIHelpText(helpText);
                });
            });
        }
    };
    GameButtons.prototype.createNameRow = function () {
        var _this = this;
        var _a;
        var nameRow = createElement({ classes: 'form-row' });
        var nameRowLeft = createElement({ classes: ['game-buttons-left'], style: 'flex-grow: 1' });
        nameRow.appendChild(nameRowLeft);
        var nameLabel = createElement({ text: 'Game name' });
        var nameInputEl = createElement({
            tag: 'input',
            defaultValue: ((_a = this.game.lastGameData) === null || _a === void 0 ? void 0 : _a.description) || ''
        });
        // const nameButton = createElement({tag: 'button', text: 'update name'}) as
        // HTMLButtonElement;
        nameInputEl.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // nameButton.disabled = true;
                        nameInputEl.disabled = true;
                        logWriteOperation('updateName');
                        this.game.lastGameData.description = nameInputEl.value;
                        return [4 /*yield*/, firebase.firestore()
                                .collection('games')
                                .doc(this.game.gameId)
                                .update({ description: nameInputEl.value })];
                    case 1:
                        _a.sent();
                        this.renderAll();
                        updateBreadcrumb(nameInputEl.value);
                        return [2 /*return*/];
                }
            });
        }); };
        nameRowLeft.appendChild(nameLabel);
        nameRowLeft.appendChild(nameInputEl);
        // nameRow.appendChild(nameButton);
        var nameRowCenter = createElement({ classes: ['game-buttons-center'] });
        nameRow.appendChild(nameRowCenter);
        if (this.game.helpLabel) {
            nameRowCenter.appendChild(createElement({ text: this.game.helpLabel, classes: ['game-help-label'] }));
        }
        var nameRowRight = createElement({ classes: ['game-buttons-right'], style: 'flex-grow: 1;' });
        nameRow.appendChild(nameRowRight);
        var scaleGroup = createElement({ classes: ['game-buttons-right'], style: 'margin-left: auto;' });
        nameRowRight.appendChild(scaleGroup);
        var scaleLabel = createElement({ text: 'Card scale' });
        var scaleInputEl = createElement({
            tag: 'input',
            style: 'width: 60px;',
            defaultValue: this.game.gameScale,
        });
        // const scaleButton = createElement({tag: 'button', text: 'update scale'})
        // as HTMLButtonElement;
        scaleInputEl.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
            var value;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // scaleButton.disabled = true;
                        scaleInputEl.disabled = true;
                        value = +scaleInputEl.value;
                        value = Math.min(Math.max(value, constants.CARD_SCALE_MIN), constants.CARD_SCALE_MAX);
                        logWriteOperation('updateScale');
                        this.game.lastGameData.cardScale = value;
                        this.game.processGameData(this.game.lastGameData, true);
                        return [4 /*yield*/, firebase.firestore()
                                .collection('games')
                                .doc(this.game.gameId)
                                .update({ cardScale: value })];
                    case 1:
                        _a.sent();
                        this.renderAll();
                        return [2 /*return*/];
                }
            });
        }); };
        scaleGroup.appendChild(scaleLabel);
        scaleGroup.appendChild(scaleInputEl);
        // nameRow.appendChild(scaleButton);
        return nameRow;
    };
    GameButtons.prototype.createCheckRow = function (labelText, isChecked, onClick, helpText) {
        var checkRow = createElement({ classes: 'form-row' });
        return this.createCheckBox(checkRow, labelText, isChecked, onClick, helpText);
    };
    GameButtons.prototype.createMultiCheckRow = function (checks) {
        var checkRow = createElement({ classes: 'form-row' });
        for (var i = 0; i < checks.length; i++) {
            if (i > 0) {
                checkRow.appendChild(createElement({ classes: ['checkspacer'] }));
            }
            this.createCheckBox(checkRow, checks[i].labelText, checks[i].isChecked, checks[i].onClick, checks[i].helpText);
        }
        return checkRow;
    };
    GameButtons.prototype.createCheckBox = function (checkRow, labelText, isChecked, onClick, helpText) {
        var _this = this;
        var checkLabel = createElement({ text: labelText });
        var checkButton = createElement({ tag: 'button', text: '&check;', classes: 'checkbutton' });
        if (isChecked) {
            checkButton.classList.add('checked');
        }
        checkButton.addEventListener('click', function (e) {
            onClick(e);
            _this.renderAll();
        });
        checkLabel.addEventListener('click', function (e) {
            onClick(e);
            _this.renderAll();
        });
        var el = createElement({ style: 'display:flex; align-items: center;' });
        el.appendChild(checkButton);
        el.appendChild(checkLabel);
        checkRow.append(el);
        this.addHelpText([checkButton], helpText);
        this.addHelpText([checkLabel], helpText);
        return checkRow;
    };
    GameButtons.prototype.createOptionRow = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        var isTemplate = !!((_a = this.game.lastGameData) === null || _a === void 0 ? void 0 : _a.isTemplate);
        var isPrivate = !!((_b = this.game.lastGameData) === null || _b === void 0 ? void 0 : _b.isPrivate);
        var isArchived = !!((_c = this.game.lastGameData) === null || _c === void 0 ? void 0 : _c.isArchived);
        var isDragVertical = !!((_d = this.game.lastGameData) === null || _d === void 0 ? void 0 : _d.isDragVertical);
        var isDropVertical = !!((_e = this.game.lastGameData) === null || _e === void 0 ? void 0 : _e.isDropVertical);
        var isEditLocked = !!((_f = this.game.lastGameData) === null || _f === void 0 ? void 0 : _f.isEditLocked);
        var isJoinLocked = !!((_g = this.game.lastGameData) === null || _g === void 0 ? void 0 : _g.isJoinLocked);
        var checkBoxes = [
            {
                labelText: 'Template',
                isChecked: isTemplate,
                onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                logWriteOperation('updateIsTemplate');
                                this.game.lastGameData.isTemplate =
                                    !this.game.lastGameData.isTemplate;
                                return [4 /*yield*/, firebase.firestore()
                                        .collection('games')
                                        .doc(this.game.gameId)
                                        .update({ isTemplate: this.game.lastGameData.isTemplate })];
                            case 1:
                                _a.sent();
                                this.renderAll();
                                return [2 /*return*/];
                        }
                    });
                }); },
                helpText: 'Templates are listed in \'Create Game from Template\'.'
            },
            {
                labelText: 'Private',
                isChecked: isPrivate,
                onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                logWriteOperation('updateIsPrivate');
                                this.game.lastGameData.isPrivate =
                                    !this.game.lastGameData.isPrivate;
                                return [4 /*yield*/, firebase.firestore()
                                        .collection('games')
                                        .doc(this.game.gameId)
                                        .update({ isPrivate: this.game.lastGameData.isPrivate })];
                            case 1:
                                _a.sent();
                                this.renderAll();
                                return [2 /*return*/];
                        }
                    });
                }); },
                helpText: 'Private games are only listed for their creator and players.'
            },
            {
                labelText: 'Archived',
                isChecked: isArchived,
                onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.game.lastGameData.isArchived =
                                    !this.game.lastGameData.isArchived;
                                logWriteOperation('updateIsArchived');
                                return [4 /*yield*/, firebase.firestore()
                                        .collection('games')
                                        .doc(this.game.gameId)
                                        .update({ isArchived: this.game.lastGameData.isArchived })];
                            case 1:
                                _a.sent();
                                this.renderAll();
                                return [2 /*return*/];
                        }
                    });
                }); },
                helpText: 'Archived games are not displayed in any list.'
            },
            {
                labelText: 'Drag vertically',
                isChecked: isDragVertical,
                onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.game.lastGameData.isDragVertical =
                                    !this.game.lastGameData.isDragVertical;
                                logWriteOperation('updateIsDragVertical');
                                return [4 /*yield*/, firebase.firestore()
                                        .collection('games')
                                        .doc(this.game.gameId)
                                        .update({ isDragVertical: this.game.lastGameData.isDragVertical })];
                            case 1:
                                _a.sent();
                                this.renderAll();
                                return [2 /*return*/];
                        }
                    });
                }); },
                helpText: 'When you drag multiple cards, orient them vertically instead of horizontally.'
            },
            {
                labelText: 'Drop vertically',
                isChecked: isDropVertical,
                onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.game.lastGameData.isDropVertical =
                                    !this.game.lastGameData.isDropVertical;
                                logWriteOperation('updateIsDropVertical');
                                return [4 /*yield*/, firebase.firestore()
                                        .collection('games')
                                        .doc(this.game.gameId)
                                        .update({ isDropVertical: this.game.lastGameData.isDropVertical })];
                            case 1:
                                _a.sent();
                                this.renderAll();
                                return [2 /*return*/];
                        }
                    });
                }); },
                helpText: 'When you drop cards arbitrarily, orient them vertically instead of horizontally.'
            }
        ];
        if (this.game.user.uid == this.game.lastGameData.owner)
            checkBoxes = checkBoxes.concat([
                {
                    labelText: 'Edit-locked',
                    isChecked: isEditLocked,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.game.lastGameData.isEditLocked =
                                        !this.game.lastGameData.isEditLocked;
                                    logWriteOperation('updateIsEditLocked');
                                    return [4 /*yield*/, firebase.firestore()
                                            .collection('games')
                                            .doc(this.game.gameId)
                                            .update({ isEditLocked: this.game.lastGameData.isEditLocked })];
                                case 1:
                                    _a.sent();
                                    this.renderAll();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'Edit-locked games can only be edited by their original creator.'
                },
                {
                    labelText: 'Join-locked',
                    isChecked: isJoinLocked,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.game.lastGameData.isJoinLocked =
                                        !this.game.lastGameData.isJoinLocked;
                                    logWriteOperation('updateIsJoinLocked');
                                    return [4 /*yield*/, firebase.firestore()
                                            .collection('games')
                                            .doc(this.game.gameId)
                                            .update({ isJoinLocked: this.game.lastGameData.isJoinLocked })];
                                case 1:
                                    _a.sent();
                                    this.renderAll();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'Join-locked games can\'t be joined by more players (except their creator).',
                },
            ]);
        var el = this.createMultiCheckRow(checkBoxes);
        el.appendChild(createElement({ classes: ['checkspacer'] }));
        return el;
    };
    GameButtons.prototype.saveEditingPile = function () {
        this.game.setEditClickState(EditClickState.Normal);
        this.game.markCanvasDirty();
        this.showOverrideStyleOptions = false;
        this.renderAll();
    };
    GameButtons.prototype.createPileOptionRow = function (parentEl) {
        var _this = this;
        var _a, _b, _c;
        // Style
        // Rotation (drop-down: any, 0, 90, 180, 270)
        // Scale (text, default 1)
        // Style overrides sub-option
        // - Face down, face up, either.
        // - Splay options
        // - UX options
        // Label
        // Label size
        // Hover text
        // Hover text size
        if (this.game.editClickState != EditClickState.EditingPile ||
            !this.game.editClickStatePileId)
            return false;
        var pile = this.game.piles.get(this.game.editClickStatePileId);
        if (!pile)
            return false;
        var pileOptionRow0 = createElement({ classes: 'form-row' });
        parentEl.appendChild(pileOptionRow0);
        var pileOptionRow1 = createElement({ classes: 'form-row' });
        parentEl.appendChild(pileOptionRow1);
        // Create style dropdown
        var styleLabelEl = createElement({ text: 'Style' });
        var styleEl = createElement({
            tag: 'select',
            classes: ['edit-mode-dropdown'],
            style: 'width: 170px; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;',
            id: 'pileOption-style'
        });
        this.addHelpText([styleLabelEl, styleEl], 'Choose a predefined style for this card pile.');
        styleEl.onchange =
            function () {
                _this.game.setPileStyleId(pile.id, styleEl.value);
                var newStyle = _this.game.pileStyles.get(styleEl.value);
                if (newStyle) {
                    pile.pileStyle = __assign({}, newStyle);
                    _this.renderAll();
                    _this.game.markCanvasDirty();
                }
            };
        Array.from(this.game.pileStyles.keys())
            .forEach(function (styleId) {
            if (_this.game.pileStyles.get(styleId).placeholder ==
                PlaceholderStyle.None ||
                _this.game.pileStyles.get(styleId).hideFromList)
                return;
            var styleName = _this.game.pileStyles.get(styleId).name;
            var isCurrentStyle = (styleId == pile.pileStyle.id);
            var option = new Option(styleName, styleId, isCurrentStyle, isCurrentStyle);
            styleEl.appendChild(option);
        });
        // styleEl.disabled = this.showOverrideStyleOptions;
        pileOptionRow1.append(styleLabelEl);
        pileOptionRow1.append(styleEl);
        var customizeButton = createElement({
            tag: 'button',
            text: this.showOverrideStyleOptions ? 'un-customize' : 'customize',
            style: 'margin-right: 20px'
        });
        customizeButton.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
            var styleId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showOverrideStyleOptions = !this.showOverrideStyleOptions;
                        if (!!this.showOverrideStyleOptions) return [3 /*break*/, 2];
                        styleId = pile.pileStyle.id;
                        pile.pileStyle = __assign({}, this.game.pileStyles.get(styleId));
                        return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, null)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.renderAll();
                        this.game.markCanvasDirty();
                        return [2 /*return*/];
                }
            });
        }); });
        pileOptionRow1.append(customizeButton);
        this.addHelpText([customizeButton], this.showOverrideStyleOptions ?
            'Use a predefined style instead of customizing the exact style settings for this pile.' :
            'Customize the exact style settings for this pile, instead of using a predefined style.');
        var scaleLabel = createElement({ text: 'Scale' });
        var scaleInputEl = createElement({
            id: 'pileOption-scale',
            tag: 'input',
            style: 'width: 60px;',
            defaultValue: this.game.getPileScale(pile.id).x,
        });
        scaleInputEl.onchange = function () {
            var value = +scaleInputEl.value;
            if (!value)
                value = 1;
            value = Math.min(Math.max(value, constants.CARD_SCALE_MIN), constants.CARD_SCALE_MAX);
            _this.game.setPileScale(pile.id, value, value);
            _this.game.markCanvasDirty();
            _this.renderAll();
        };
        pileOptionRow1.append(scaleLabel);
        pileOptionRow1.append(scaleInputEl);
        this.addHelpText([scaleLabel, scaleInputEl], 'Scale the cards in this pile by this multiplier (use 1 for no scaling).');
        var rotationLabel = createElement({ text: 'Rotation' });
        var rotationEl = createElement({
            id: 'pileOption-rotation',
            tag: 'select',
            classes: ['edit-mode-dropdown'],
        });
        [null, 0, 90, 180, 270].forEach(function (angle) {
            var angleName = (angle == null) ? 'any' : angle + "\u00B0";
            var angleId = (angle == null) ? -1 : angle;
            var currRotation = _this.game.getPileRotation(pile.id);
            rotationEl.appendChild(new Option(angleName, "" + angleId, currRotation == angle, currRotation == angle));
        });
        rotationEl.onchange = function () {
            _this.game.setPileRotation(pile.id, parseInt(rotationEl.value, 10));
            _this.game.markCanvasDirty();
            _this.renderAll();
        };
        pileOptionRow1.append(rotationLabel);
        pileOptionRow1.append(rotationEl);
        this.addHelpText([rotationLabel, rotationEl], 'Choose whether all cards in this pile can be rotated to any angle, or are forced to a single rotation.');
        if (pile.dealSettings.length == 0 || this.game.editChoosingPileToDrawFrom) {
            var dealCardsButton = createElement({
                tag: 'button',
                classes: 'game-button',
                text: this.game.editChoosingPileToDrawFrom ?
                    'choose source pile or click here to cancel' :
                    'auto-deal cards to this pile'
            });
            if ((_a = this.game.lastGameData) === null || _a === void 0 ? void 0 : _a.isTemplate) {
                dealCardsButton.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        if (!((_a = this.game.lastGameData) === null || _a === void 0 ? void 0 : _a.isTemplate)) {
                            return [2 /*return*/];
                        }
                        else {
                            if (this.game.editChoosingPileToDrawFrom) {
                                this.game.setEditClickState(EditClickState.EditingPile, this.game.editClickStatePileId);
                            }
                            else {
                                this.setMessage('Click on the pile you\'d like to draw cards into this pile from.');
                                this.game.editChoosingPileToDrawFrom = true;
                                this.renderAll();
                                this.game.markCanvasDirty();
                            }
                        }
                        return [2 /*return*/];
                    });
                }); });
                this.addHelpText([dealCardsButton], 'Deal cards to this pile from another pile, when games using this template are restarted.');
            }
            else {
                this.addHelpText([dealCardsButton], optionOnlyAvailableForTemplatesText);
                dealCardsButton.setAttribute('style', 'background: gray;');
            }
            pileOptionRow1.append(dealCardsButton);
        }
        else {
            var disable_1 = !((_b = this.game.lastGameData) === null || _b === void 0 ? void 0 : _b.isTemplate);
            // Show the pullCardsOnReset options.
            var pullCardsEl = createElement({
                classes: ['pile-options-subgroup'],
                style: disable_1 ? 'background:gray;' : ''
            });
            pileOptionRow1.appendChild(pullCardsEl);
            if (pile.dealSettings.length > 0) {
                var viewPrev = createElement({
                    tag: 'button',
                    classes: 'game-button',
                    text: '⬆',
                    style: 'padding: 1px; background: ' +
                        (this.showDealSettingsNum > 0 ? 'green;' : 'gray;'),
                });
                viewPrev.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.showDealSettingsNum--;
                        if (this.showDealSettingsNum < 0)
                            this.showDealSettingsNum = 0;
                        this.renderAll();
                        this.game.markCanvasDirty();
                        return [2 /*return*/];
                    });
                }); });
                var viewNext = createElement({
                    tag: 'button',
                    classes: 'game-button',
                    text: '⬇',
                    style: 'margin-right: 8px; padding: 1px; background: ' +
                        (this.showDealSettingsNum < pile.dealSettings.length - 1 ?
                            'green;' :
                            'gray;'),
                });
                viewNext.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.showDealSettingsNum++;
                        if (this.showDealSettingsNum >= pile.dealSettings.length)
                            this.showDealSettingsNum = pile.dealSettings.length - 1;
                        this.renderAll();
                        this.game.markCanvasDirty();
                        return [2 /*return*/];
                    });
                }); });
                // const scrollEl = createElement();
                // pullCardsEl.appendChild(scrollEl);
                // scrollEl.append(viewPrev);
                // scrollEl.append(viewNext);
                this.addHelpText([viewPrev], 'View previous auto-deal setting.');
                this.addHelpText([viewNext], 'View next auto-deal setting.');
                pullCardsEl.append(viewPrev);
                var pos = createElement({
                    text: "" + (this.showDealSettingsNum + 1),
                    style: 'background: green; padding: 2px 5px 2px 5px; border-top: 1px solid white; border-bottom: 1px solid white;'
                });
                pullCardsEl.append(pos);
                this.addHelpText([pos], "Viewing deal setting " + (this.showDealSettingsNum + 1) + " of " + pile.dealSettings.length + ". Use \u2B06 and \u2B07 to select.");
                pullCardsEl.append(viewNext);
            }
            var title = createElement({ text: "Deal" });
            this.addHelpText([title], disable_1 ?
                'This pile is set to be dealt cards when the game is restarted.' :
                'This pile is set to be dealt cards when games using this template are restarted.');
            pullCardsEl.append(title);
            var currentDealSetting_1 = pile.dealSettings[this.showDealSettingsNum];
            var numCardsEl_1 = createElement({
                id: 'pileOption-pullCards-numCards',
                tag: 'input',
                style: 'width: 40px; margin-right: 0px; margin-left: ',
                defaultValue: currentDealSetting_1.numCards,
            });
            if (!disable_1) {
                numCardsEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                currentDealSetting_1.numCards =
                                    Math.max(0, parseInt(numCardsEl_1.value, 10));
                                return [4 /*yield*/, this.game.setPileDrawCardsOnReset(pile.id, pile.dealSettings)];
                            case 1:
                                _a.sent();
                                this.renderAll();
                                this.game.markCanvasDirty();
                                return [2 /*return*/];
                        }
                    });
                }); };
            }
            var caption = currentDealSetting_1.numCards == 1 ? 'card' : 'cards';
            var numCardsText = createElement({ text: caption + ", if", style: 'margin-right: 10px;' });
            this.addHelpText([numCardsText, numCardsEl_1], disable_1 ?
                'How many cards will be dealt to this pile. Auto-deal settings can only be modified in this game\'s template.' :
                'Select how many cards to pull from the source pile.');
            if (disable_1)
                numCardsEl_1.readOnly = true;
            pullCardsEl.append(numCardsEl_1);
            pullCardsEl.append(numCardsText);
            pullCardsEl.appendChild(createElement({ classes: ['vl'] }));
            pullCardsEl.appendChild(createElement({ style: 'padding-left: 10px;' }));
            var onlyIfAssignedEl = this.createCheckBox(pullCardsEl, '🔓', currentDealSetting_1.onlyIfAssignedPlayer, function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!disable_1) return [3 /*break*/, 2];
                            currentDealSetting_1.onlyIfAssignedPlayer =
                                !currentDealSetting_1.onlyIfAssignedPlayer;
                            return [4 /*yield*/, this.game.setPileDrawCardsOnReset(pile.id, pile.dealSettings)];
                        case 1:
                            _a.sent();
                            this.renderAll();
                            this.game.markCanvasDirty();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, 'If checked, this pile is only dealt cards if it is assigned player visibility (e.g. is an active player\'s hand).');
            onlyIfAssignedEl.childNodes[4].style.marginRight =
                '2px';
            var playersAtLeastText = createElement({ text: '👤≥', style: 'margin-left: 5px;' });
            // 👤
            var playersAtLeast_1 = createElement({
                id: 'pileOption-pullCards-playersAtLeast',
                tag: 'input',
                style: 'width: 40px; margin-left: 0px;',
                defaultValue: currentDealSetting_1.onlyIfPlayerCountAtLeast ?
                    currentDealSetting_1.onlyIfPlayerCountAtLeast :
                    0,
            });
            if (!disable_1) {
                playersAtLeast_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                currentDealSetting_1.onlyIfPlayerCountAtLeast =
                                    Math.max(0, parseInt(playersAtLeast_1.value, 10));
                                return [4 /*yield*/, this.game.setPileDrawCardsOnReset(pile.id, pile.dealSettings)];
                            case 1:
                                _a.sent();
                                this.renderAll();
                                this.game.markCanvasDirty();
                                return [2 /*return*/];
                        }
                    });
                }); };
            }
            this.addHelpText([playersAtLeast_1, playersAtLeastText], disable_1 ?
                'Only deal if # of players ≥ this number. Auto-deal settings can only be modified in this game\'s template.' :
                'Deal these cards only if # of players ≥ this number. Enter 0 to always deal cards. For =, set both.');
            var playersAtMostText = createElement({ text: '👤≤', style: 'margin-left: 5px;' });
            var playersAtMost_1 = createElement({
                id: 'pileOption-pullCards-playersAtMost',
                tag: 'input',
                style: 'width: 40px; margin-left: 0px;',
                defaultValue: currentDealSetting_1.onlyIfPlayerCountAtMost ?
                    currentDealSetting_1.onlyIfPlayerCountAtMost :
                    '∞',
            });
            if (!disable_1) {
                playersAtMost_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                currentDealSetting_1.onlyIfPlayerCountAtMost =
                                    Math.max(0, parseInt(playersAtMost_1.value, 10));
                                return [4 /*yield*/, this.game.setPileDrawCardsOnReset(pile.id, pile.dealSettings)];
                            case 1:
                                _a.sent();
                                this.renderAll();
                                this.game.markCanvasDirty();
                                return [2 /*return*/];
                        }
                    });
                }); };
            }
            this.addHelpText([playersAtMost_1, playersAtMostText], disable_1 ?
                'Only deal if # of players ≤ this number. Auto-deal settings can only be modified in this game\'s template.' :
                'Deal these cards only if # of players ≤ this number. Enter 0 to always deal cards. For =, set both.');
            pullCardsEl.appendChild(createElement({ classes: ['vl'], style: 'margin-left: 5px;' }));
            pullCardsEl.append(playersAtLeastText);
            pullCardsEl.append(playersAtLeast_1);
            pullCardsEl.appendChild(createElement({ classes: ['vl'] }));
            pullCardsEl.append(playersAtMostText);
            pullCardsEl.append(playersAtMost_1);
            if (!disable_1) {
                pullCardsEl.appendChild(createElement({ classes: ['vl'], style: 'margin-right: 10px;' }));
            }
            if (disable_1) {
                numCardsEl_1.readOnly = true;
                playersAtMost_1.readOnly = true;
                playersAtLeast_1.readOnly = true;
            }
            // const addRemoveButtons = createElement({
            // classes: ['pile-options-subgroup'],
            //});
            // const pullCardsRight = createElement();
            var addMore = createElement({
                tag: 'button',
                classes: 'game-button',
                text: '➕',
                style: 'background: green; margin-right: 3px; padding: 1px;',
            });
            this.addHelpText([addMore], 'Add an additional auto-deal setting.');
            var turnOff = createElement({
                tag: 'button',
                classes: 'game-button',
                text: '❌',
                style: 'background: green; padding: 1px;',
            });
            this.addHelpText([turnOff], 'Delete this auto-deal setting.');
            if (!disable_1) {
                addMore.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.game.editChoosingPileToDrawFrom = true;
                        this.renderAll();
                        this.game.markCanvasDirty();
                        return [2 /*return*/];
                    });
                }); });
                turnOff.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
                    var newDealSettings;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(pile.dealSettings.length == 1)) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.game.setPileDrawCardsOnReset(pile.id, null)];
                            case 1:
                                _a.sent();
                                return [3 /*break*/, 4];
                            case 2:
                                newDealSettings = __spreadArrays(pile.dealSettings);
                                newDealSettings.splice(this.showDealSettingsNum, 1);
                                return [4 /*yield*/, this.game.setPileDrawCardsOnReset(pile.id, newDealSettings)];
                            case 3:
                                _a.sent();
                                if (this.showDealSettingsNum > 0)
                                    this.showDealSettingsNum--;
                                _a.label = 4;
                            case 4:
                                this.renderAll();
                                this.game.markCanvasDirty();
                                return [2 /*return*/];
                        }
                    });
                }); });
                pullCardsEl.append(addMore);
                pullCardsEl.append(turnOff);
                // pullCardsRight.appendChild(addRemoveButtons);
            }
            // pullCardsEl.appendChild(pullCardsRight);
        }
        var pileLabelEl = createElement({
            id: 'pileOption-label',
            tag: 'input',
            style: 'width: 200px;',
            defaultValue: this.game.getPileLabel(this.game.editClickStatePileId),
        });
        var labelTextEl = createElement({ text: 'Label' });
        pileLabelEl.onchange = function () {
            _this.game.setPileLabel(_this.game.editClickStatePileId, pileLabelEl.value);
        };
        pileOptionRow0.appendChild(labelTextEl);
        pileOptionRow0.appendChild(pileLabelEl);
        this.addHelpText([labelTextEl, pileLabelEl], 'Add label text to be displayed above the pile.');
        var labelSizeEl = createElement({
            id: 'pileOption-labelSize',
            tag: 'input',
            style: 'width: 50px;',
            defaultValue: this.game.getPileLabelSize(pile.id),
        });
        var labelSizeTextEl = createElement({ text: 'Label size' });
        labelSizeEl.onchange = function () {
            _this.game.setPileLabelSize(_this.game.editClickStatePileId, parseInt(labelSizeEl.value, 10));
        };
        pileOptionRow0.appendChild(labelSizeTextEl);
        pileOptionRow0.appendChild(labelSizeEl);
        this.addHelpText([labelSizeEl, labelSizeTextEl], 'Change label text size, or 0 for default.');
        var helpTextTextEl = createElement({ text: 'Hover text' });
        var pileHelpTextEl = createElement({
            id: 'pileOption-helpText',
            tag: 'input',
            style: 'width: 500px;',
            defaultValue: this.game.getPileHelpText(this.game.editClickStatePileId),
        });
        pileHelpTextEl.onchange = function () {
            _this.game.setPileHelpText(_this.game.editClickStatePileId, pileHelpTextEl.value);
        };
        pileOptionRow0.appendChild(helpTextTextEl);
        pileOptionRow0.appendChild(pileHelpTextEl);
        this.addHelpText([helpTextTextEl, pileHelpTextEl], 'Help text to display when you mouse-over the pile. $num and $numCards are replaced with the card count.');
        if (this.showOverrideStyleOptions) {
            var pileOptionRow2 = createElement({ classes: 'form-row' });
            parentEl.appendChild(pileOptionRow2);
            var spreadLabel = createElement({ text: 'Card spread', style: 'margin-right: 15px' });
            pileOptionRow2.append(spreadLabel);
            var cardSpreadEl = createElement({ classes: ['pile-options-subgroup'] });
            pileOptionRow2.appendChild(cardSpreadEl);
            this.addHelpText([spreadLabel], 'Customize how the cards in this pile spread out (\'splay\'), either horizontally or vertically.');
            var splayXLabel = createElement({ text: 'horiz %' });
            var splayXInputEl_1 = createElement({
                id: 'pileOption-custom-splay-x',
                tag: 'input',
                style: 'width: 50px; padding: 1px;',
                defaultValue: pile.pileStyle.splayPctX,
            });
            this.addHelpText([splayXLabel, splayXInputEl_1], 'Spread out cards horizontally by this amount, specified as % of card width.');
            cardSpreadEl.append(splayXLabel);
            cardSpreadEl.append(splayXInputEl_1);
            splayXInputEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            pile.pileStyle.splayPctX = +splayXInputEl_1.value;
                            return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            var splayYLabel = createElement({ text: 'vert %' });
            var splayYInputEl_1 = createElement({
                id: 'pileOption-custom-splay-y',
                tag: 'input',
                style: 'width: 50px; padding: 1px;',
                defaultValue: pile.pileStyle.splayPctY,
            });
            this.addHelpText([splayYLabel, splayYInputEl_1], 'Spread out cards vertically by this amount, specified as % of card width.');
            cardSpreadEl.append(splayYLabel);
            cardSpreadEl.append(splayYInputEl_1);
            splayYInputEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            pile.pileStyle.splayPctY = +splayYInputEl_1.value;
                            return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            var splayMultLabel = createElement({ text: 'grouping' });
            var splayMultInputEl_1 = createElement({
                id: 'pileOption-custom-splay-mult',
                tag: 'input',
                style: 'width: 50px; padding: 1px;',
                defaultValue: pile.pileStyle.splayMult,
            });
            this.addHelpText([splayMultLabel, splayMultInputEl_1], 'When spreading out cards, group them together this many at a time (useful for face-down decks). Default 1.');
            cardSpreadEl.append(splayMultLabel);
            cardSpreadEl.append(splayMultInputEl_1);
            splayMultInputEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            pile.pileStyle.splayMult = +splayMultInputEl_1.value;
                            return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            var splayMaxLabel = createElement({ text: 'max' });
            var splayMaxInputEl_1 = createElement({
                id: 'pileOption-custom-splay-max',
                tag: 'input',
                style: 'width: 50px; padding: 1px;',
                defaultValue: pile.pileStyle.splayMax,
            });
            this.addHelpText([splayMaxLabel, splayMaxInputEl_1], 'Maximum number of cards to show spread out, or 0 for unlimited. Default 0.');
            cardSpreadEl.append(splayMaxLabel);
            cardSpreadEl.append(splayMaxInputEl_1);
            splayMaxInputEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            pile.pileStyle.splayMax = +splayMaxInputEl_1.value;
                            return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            var facingLabel = createElement({ text: ' Card facing', style: 'margin-left: 15px;' });
            var facingEl_1 = createElement({
                id: 'pileOption-facing',
                tag: 'select',
                classes: ['edit-mode-dropdown'],
            });
            facingEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (facingEl_1.value == 'faceUp') {
                                pile.pileStyle.facing = PileFacing.ForceFaceUp;
                            }
                            else if (facingEl_1.value == 'faceDown') {
                                pile.pileStyle.facing = PileFacing.ForceFaceDown;
                            }
                            else {
                                pile.pileStyle.facing = PileFacing.Any;
                            }
                            return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            facingEl_1.appendChild(new Option('any', 'any', pile.pileStyle.facing == PileFacing.Any, pile.pileStyle.facing == PileFacing.Any));
            facingEl_1.appendChild(new Option('up', 'faceUp', pile.pileStyle.facing == PileFacing.ForceFaceUp, pile.pileStyle.facing == PileFacing.ForceFaceUp));
            facingEl_1.appendChild(new Option('down', 'faceDown', pile.pileStyle.facing == PileFacing.ForceFaceDown, pile.pileStyle.facing == PileFacing.ForceFaceDown));
            this.addHelpText([facingLabel, facingEl_1], 'Choose whether cards in this pile can be any facing, or are forced to be all face up or all face down.');
            pileOptionRow2.append(facingLabel);
            pileOptionRow2.append(facingEl_1);
            var layerLabel = createElement({ text: 'Layer', style: 'margin-left: 10px;' });
            var layerEl_1 = createElement({
                id: 'pileOption-layer',
                tag: 'select',
                classes: ['edit-mode-dropdown'],
            });
            layerEl_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            pile.pileStyle.layer = parseInt(layerEl_1.value, 10);
                            return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            layerEl_1.appendChild(new Option('default', '0', pile.pileStyle.layer == 0, pile.pileStyle.layer == 0));
            layerEl_1.appendChild(new Option('back', '-1', pile.pileStyle.layer == -1, pile.pileStyle.layer == -1));
            layerEl_1.appendChild(new Option('front', '1', pile.pileStyle.layer == 1, pile.pileStyle.layer == 1));
            this.addHelpText([layerLabel, layerEl_1], 'Choose whether these cards are rendered in front of or behind the default layer of cards.');
            pileOptionRow2.append(layerLabel);
            pileOptionRow2.append(layerEl_1);
            var el = this.createMultiCheckRow([
                {
                    labelText: 'shuffle on restart',
                    isChecked: (pile.pileStyle.uxMode & UXMode.ShuffleOnReset) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode =
                                        (pile.pileStyle.uxMode ^ UXMode.ShuffleOnReset);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: (((_c = this.game.lastGameData) === null || _c === void 0 ? void 0 : _c.isTemplate) ?
                        'When games using this template are restarted, this pile will be shuffled.' :
                        optionOnlyAvailableForTemplatesText),
                },
                {
                    labelText: 'draw from top',
                    isChecked: (pile.pileStyle.uxMode & UXMode.Draw) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode = (pile.pileStyle.uxMode ^ UXMode.Draw);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'Only cards on top can be interacted with. Double-click and drag to draw multiple cards.',
                },
                {
                    labelText: 'double-click to expand',
                    isChecked: (pile.pileStyle.uxMode & UXMode.Expand) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode = (pile.pileStyle.uxMode ^ UXMode.Expand);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'Double-click on this pile to spread the cards. If NOT set, double-clicking flips cards instead.',
                },
                {
                    labelText: 'hover zoom',
                    isChecked: (pile.pileStyle.uxMode & UXMode.ZoomCard) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode = (pile.pileStyle.uxMode ^ UXMode.ZoomCard);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'If you mouse-over a card, it will be zoomed so you can read it more easily.',
                },
                {
                    labelText: 'hover expand',
                    isChecked: (pile.pileStyle.uxMode & UXMode.PeekCard) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode = (pile.pileStyle.uxMode ^ UXMode.PeekCard);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'If you mouse-over a card, other cards will move out of the way to make this card fully visible.',
                },
                {
                    labelText: 'hover flip',
                    isChecked: (pile.pileStyle.uxMode & UXMode.SecretlyViewCard) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode =
                                        (pile.pileStyle.uxMode ^ UXMode.SecretlyViewCard);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'If you mouse-over a card, you can view its flip side.',
                },
                {
                    labelText: 'greedy',
                    isChecked: (pile.pileStyle.uxMode & UXMode.GreedyCollision) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode =
                                        (pile.pileStyle.uxMode ^ UXMode.GreedyCollision);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'Greedy piles are easier to drop cards onto (useful for player hands, markets, tableaus).',
                },
                {
                    labelText: 'hidden',
                    isChecked: (pile.pileStyle.uxMode & UXMode.Hidden) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode = (pile.pileStyle.uxMode ^ UXMode.Hidden);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'This pile is hidden from view, except in edit mode.',
                },
                {
                    labelText: 'locked',
                    isChecked: (pile.pileStyle.uxMode & UXMode.Locked) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode = (pile.pileStyle.uxMode ^ UXMode.Locked);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'Cards cannot be moved to nor from this pile.',
                },
                {
                    labelText: 'one card',
                    isChecked: (pile.pileStyle.uxMode & UXMode.OneCard) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode = (pile.pileStyle.uxMode ^ UXMode.OneCard);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'This pile can hold at most one card.',
                },
                {
                    labelText: 'sort cards',
                    isChecked: (pile.pileStyle.uxMode & UXMode.SortCards) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode = (pile.pileStyle.uxMode ^ UXMode.SortCards);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'Cards in this pile are always sorted (by their import order).',
                },
                {
                    labelText: 'flip top card',
                    isChecked: (pile.pileStyle.uxMode & UXMode.FlipTopCard) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode =
                                        (pile.pileStyle.uxMode ^ UXMode.FlipTopCard);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'The top card of this pile is flipped over (only for piles with forced card facing up or down).',
                },
                {
                    labelText: 'auto tableau',
                    isChecked: (pile.pileStyle.uxMode & UXMode.AutoTableau) != 0,
                    onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    pile.pileStyle.uxMode =
                                        (pile.pileStyle.uxMode ^ UXMode.AutoTableau);
                                    return [4 /*yield*/, this.game.setPileStyleOverride(pile.id, pile.pileStyle)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                    helpText: 'When this pile has at least one card, another empty pile appears next to it, and so on.',
                },
            ]);
            el.classList.remove('form-row');
            el.style.cssText =
                'flex-wrap: wrap; justify-content: left; flex-basis: 100%; height: 80px;';
            el.classList.add('pile-options-subgroup');
            var label = createElement({ text: 'Pile&nbsp;options', style: 'margin-right: 5px' });
            var pileRowOptions3 = createElement({ classes: ['form-row'] });
            pileRowOptions3.appendChild(label);
            // el.insertBefore(label, el.firstChild);
            this.addHelpText([label], 'These options control how you interact with the cards in the pile.');
            pileRowOptions3.appendChild(el);
            parentEl.appendChild(pileRowOptions3);
        }
        return true;
    };
    GameButtons.prototype.renderAll = function () {
        return __awaiter(this, void 0, void 0, function () {
            var leftGroup, centerGroup, rightGroup, buttonRoot, topGroup, bottomGroup, optionRow, saveButtonEl, el, messageEl, el, el, buttonsToRender, _loop_1, this_1, _i, buttonsToRender_1, button, el, el_1, uploadRulebook_1, rulebookLabel;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isInitialized];
                    case 1:
                        _a.sent();
                        this.container.innerHTML = '';
                        leftGroup = createElement({ classes: 'game-buttons-left' });
                        centerGroup = createElement({ classes: 'game-buttons-center' });
                        rightGroup = createElement({ classes: 'game-buttons-right' });
                        buttonRoot = this.container;
                        if (this.isEditMode) {
                            topGroup = createElement({ classes: 'game-buttons-top' });
                            bottomGroup = createElement({ classes: 'game-buttons-bottom' });
                            buttonRoot = topGroup;
                            this.container.appendChild(topGroup);
                            this.container.appendChild(bottomGroup);
                            this.container.classList.add('vertical');
                            if (!this.createPileOptionRow(bottomGroup)) {
                                bottomGroup.appendChild(this.createNameRow());
                                optionRow = this.createOptionRow();
                                bottomGroup.appendChild(optionRow);
                            }
                            else {
                                saveButtonEl = createElement({
                                    tag: 'button',
                                    classes: ['game-button', 'edit-button'],
                                    text: 'save pile changes'
                                });
                                saveButtonEl.addEventListener('click', function (e) {
                                    _this.saveEditingPile();
                                });
                                this.addHelpText([saveButtonEl], 'Save changes to pile and return to edit mode.');
                                if (this.game.helpLabel && !this.game.editChoosingPileToDrawFrom) {
                                    centerGroup.appendChild(createElement({ text: this.game.helpLabel, classes: ['game-help-label'] }));
                                }
                                rightGroup.appendChild(saveButtonEl);
                            }
                        }
                        else {
                            this.container.classList.remove('vertical');
                        }
                        buttonRoot.appendChild(leftGroup);
                        if (this.game.helpLabel && !this.game.editMode) {
                            buttonRoot.appendChild(createElement({ text: this.game.helpLabel, classes: ['game-help-label'] }));
                        }
                        if (!this.userIsPlayer) {
                            el = createElement({ text: 'You are currently a spectator. ', classes: ['message'] });
                            leftGroup.appendChild(el);
                        }
                        buttonRoot.appendChild(centerGroup);
                        buttonRoot.appendChild(rightGroup);
                        if (this.message || this.isEditMode) {
                            messageEl = createElement({ classes: 'message', text: this.message || this.editModeMessage });
                            leftGroup.appendChild(messageEl);
                            if (this.message) {
                                if (!this.game.editClickStatePileId) {
                                    el = createElement({
                                        tag: 'button',
                                        classes: 'cancel-button',
                                        text: this.cancelText
                                    });
                                    el.addEventListener('click', function () { _this.onCancelButtonClick(); });
                                    leftGroup.appendChild(el);
                                }
                                if (this.isEditMode &&
                                    this.game.editClickState == EditClickState.TogglingUser &&
                                    this.game.editClickStateUserId &&
                                    this.game.editClickStateUserId != '' &&
                                    this.game.editClickStateUserId != this.game.user.uid) {
                                    el = createElement({
                                        tag: 'button',
                                        classes: 'quit-button',
                                        text: 'move ' +
                                            this.game.players.getDisplayName(this.game.editClickStateUserId) +
                                            ' to spectator',
                                    });
                                    el.addEventListener('click', function () { _this.onRemoveUserClick(); });
                                    rightGroup.appendChild(el);
                                }
                                return [2 /*return*/];
                            }
                        }
                        buttonsToRender = this.buttons.filter(function (button) {
                            if (!_this.userIsPlayer) {
                                return button.mode === 'spectator';
                            }
                            else if (_this.isEditMode) {
                                return button.mode === 'edit';
                            }
                            else {
                                return button.mode === 'default';
                            }
                        });
                        _loop_1 = function (button) {
                            if (button == this_1.editModeButton && this_1.game.lastGameData == undefined)
                                return "continue";
                            var text = button.text;
                            var helpText = button.helpText;
                            var style = '';
                            if (button == this_1.editModeButton &&
                                this_1.game.lastGameData.isEditLocked &&
                                this_1.game.user.uid != this_1.game.lastGameData.owner) {
                                helpText =
                                    'This game is edit locked. Only the game\'s original creator can edit it.';
                                style = 'background: gray;';
                            }
                            var el = createElement({
                                tag: 'button',
                                classes: 'game-button',
                                text: text,
                                style: style
                            });
                            el.addEventListener('click', function (e) { return button.action(e); });
                            this_1.addHelpText([el], helpText);
                            button.className && el.classList.add(button.className);
                            if (button.side === 'left') {
                                leftGroup.appendChild(el);
                            }
                            else {
                                rightGroup.appendChild(el);
                            }
                        };
                        this_1 = this;
                        for (_i = 0, buttonsToRender_1 = buttonsToRender; _i < buttonsToRender_1.length; _i++) {
                            button = buttonsToRender_1[_i];
                            _loop_1(button);
                        }
                        buttonRoot.onmousedown = function () {
                            _this.game.players.cancelClickUser();
                            return true;
                        };
                        if (!this.isEditMode && this.game.lastGameData && this.game.lastGameData.helpUrl && this.game.lastGameData.helpUrl.length > 0) {
                            el = createElement({
                                tag: 'button',
                                classes: 'game-button',
                                text: 'view rulebook',
                            });
                            leftGroup.appendChild(el);
                            el.addEventListener('click', function (e) {
                                window.open(_this.game.lastGameData.helpUrl);
                            });
                            if (this.game.lastGameData.helpRef &&
                                this.game.lastGameData.helpRef.toLowerCase().endsWith('.pdf')) {
                                this.addHelpText([el], 'Open the game\'s rulebook (PDF) in a new window.');
                            }
                            else {
                                this.addHelpText([el], 'Open the game\'s rulebook in a new window.');
                            }
                        }
                        this.rulebookLabel = undefined;
                        if (this.isEditMode && this.game.editClickState == EditClickState.Normal &&
                            this.game.user.uid == this.game.lastGameData.owner) {
                            if (this.game.lastGameData.helpUrl &&
                                this.game.lastGameData.helpUrl.length > 0) {
                                el_1 = createElement({
                                    tag: 'button',
                                    classes: 'game-button',
                                    text: 'remove rulebook',
                                });
                                leftGroup.appendChild(el_1);
                                el_1.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () {
                                    var oldHelpRef;
                                    var _this = this;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                el_1.textContent = 'please wait...';
                                                oldHelpRef = this.game.lastGameData.helpRef;
                                                return [4 /*yield*/, firebase.firestore()
                                                        .collection('games')
                                                        .doc(this.game.gameId)
                                                        .update({ helpUrl: null, helpRef: null })];
                                            case 1:
                                                _a.sent();
                                                if (!(oldHelpRef && oldHelpRef.length > 0)) return [3 /*break*/, 3];
                                                if (!(oldHelpRef.indexOf(this.game.gameId) != -1 &&
                                                    oldHelpRef.indexOf(this.game.user.uid) != -1)) return [3 /*break*/, 3];
                                                // Only delete it if the filename belongs to this gameId and was
                                                // uploaded by us.
                                                return [4 /*yield*/, firebase.storage().ref(oldHelpRef).delete()];
                                            case 2:
                                                // Only delete it if the filename belongs to this gameId and was
                                                // uploaded by us.
                                                _a.sent();
                                                _a.label = 3;
                                            case 3:
                                                if (this.game.lastGameData) {
                                                    this.game.lastGameData.helpUrl = '';
                                                    this.game.lastGameData.helpRef = '';
                                                }
                                                setTimeout(function () {
                                                    _this.renderAll();
                                                }, 250);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); });
                            }
                            else {
                                uploadRulebook_1 = createElement({
                                    id: 'upload-rulebook-input',
                                    text: 'upload rulebook',
                                    tag: 'input'
                                });
                                rulebookLabel = this.rulebookLabel =
                                    createElement({ tag: 'label', classes: ['game-button'] });
                                rulebookLabel.setAttribute('for', 'upload-rulebook-input');
                                rulebookLabel.classList.add('file-input-label');
                                rulebookLabel.textContent = 'upload rulebook';
                                leftGroup.appendChild(uploadRulebook_1);
                                leftGroup.appendChild(rulebookLabel);
                                uploadRulebook_1.type = 'file';
                                uploadRulebook_1.accept = 'application/pdf,image/*';
                                uploadRulebook_1.onclick = null;
                                uploadRulebook_1.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                                    var file, ref, url, oldHelpRef;
                                    var _this = this;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (this.rulebookLabel)
                                                    this.rulebookLabel.textContent = 'please wait...';
                                                return [4 /*yield*/, this.rulebookUploaded(uploadRulebook_1.files)];
                                            case 1:
                                                file = _a.sent();
                                                ref = file[0];
                                                url = file[1];
                                                if (!(ref.length > 0 && url.length > 0)) return [3 /*break*/, 3];
                                                oldHelpRef = this.game.lastGameData.helpRef;
                                                logWriteOperation('updateRulebook');
                                                if (this.rulebookLabel)
                                                    this.rulebookLabel.textContent = 'please wait...';
                                                return [4 /*yield*/, firebase.firestore()
                                                        .collection('games')
                                                        .doc(this.game.gameId)
                                                        .update({ helpUrl: url, helpRef: ref })];
                                            case 2:
                                                _a.sent();
                                                if (this.game.lastGameData) {
                                                    this.game.lastGameData.helpUrl = url;
                                                    this.game.lastGameData.helpRef = ref;
                                                }
                                                if (this.rulebookLabel)
                                                    this.rulebookLabel.textContent = 'please wait...';
                                                setTimeout(function () {
                                                    _this.renderAll();
                                                }, 250);
                                                _a.label = 3;
                                            case 3: return [2 /*return*/];
                                        }
                                    });
                                }); };
                            }
                            ;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    GameButtons.prototype.rulebookUploaded = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var uid, storage, rulebookRef, timestamp, fileRef, ref, url, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!files || !files.item(0))
                            return [2 /*return*/, ['', '']];
                        uid = this.game.user.uid;
                        storage = firebase.storage();
                        rulebookRef = storage.ref().child("/users/" + uid + "/rulebooks");
                        timestamp = (new Date()).getTime();
                        fileRef = rulebookRef.child(this.game.gameId + "_" + timestamp + path.extname(files.item(0).name));
                        ref = fileRef.fullPath;
                        url = '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fileRef.put(files.item(0))];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, fileRef.getDownloadURL()];
                    case 3:
                        url = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        console.log('firebase storage exception: ', e_1);
                        this.message = 'Upload error: ' + e_1.message;
                        alert(this.message);
                        return [2 /*return*/, ['', '']];
                    case 5: return [2 /*return*/, [ref, url]];
                }
            });
        });
    };
    GameButtons.prototype.getViewingDealSource = function () {
        if (this.game.editClickState == EditClickState.EditingPile &&
            this.game.editClickStatePileId) {
            var pile = this.game.piles.get(this.game.editClickStatePileId);
            if (!pile)
                return '';
            if (this.showDealSettingsNum < pile.dealSettings.length)
                return pile.dealSettings[this.showDealSettingsNum].fromPile;
        }
        return '';
    };
    return GameButtons;
}());
export { GameButtons };
