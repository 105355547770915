import { UXMode } from './types';
var CardCollision = /** @class */ (function () {
    function CardCollision() {
        this.cardAabbs = Array();
    }
    CardCollision.prototype.clear = function () {
        this.cardAabbs = Array();
    };
    // Add this card to the collision list. Later cards are clicked on first.
    CardCollision.prototype.addCard = function (left, right, top, bottom, card) {
        var cardAabb = { left: left, right: right, top: top, bottom: bottom, card: card };
        this.cardAabbs.push(cardAabb);
    };
    CardCollision.prototype.addPilePlaceholder = function (left, right, top, bottom, pile) {
        var cardAabb = { left: left, right: right, top: top, bottom: bottom, pile: pile };
        this.cardAabbs.push(cardAabb);
    };
    // Returns the card you clicked on.
    CardCollision.prototype.getCollisionAABB = function (x1, y1, x2, y2, ignoreCards, ignorePiles) {
        if (ignoreCards === void 0) { ignoreCards = Array(); }
        if (ignorePiles === void 0) { ignorePiles = Array(); }
        // Center point for non-greedy piles.
        var x = (x1 + x2) / 2;
        var y = (y1 + y2) / 2;
        for (var i = this.cardAabbs.length - 1; i >= 0; i--) {
            var aabb = this.cardAabbs[i];
            if (aabb.card && ignoreCards.indexOf(aabb.card.id) != -1)
                continue;
            if (aabb.card && aabb.card.isDragBeingAnimated())
                continue;
            if (aabb.card && aabb.card.cardPile != null &&
                ignorePiles.indexOf(aabb.card.cardPile.id) != -1)
                continue;
            if (aabb.pile && ignorePiles.indexOf(aabb.pile.id) != -1)
                continue;
            // Not in the ignore list.
            var touching = true;
            var pile = (aabb.card && aabb.card.cardPile) ? aabb.card.cardPile : aabb.pile;
            if (pile && (pile.pileStyle.uxMode & UXMode.GreedyCollision)) {
                if (x2 < aabb.left)
                    touching = false;
                if (x1 > aabb.right)
                    touching = false;
                if (y2 < aabb.top)
                    touching = false;
                if (y1 > aabb.bottom)
                    touching = false;
            }
            else {
                if (x < aabb.left)
                    touching = false;
                if (x > aabb.right)
                    touching = false;
                if (y < aabb.top)
                    touching = false;
                if (y > aabb.bottom)
                    touching = false;
            }
            if (touching) {
                return aabb;
            }
        }
        return null;
    };
    CardCollision.prototype.getOverlappingPile = function (draggingCard, ignoreCardIds, ignorePiles) {
        if (!ignoreCardIds)
            ignoreCardIds = new Array();
        if (!ignorePiles)
            ignorePiles = new Array();
        // Put all piles that can't accept cards in the ignore list.
        var collision = this.getCollisionAABB(draggingCard.upperLeft().x, draggingCard.upperLeft().y, draggingCard.bottomRight().x, draggingCard.bottomRight().y, ignoreCardIds.concat([draggingCard.id]), ignorePiles);
        if (collision) {
            if (collision.card)
                return collision.card.cardPile;
            else if (collision.pile)
                return collision.pile;
        }
        return undefined;
    };
    CardCollision.prototype.getPileAtPosition = function (x, y, ignoreCardIds) {
        if (!ignoreCardIds)
            ignoreCardIds = new Array();
        var collision = this.getCollisionAABB(x, y, x, y, ignoreCardIds);
        if (collision) {
            if (collision.card)
                return collision.card.cardPile;
            else if (collision.pile)
                return collision.pile;
        }
        return undefined;
    };
    // Look at the cards in a pile and the card we are dragging, and
    // figure out what position that card should be dropped into the
    // destination pile based on heuristics.
    CardCollision.prototype.calculateDropIndex = function (draggingCard, destinationPile, ignorePileIds) {
        var _a;
        // First some short-circuits, if a pile never splays then you can only drop
        // on top.
        if (destinationPile.numCards() == 0) {
            return -1;
        }
        if (destinationPile.pileStyle.splayPctX == 0 &&
            destinationPile.pileStyle.splayPctY == 0) {
            return -1;
        }
        // Second, if it's a draw pile you can only drop on top.
        if (destinationPile.pileStyle.uxMode & UXMode.Draw) {
            return -1;
        }
        // If the destination pile is empty, it doesn't matter.
        if (destinationPile.numCards() == 0) {
            return -1;
        }
        // Always drop on the end if we're dropping on a new pile.
        if (destinationPile.id != ((_a = draggingCard.cardPile) === null || _a === void 0 ? void 0 : _a.id)) {
            return -1;
        }
        var closestIdx = -1;
        var closestDist = -1;
        for (var i = destinationPile.numCards() - 1; i >= 0; i--) {
            var destCard = destinationPile.getCard(i);
            if (ignorePileIds.indexOf(destCard.id) != -1)
                continue;
            var distance = Math.max(Math.abs(draggingCard.center().x - destCard.center().x), Math.abs(draggingCard.center().y - destCard.center().y));
            if (closestDist < 0 || distance < closestDist) {
                closestIdx = i;
                closestDist = distance;
            }
        }
        // We have an index of a closest card - now check if we go before or after
        // that card.
        if (closestIdx < 0 || closestIdx >= destinationPile.numCards())
            return -1;
        var closestCard = destinationPile.getCard(closestIdx);
        if (destinationPile.pileStyle.splayPctY != 0) {
            // Vertical pile, base it on Y.
            var above = false;
            if (draggingCard.center().y > closestCard.center().y) {
                above = true;
            }
            if (above == (destinationPile.pileStyle.splayPctY > 0)) {
                closestIdx++;
                if (closestIdx >= destinationPile.numCards()) {
                    closestIdx = -1;
                }
            }
        }
        else if (destinationPile.pileStyle.splayPctX != 0) {
            // Horizontal pile.
            var above = false;
            if (draggingCard.center().x > closestCard.center().x) {
                above = true;
            }
            if (above == (destinationPile.pileStyle.splayPctX > 0)) {
                closestIdx++;
                if (closestIdx >= destinationPile.numCards()) {
                    closestIdx = -1;
                }
            }
        }
        else {
            return -1;
        }
        return closestIdx;
    };
    return CardCollision;
}());
export default CardCollision;
