export var PileFacing;
(function (PileFacing) {
    PileFacing[PileFacing["Any"] = 0] = "Any";
    PileFacing[PileFacing["ForceFaceUp"] = 1] = "ForceFaceUp";
    PileFacing[PileFacing["ForceFaceDown"] = 2] = "ForceFaceDown";
})(PileFacing || (PileFacing = {}));
export var EditClickState;
(function (EditClickState) {
    EditClickState[EditClickState["Normal"] = 0] = "Normal";
    EditClickState[EditClickState["DeletingPile"] = 1] = "DeletingPile";
    EditClickState[EditClickState["AddingPile"] = 2] = "AddingPile";
    EditClickState[EditClickState["ChoosingPileToEdit"] = 3] = "ChoosingPileToEdit";
    EditClickState[EditClickState["EditingPile"] = 4] = "EditingPile";
    EditClickState[EditClickState["TogglingUser"] = 5] = "TogglingUser";
})(EditClickState || (EditClickState = {}));
export var PlaceholderStyle;
(function (PlaceholderStyle) {
    PlaceholderStyle[PlaceholderStyle["None"] = 0] = "None";
    PlaceholderStyle[PlaceholderStyle["Single"] = 1] = "Single";
    PlaceholderStyle[PlaceholderStyle["Wide"] = 2] = "Wide";
    PlaceholderStyle[PlaceholderStyle["Tall"] = 3] = "Tall";
})(PlaceholderStyle || (PlaceholderStyle = {}));
export var UXMode;
(function (UXMode) {
    // No special user interaction for this pile.
    UXMode[UXMode["Default"] = 0] = "Default";
    // Only interact with top cards, and double-click to draw multiple cards. Also
    // takes longer to pick up the whole pile.
    // uxMode: draw
    UXMode[UXMode["Draw"] = 1] = "Draw";
    // Expand on double-click, then allow ramdom access to cards.
    // uxMode: expand
    UXMode[UXMode["Expand"] = 2] = "Expand";
    // Peek at the card the mouse is hovering over (or you tap once via touch).
    // uxMode: peek
    UXMode[UXMode["PeekCard"] = 4] = "PeekCard";
    // Zoom the card is hovering over (or you tap once via touch)
    // uxMode: zoom
    UXMode[UXMode["ZoomCard"] = 8] = "ZoomCard";
    // Shuffle this pile on game reset.
    // uxMode: shuffle
    UXMode[UXMode["ShuffleOnReset"] = 16] = "ShuffleOnReset";
    // Make easier to drop cards into.
    // uxMode: greedy
    UXMode[UXMode["GreedyCollision"] = 32] = "GreedyCollision";
    // Flip the top card face-up (assuming a face-down pile).
    // uxMode: flipTop
    UXMode[UXMode["FlipTopCard"] = 64] = "FlipTopCard";
    // In this pile, cards are always sorted in order.
    // uxMode: sort
    UXMode[UXMode["SortCards"] = 128] = "SortCards";
    // This pile can only have a single card in it.
    // uxMode: oneCard
    UXMode[UXMode["OneCard"] = 256] = "OneCard";
    // This pile operates in auto-tableau mode: When it has at least one
    // card in it, it creates an empty adjacent child pile. The same holds
    // for the adjacent child pile as well.
    // uxMode: autoTableau
    UXMode[UXMode["AutoTableau"] = 512] = "AutoTableau";
    // Allow the owner of this pile to secretly view cards in it. Any card
    // they mouse-over is flipped over (but just for them).
    // uxMode: secretView
    UXMode[UXMode["SecretlyViewCard"] = 1024] = "SecretlyViewCard";
    // This pile and its cards are hidden from view (except in edit mode).
    // uxMode: hidden
    UXMode[UXMode["Hidden"] = 2048] = "Hidden";
    // This pile cannot be dragged onto nor from.
    // uxMode: locked
    UXMode[UXMode["Locked"] = 4096] = "Locked";
})(UXMode || (UXMode = {}));
