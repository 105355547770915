import { __awaiter, __generator } from "tslib";
import 'firebase/auth';
import 'firebase/firestore';
import constants from './constants';
import { gameConverter, pileStyleConverter, sheetConverter } from './converters';
import { firebase } from './firebase';
import Game from './Game';
import { logReadOperation } from './utils';
// Function to unsubscribe from 'games'. Will be null if
// we are not subscribed.
var gameUnsubscribe = null;
var canvas = document.querySelector('canvas');
/**
 * Holds asset data.
 */
var sheetAssets = new Map();
var pileStyles = new Map();
/**
 * Holds game data.
 */
var gameData = null;
var useSessionCacheForGameData = false;
export function gamePage(gameId, currentUser) {
    firebase.analytics().logEvent('level_start', { 'level_end': gameId });
    if (useSessionCacheForGameData) {
        loadInitialGameDataFromSessionCache(gameId, currentUser);
    }
    gameUnsubscribe = subscribeToGame(gameId, currentUser);
    return gameUnsubscribe;
}
/**
 * Clear game, run when logging out, changing games, etc.
 */
export function clearGame() {
    firebase.analytics().logEvent('level_end', { 'level_end': gameData === null || gameData === void 0 ? void 0 : gameData.id });
    // TODO: Clear canvas.
    sheetAssets = new Map();
    pileStyles = new Map();
    gameData = null;
    if (gameUnsubscribe) {
        gameUnsubscribe();
        gameUnsubscribe = null;
    }
}
/**
 * One-time fetch of a sheet document. Stores in local storage.
 * @param sheetId Sheet Firestore document ID.
 */
function fetchSheet(sheetId) {
    return __awaiter(this, void 0, void 0, function () {
        var cachedData, sheetData, docSnap, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    try {
                        cachedData = localStorage.getItem("sheets/" + sheetId);
                    }
                    catch (e) {
                        firebase.analytics().logEvent('sheet_data_cache_read_failed');
                    }
                    if (cachedData) {
                        firebase.analytics().logEvent('sheet_data_cache_read_success', { bytes: cachedData.length });
                        try {
                            sheetData = JSON.parse(cachedData);
                            sheetAssets.set(sheetId, sheetData);
                        }
                        catch (e) {
                            firebase.analytics().logEvent('sheet_data_cache_parse_failed', { exception_message: e.message });
                        }
                    }
                    if (!!sheetAssets.has(sheetId)) return [3 /*break*/, 2];
                    logReadOperation('fetchSheet');
                    return [4 /*yield*/, firebase.firestore()
                            .collection('sheets')
                            .doc(sheetId)
                            .withConverter(sheetConverter)
                            .get()];
                case 1:
                    docSnap = _a.sent();
                    data = docSnap.data();
                    if (data) {
                        try {
                            localStorage.setItem("sheets/" + sheetId, JSON.stringify(data));
                            firebase.analytics().logEvent('sheet_data_cache_write_success');
                        }
                        catch (e) {
                            // localStorage failed, we might be over our size limit, clear it out so
                            // we can cache newer stuff.
                            try {
                                firebase.analytics().logEvent('sheet_data_cache_write_failed');
                                localStorage.clear();
                                localStorage.setItem("sheets/" + sheetId, JSON.stringify(data));
                            }
                            catch (e2) {
                                firebase.analytics().logEvent('sheet_data_cache_write_failed_after_clear');
                            }
                        }
                        sheetAssets.set(sheetId, data);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
/**
 * One-time fetch of all pile styles.
 */
function fetchPileStyles() {
    return __awaiter(this, void 0, void 0, function () {
        var didFetchCached, cachedData, pileStylesArray, collection, pileStylesArray;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    didFetchCached = false;
                    if (constants.CACHE_PILE_STYLES) {
                        try {
                            cachedData = sessionStorage.getItem("pileStyles");
                        }
                        catch (e) {
                            firebase.analytics().logEvent('pile_styles_cache_read_failed');
                        }
                        if (cachedData) {
                            try {
                                pileStylesArray = JSON.parse(cachedData);
                                pileStylesArray.forEach(function (element) { return pileStyles.set(element[0], element[1]); });
                                didFetchCached = true;
                                firebase.analytics().logEvent('pile_styles_cache_read_success', { 'bytes': cachedData.length });
                            }
                            catch (e) {
                                firebase.analytics().logEvent('pile_styles_cache_parse_failed', { 'exception_message': e.message });
                            }
                        }
                        ;
                    }
                    else {
                        firebase.analytics().logEvent('pile_styles_cache_read_skipped');
                    }
                    if (!!didFetchCached) return [3 /*break*/, 2];
                    logReadOperation('fetchPileStyles');
                    return [4 /*yield*/, firebase.firestore()
                            .collection('pileStyles')
                            .withConverter(pileStyleConverter)
                            .get()];
                case 1:
                    collection = _a.sent();
                    collection.forEach(function (snapshot) {
                        if (snapshot.data()) {
                            pileStyles.set(snapshot.id, snapshot.data());
                        }
                    });
                    if (constants.CACHE_PILE_STYLES) {
                        pileStylesArray = Array.from(pileStyles.keys()).map(function (key) { return [key, pileStyles.get(key)]; });
                        try {
                            sessionStorage.setItem("pileStyles", JSON.stringify(pileStylesArray));
                            firebase.analytics().logEvent('pile_styles_cache_write_success');
                        }
                        catch (e) {
                            // Failed to save to session storage, oh well.
                            firebase.analytics().logEvent('pile_styles_cache_write_failed', { 'exception_message': e.message });
                            try {
                                sessionStorage.clear();
                                sessionStorage.setItem("pileStyles", JSON.stringify(pileStylesArray));
                            }
                            catch (e2) {
                                firebase.analytics().logEvent('pile_styles_cache_write_failed_after_clear', { 'exception_message': e2.message });
                            }
                        }
                    }
                    else {
                        firebase.analytics().logEvent('pile_styles_cache_write_skipped');
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
function loadInitialGameDataFromSessionCache(gameId, user) {
    var cachedData;
    try {
        cachedData = sessionStorage.getItem("gameData/" + gameId);
        firebase.analytics().logEvent('game_data_cache_read_success');
    }
    catch (e) {
        firebase.analytics().logEvent('game_data_cache_read_failure', { exception_message: e.message });
    }
    if (cachedData) {
        try {
            finishReadingGameData(JSON.parse(cachedData), user);
            firebase.analytics().logEvent('game_data_cache_parse_success');
            return true;
        }
        catch (e) {
            firebase.analytics().logEvent('game_data_cache_parse_failure', { exception_message: e.message });
        }
    }
    return false;
}
function saveGameDataToSessionCache(gameData) {
    // Cache game data in the session cache in case the user exits the game and
    // goes back in, it will load faster.
    try {
        sessionStorage.setItem("gameData/" + gameData.id, JSON.stringify(gameData));
    }
    catch (e) {
        firebase.analytics().logEvent('game_data_cache_write_failed', { 'exception_message': e.message });
        try {
            sessionStorage.clear();
            sessionStorage.setItem("gameData/" + gameData.id, JSON.stringify(gameData));
        }
        catch (e2) {
            firebase.analytics().logEvent('game_data_cache_write_failed_after_clear', { 'exception_message': e2.message });
        }
    }
}
/**
 * Subscribes to Firestore collection `sheets` and calls callback
 * provided to`onSnapshot` every time collection data changes.
 *
 * Returns function to unsubscribe.
 */
function subscribeToGame(gameId, user) {
    var _this = this;
    return firebase.firestore()
        .collection('games')
        .doc(gameId)
        .withConverter(gameConverter)
        .onSnapshot(function (docSnap) { return __awaiter(_this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    logReadOperation('updateGameData');
                    data = docSnap.data();
                    if (!data) return [3 /*break*/, 2];
                    gameData = data;
                    if (useSessionCacheForGameData) {
                        saveGameDataToSessionCache(gameData);
                    }
                    return [4 /*yield*/, finishReadingGameData(gameData, user)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); });
}
export function updateBreadcrumb(description) {
    var _a;
    var titleEl = document.getElementById('header-title');
    var previousPageEls = document.querySelectorAll('.page-breadcrumb');
    previousPageEls.forEach(function (el) {
        var _a;
        (_a = el.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(el);
    });
    var pageEl = document.createElement('div');
    pageEl.classList.add('page-breadcrumb');
    pageEl.textContent = description;
    (_a = titleEl === null || titleEl === void 0 ? void 0 : titleEl.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(pageEl);
}
function finishReadingGameData(gameData, user) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updateBreadcrumb(gameData.description);
                    if (!(sheetAssets.get(gameData.sheetId) == null)) return [3 /*break*/, 2];
                    // Wait to load the sheet before updating the game.
                    return [4 /*yield*/, fetchSheet(gameData.sheetId)];
                case 1:
                    // Wait to load the sheet before updating the game.
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (!(pileStyles.size == 0)) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetchPileStyles()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, updateGame(gameData, user)];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function unsubscribeFromGame() {
    if (gameUnsubscribe) {
        gameUnsubscribe();
        gameUnsubscribe = null;
    }
}
export function resubscribeToGame(gameId, user) {
    gameUnsubscribe = subscribeToGame(gameId, user);
    return gameUnsubscribe;
}
var game;
var gameButtons;
function updateGame(gameData, user) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (game == undefined || gameData.id != game.gameId) {
                        game = new Game(gameData.id, sheetAssets.get(gameData.sheetId), pileStyles, canvas, user, gameData);
                    }
                    return [4 /*yield*/, game.processGameData(gameData)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function getGameInstance() {
    return game;
}
