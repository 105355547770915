import { __awaiter, __generator } from "tslib";
import 'firebase/firestore';
import 'firebase/firebase-storage';
import { loadImage } from './canvas-utils';
import constants from './constants';
import { gameConverter, sheetConverter } from './converters';
import { createElement } from './dom-helpers';
import { firebase } from './firebase';
import { logWriteOperation } from './utils';
var storage = firebase.storage();
var SheetEditor = /** @class */ (function () {
    function SheetEditor(uid, topEl) {
        this.cardImages = [];
        this.cardBackMultiImages = [];
        this.uploadedCardWidth = 1;
        this.uploadedCardHeight = 1;
        this.renderedCardWidth = constants.SHEET_EDITOR_CARD_WIDTH;
        this.renderedCardHeight = 0;
        this.rowCount = 0;
        this.cardBackPosition = 0;
        this.cutoutRadius = constants.SHEET_EDITOR_CUTOUT_RADIUS;
        this.borderColor = constants.SHEET_EDITOR_BORDER_COLOR;
        this.columnCount = 0;
        this.cropAmount = 0;
        this.borderThickness = 1;
        this.nameInput = '';
        this.uid = uid;
        this.topEl = topEl;
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.renderInitialHtml();
    }
    SheetEditor.prototype.renderInitialHtml = function () {
        var _this = this;
        this.topEl.innerHTML = '';
        var formEl = createElement({ tag: 'form', classes: ['new-game-form'] });
        var titleEl = createElement({ tag: 'h2', text: 'Create a New Game', classes: ['new-game-form'] });
        var nameInputEl = createElement({
            tag: 'input',
            placeholder: 'Game name (Example: "A Test Game")',
            value: this.nameInput || ''
        });
        nameInputEl.addEventListener('input', function (e) { return _this.nameInput = e.target.value; });
        var templateTitleEl = createElement({ tag: 'h3', text: 'Upload deck images' });
        var afterCardFaceUploaded = createElement();
        var cardBackInputContainerEl = createElement({ classes: 'new-game-button-container' });
        var cardBackInput = document.createElement('input');
        cardBackInput.id = 'card-back-input';
        cardBackInput.type = 'file';
        cardBackInput.accept = 'image/*';
        cardBackInput.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cardBackMultiFilesSelected(null)];
                    case 1:
                        _a.sent();
                        this.cardBackMultiImages = [];
                        return [4 /*yield*/, this.cardBackFileSelected(cardBackInput.files)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var cardBackLabel = document.createElement('label');
        cardBackLabel.setAttribute('for', 'card-back-input');
        cardBackLabel.classList.add('file-input-label');
        cardBackLabel.textContent = 'Add Uniform Card Back';
        var cardBackDivider = document.createElement('div');
        cardBackDivider.textContent = 'or';
        cardBackDivider.classList.add('card-back-button-divider');
        var cardsBackMultiInput = document.createElement('input');
        cardsBackMultiInput.id = 'cards-back-multi-input';
        cardsBackMultiInput.type = 'file';
        cardsBackMultiInput.accept = 'image/*';
        cardsBackMultiInput.multiple = true;
        cardsBackMultiInput.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cardBackFileSelected(null)];
                    case 1:
                        _a.sent();
                        this.cardBackImage = undefined;
                        return [4 /*yield*/, this.cardBackMultiFilesSelected(cardsBackMultiInput.files)];
                    case 2:
                        _a.sent();
                        cardsBackMultiInput.value = '';
                        return [2 /*return*/];
                }
            });
        }); };
        var cardsBackMultiLabel = document.createElement('label');
        cardsBackMultiLabel.setAttribute('for', 'cards-back-multi-input');
        cardsBackMultiLabel.classList.add('file-input-label');
        cardsBackMultiLabel.innerHTML =
            'Add Multiple Cards Backs<br>(one per card face)';
        var cardWidthContainerEl = createElement();
        cardWidthContainerEl.classList.add('card-width-input');
        var cardWidthInput = document.createElement('input');
        cardWidthInput.type = 'number';
        cardWidthInput.placeholder = "Card width (defaults to " + this.renderedCardWidth + "px), or 0 to use uploaded size.";
        cardWidthInput.onkeydown = function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                var cardWidth = parseInt(cardWidthInput.value, 10);
                if (!cardWidth) {
                    cardWidth = _this.uploadedCardWidth;
                }
                _this.renderedCardWidth = cardWidth;
                _this.renderedCardHeight = Math.floor(_this.uploadedCardHeight / _this.uploadedCardWidth *
                    _this.renderedCardWidth);
                _this.drawCanvas();
                return false;
            }
        };
        cardWidthInput.onchange = function () {
            _this.renderedCardWidth = parseInt(cardWidthInput.value, 10);
            _this.renderedCardHeight = Math.floor(_this.uploadedCardHeight / _this.uploadedCardWidth *
                _this.renderedCardWidth);
            _this.drawCanvas();
        };
        cardWidthContainerEl.appendChild(cardWidthInput);
        var radiusContainerEl = createElement();
        radiusContainerEl.classList.add('card-width-input');
        var radiusInput = document.createElement('input');
        radiusInput.type = 'number';
        radiusInput.placeholder =
            "Corner radius (defaults to " + this.cutoutRadius + "px), or '0' to remove.";
        radiusInput.onkeydown = function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                _this.cutoutRadius = parseInt(radiusInput.value, 10);
                _this.drawCanvas();
                return false;
            }
        };
        radiusInput.onchange = function () {
            _this.cutoutRadius = parseInt(radiusInput.value, 10);
            _this.drawCanvas();
        };
        radiusContainerEl.appendChild(radiusInput);
        var cropContainerEl = createElement();
        cropContainerEl.classList.add('card-width-input');
        var cropInput = document.createElement('input');
        cropInput.type = 'number';
        cropInput.placeholder = "Crop edges (defaults to " + this.cropAmount + "px).";
        cropInput.onkeydown = function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                _this.cropAmount = parseInt(cropInput.value, 10);
                _this.drawCanvas();
                return false;
            }
        };
        cropInput.onchange = function () {
            _this.cropAmount = parseInt(cropInput.value, 10);
            _this.drawCanvas();
        };
        cropContainerEl.appendChild(cropInput);
        var borderColorContainerEl = createElement();
        borderColorContainerEl.classList.add('card-width-input');
        var borderColorInput = document.createElement('input');
        borderColorInput.placeholder = "Border color (defaults to " + this.borderColor + "), or 'transparent' to remove.";
        borderColorInput.placeholder =
            "Border color (defaults to " + this.borderColor + ")";
        borderColorInput.onkeydown = function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                _this.borderColor = borderColorInput.value;
                _this.drawCanvas();
                return false;
            }
        };
        borderColorInput.onchange = function () {
            _this.borderColor = borderColorInput.value;
            _this.drawCanvas();
        };
        borderColorContainerEl.appendChild(borderColorInput);
        var borderThicknessContainerEl = createElement();
        borderThicknessContainerEl.classList.add('card-width-input');
        var borderThicknessInput = document.createElement('input');
        borderThicknessInput.type = 'number';
        borderThicknessInput.placeholder =
            "Border Thickness (defaults to " + this.borderThickness + "px).";
        borderThicknessInput.onkeydown = function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                _this.borderThickness = parseInt(borderThicknessInput.value, 10);
                _this.drawCanvas();
                return false;
            }
        };
        borderThicknessInput.onchange = function () {
            _this.borderThickness = parseInt(borderThicknessInput.value, 10);
            _this.drawCanvas();
        };
        borderThicknessContainerEl.appendChild(borderThicknessInput);
        var optionsContainerEl = createElement();
        optionsContainerEl.appendChild(cardWidthContainerEl);
        optionsContainerEl.appendChild(radiusContainerEl);
        optionsContainerEl.appendChild(cropContainerEl);
        optionsContainerEl.appendChild(borderColorContainerEl);
        optionsContainerEl.appendChild(borderThicknessContainerEl);
        var buttonContainerEl = createElement({ classes: 'new-game-button-container' });
        var submitButtonEl = createElement({
            tag: 'button',
            classes: 'create-submit-button',
            text: 'Create This Game'
        });
        var cancelButtonEl = createElement({
            tag: 'button',
            classes: 'cancel-button',
            text: 'Cancel',
            type: 'button'
        });
        cancelButtonEl.addEventListener('click', function () {
            window.location.href = '/';
        });
        var cardsFileInput = document.createElement('input');
        cardsFileInput.id = 'cards-file-input';
        cardsFileInput.type = 'file';
        cardsFileInput.accept = 'image/*';
        cardsFileInput.multiple = true;
        if (this.cardImages.length == 0) {
            cardsFileInput.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.cardFilesSelected(cardsFileInput.files)];
                        case 1:
                            _a.sent();
                            afterCardFaceUploaded.append(cardBackInputContainerEl);
                            afterCardFaceUploaded.append(optionsContainerEl);
                            buttonContainerEl.prepend(submitButtonEl);
                            cardsFileInput.value = '';
                            return [2 /*return*/];
                    }
                });
            }); };
        }
        else {
            // Already have images uploaded, enable the buttons now.
            afterCardFaceUploaded.append(cardBackInputContainerEl);
            afterCardFaceUploaded.append(optionsContainerEl);
            buttonContainerEl.prepend(submitButtonEl);
            cardsFileInput.onchange = function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.cardFilesSelected(cardsFileInput.files)];
                        case 1:
                            _a.sent();
                            cardsFileInput.value = '';
                            return [2 /*return*/];
                    }
                });
            }); };
        }
        var cardsFileLabel = document.createElement('label');
        cardsFileLabel.setAttribute('for', 'cards-file-input');
        cardsFileLabel.classList.add('file-input-label');
        cardsFileLabel.textContent = 'Add Card Faces';
        formEl.appendChild(titleEl);
        formEl.appendChild(nameInputEl);
        formEl.appendChild(templateTitleEl);
        formEl.append(cardsFileInput);
        formEl.append(cardsFileLabel);
        formEl.appendChild(afterCardFaceUploaded);
        cardBackInputContainerEl.append(cardBackInput);
        cardBackInputContainerEl.append(cardBackLabel);
        cardBackInputContainerEl.append(cardBackDivider);
        cardBackInputContainerEl.append(cardsBackMultiInput);
        cardBackInputContainerEl.append(cardsBackMultiLabel);
        // formEl.append(cardWidthInput);
        var canvasContainerEl = createElement({ classes: ['create-sheet-canvas-preview'] });
        canvasContainerEl.append(this.canvas);
        formEl.append(canvasContainerEl);
        buttonContainerEl.appendChild(cancelButtonEl);
        if (this.errorMessage) {
            var errorEl = createElement({ text: this.errorMessage, classes: 'new-game-error-message' });
            formEl.appendChild(errorEl);
        }
        formEl.appendChild(buttonContainerEl);
        formEl.addEventListener('submit', function (e) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                e.preventDefault();
                this.validateInput();
                if (!this.errorMessage) {
                    this.saveSheet();
                }
                return [2 /*return*/];
            });
        }); });
        this.topEl.appendChild(formEl);
        this.drawCanvas();
    };
    SheetEditor.prototype.setPathRoundedRectangle = function (ctx, x, y, w, h, r) {
        ctx.moveTo(x + r, y);
        ctx.lineTo(x + w - r, y);
        ctx.arcTo(x + w, y, x + w, y + r, r);
        ctx.lineTo(x + w, y + h - r);
        ctx.arcTo(x + w, y + h, x + w - r, y + h, r);
        ctx.lineTo(x + r, y + h);
        ctx.arcTo(x, y + h, x, y + h - r, r);
        ctx.lineTo(x, y + r);
        ctx.arcTo(x, y, x + r, y, r);
    };
    SheetEditor.prototype.drawImageWithCutoutAndBorder = function (ctx, image, x, y, w, h, r, borderColor, borderWidth, crop) {
        // Mark out the card's rounded corners (if any).
        ctx.save();
        ctx.beginPath();
        if (r > 0) {
            // Cut out rounded corners.
            this.setPathRoundedRectangle(ctx, x, y, w, h, r);
        }
        else {
            // Not rounded.
            ctx.rect(x, y, w, h);
        }
        ctx.closePath();
        ctx.clip();
        ctx.imageSmoothingEnabled = true;
        ctx.drawImage(image, x - crop, y - crop, w + 2 * crop, h + 2 * crop);
        ctx.imageSmoothingEnabled = false;
        var adjust = (borderWidth % 2 == 0) ? 0.5 : 0;
        // Add card border.
        ctx.save();
        ctx.beginPath();
        if (r > 0) {
            this.setPathRoundedRectangle(ctx, x + ((borderWidth - 1) / 2), y + ((borderWidth - 1) / 2), (w - (borderWidth - 1)), (h - (borderWidth - 1)), r - Math.floor(borderWidth / 2));
        }
        else {
            // Not rounded.
            ctx.rect(x + ((borderWidth - 1) / 2), y + ((borderWidth - 1) / 2), (w - (borderWidth - 1)), (h - (borderWidth - 1)));
        }
        ctx.strokeStyle = borderColor;
        ctx.lineWidth = borderWidth;
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
        ctx.restore();
    };
    SheetEditor.prototype.drawCanvas = function () {
        var COL_COUNT = constants.SHEET_EDITOR_COLUMN_COUNT;
        this.columnCount = COL_COUNT;
        var mult = 1;
        if (this.cardBackMultiImages.length > 1)
            mult = 2;
        this.rowCount = Math.ceil((this.cardImages.length * mult + (mult == 1 ? 1 : 0)) / COL_COUNT);
        this.canvas.width = 0;
        this.canvas.height = 0;
        this.canvas.width = COL_COUNT * this.renderedCardWidth;
        this.canvas.height = this.rowCount * this.renderedCardHeight;
        this.ctx.fillStyle = 'transparent';
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        var radius = this.cutoutRadius;
        var border = this.borderColor;
        var w = this.renderedCardWidth;
        var h = this.renderedCardHeight;
        var r = Math.min(radius, w / 2, h / 2);
        var bthickness = Math.min(this.borderThickness >= 1 ? this.borderThickness : 1, Math.ceil(w / 10));
        ;
        var crop = this.cropAmount >= 0 ? this.cropAmount : 0;
        for (var i = 0; i < this.cardImages.length; i++) {
            var cardImage = this.cardImages[i];
            var x = Math.floor((i * mult) % COL_COUNT * w);
            var y = Math.floor(Math.floor((i * mult) / COL_COUNT) * h);
            this.drawImageWithCutoutAndBorder(this.ctx, cardImage, x, y, w, h, r, border, bthickness, crop);
        }
        var singleCardBackImage = this.cardBackImage;
        if (!singleCardBackImage && this.cardBackMultiImages.length == 1) {
            singleCardBackImage = this.cardBackMultiImages[0];
        }
        if (singleCardBackImage) {
            this.cardBackPosition = COL_COUNT * this.rowCount - 1;
            var x = Math.floor((COL_COUNT - 1) * w);
            var y = Math.floor((this.rowCount - 1) * h);
            this.drawImageWithCutoutAndBorder(this.ctx, singleCardBackImage, x, y, w, h, r, border, bthickness, crop);
        }
        else if (this.cardBackMultiImages.length > 1) {
            this.cardBackPosition = -1;
            // Multiple backs, alternate their positions.
            var cardBackImage = void 0;
            // Purposely loop for cardImages worth of images. Reuse the last
            // file if we don't have enough to match for every card front.
            for (var i = 0; i < this.cardImages.length; i++) {
                if (this.cardBackMultiImages.length > i) {
                    cardBackImage = this.cardBackMultiImages[i];
                }
                var x = Math.floor((1 + i * 2) % COL_COUNT * w);
                var y = Math.floor(Math.floor((i * 2) / COL_COUNT) * h);
                this.drawImageWithCutoutAndBorder(this.ctx, cardBackImage, x, y, w, h, r, border, bthickness, crop);
            }
        }
    };
    SheetEditor.prototype.cardBackFileSelected = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var backFile, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!files)
                            return [2 /*return*/];
                        backFile = files.item(0);
                        _a = this;
                        return [4 /*yield*/, loadImage(URL.createObjectURL(backFile))];
                    case 1:
                        _a.cardBackImage = _b.sent();
                        this.drawCanvas();
                        return [2 /*return*/];
                }
            });
        });
    };
    SheetEditor.prototype.cardFilesSelected = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var imagePromises, images;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!files)
                            return [2 /*return*/];
                        imagePromises = Array.from(files).map(function (file) { return loadImage(URL.createObjectURL(file)); });
                        return [4 /*yield*/, Promise.all(imagePromises)];
                    case 1:
                        images = _a.sent();
                        images.forEach(function (image) { return _this.cardImages.push(image); });
                        this.uploadedCardWidth = this.cardImages[0].width;
                        this.uploadedCardHeight = this.cardImages[0].height;
                        this.renderedCardHeight = Math.floor(this.uploadedCardHeight / this.uploadedCardWidth *
                            this.renderedCardWidth);
                        this.drawCanvas();
                        return [2 /*return*/];
                }
            });
        });
    };
    SheetEditor.prototype.cardBackMultiFilesSelected = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var imagePromises, images;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!files)
                            return [2 /*return*/];
                        imagePromises = Array.from(files).map(function (file) { return loadImage(URL.createObjectURL(file)); });
                        return [4 /*yield*/, Promise.all(imagePromises)];
                    case 1:
                        images = _a.sent();
                        images.forEach(function (image) { return _this.cardBackMultiImages.push(image); });
                        this.drawCanvas();
                        return [2 /*return*/];
                }
            });
        });
    };
    SheetEditor.prototype.assertImageSizesMatch = function () {
        var _this = this;
        var imagesToCheck = this.cardImages.slice();
        if (this.cardBackImage) {
            imagesToCheck.push(this.cardBackImage);
        }
        if (imagesToCheck.length < 2)
            return;
        imagesToCheck.forEach(function (card) {
            if (card.width != _this.uploadedCardWidth ||
                card.height != _this.uploadedCardHeight) {
                _this.cardImages = [];
                alert('All card images must be the same size.');
            }
        });
    };
    SheetEditor.prototype.saveSheet = function () {
        return __awaiter(this, void 0, void 0, function () {
            var newSheetRef, userSheetsRef, fileRef;
            var _this = this;
            return __generator(this, function (_a) {
                newSheetRef = firebase.firestore()
                    .collection('sheets')
                    .withConverter(sheetConverter)
                    .doc();
                userSheetsRef = storage.ref().child("/users/" + this.uid + "/sheets");
                fileRef = userSheetsRef.child(newSheetRef.id + '.png');
                this.canvas.toBlob(function (canvasBlob) { return __awaiter(_this, void 0, void 0, function () {
                    var url, allowDataUrlWorkaround, e_1, e_2, gameRef;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!canvasBlob) {
                                    alert('Could not extract image from Canvas');
                                    return [2 /*return*/];
                                }
                                firebase.analytics().logEvent('upload_cardsheet', {
                                    'size': canvasBlob.size,
                                    'num_cards': this.cardImages.length,
                                    'uploaded_card_width': this.uploadedCardWidth,
                                    'uploaded_card_height': this.uploadedCardHeight,
                                    'card_width': this.renderedCardWidth,
                                    'card_height': this.renderedCardHeight,
                                    'back_index': this.cardBackPosition,
                                });
                                allowDataUrlWorkaround = constants.ALLOW_STORAGE_DATAURL_WORKAROUND;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 4, , 5]);
                                return [4 /*yield*/, fileRef.put(canvasBlob)];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, fileRef.getDownloadURL()];
                            case 3:
                                url = _a.sent();
                                return [3 /*break*/, 5];
                            case 4:
                                e_1 = _a.sent();
                                console.log('firebase storage exception: ', e_1);
                                // Until Firebase Storage works, use this workaround.
                                if (allowDataUrlWorkaround) {
                                    firebase.analytics().logEvent('upload_cardsheet_dataurl', { 'exception_message': e_1.message });
                                    url = this.canvas.toDataURL('image/webp');
                                }
                                else {
                                    firebase.analytics().logEvent('upload_cardsheet_failed', { 'exception_message': e_1.message });
                                    this.errorMessage = 'Error uploading card sheet: ' + e_1.message;
                                    this.renderInitialHtml();
                                    return [2 /*return*/];
                                }
                                return [3 /*break*/, 5];
                            case 5:
                                logWriteOperation('saveSheet');
                                _a.label = 6;
                            case 6:
                                _a.trys.push([6, 8, , 9]);
                                return [4 /*yield*/, newSheetRef.set({
                                        backIndex: this.cardBackPosition,
                                        cards: [],
                                        cols: this.columnCount,
                                        count: this.cardImages.length,
                                        rows: this.rowCount,
                                        scale: 1,
                                        url: url,
                                        id: newSheetRef.id
                                    })];
                            case 7:
                                _a.sent();
                                return [3 /*break*/, 9];
                            case 8:
                                e_2 = _a.sent();
                                firebase.analytics().logEvent('save_sheet_failed', { 'exception_message': e_2.message });
                                this.errorMessage = 'Error creating card sheet: ' + e_2.message;
                                this.renderInitialHtml();
                                return [2 /*return*/];
                            case 9:
                                gameRef = firebase.firestore()
                                    .collection('games')
                                    .withConverter(gameConverter)
                                    .doc();
                                logWriteOperation('createGameFromSheet');
                                return [4 /*yield*/, gameRef.set({
                                        id: gameRef.id,
                                        cardScale: 1,
                                        description: this.nameInput,
                                        sheetId: newSheetRef.id,
                                        piles: [],
                                        isTemplate: true,
                                        owner: this.uid,
                                        sourceTemplateId: '',
                                        isPrivate: true,
                                        isEditLocked: true,
                                        isJoinLocked: true,
                                    })];
                            case 10:
                                _a.sent();
                                window.location.href = "/?game=" + gameRef.id;
                                return [2 /*return*/];
                        }
                    });
                }); }, 'image/png');
                return [2 /*return*/];
            });
        });
    };
    SheetEditor.prototype.validateInput = function () {
        if (!this.nameInput) {
            this.errorMessage = 'Please enter a name for this game.';
        }
        else if (!this.cardBackImage && this.cardBackMultiImages.length == 0) {
            this.errorMessage = 'Select a card back image.';
        }
        else if (this.cardImages.length == 0) {
            this.errorMessage = 'You must select at least one card image.';
        }
        else if (this.renderedCardWidth == 0 || this.renderedCardHeight == 0) {
            this.errorMessage = 'Invalid card width or height.';
        }
        else {
            this.errorMessage = undefined;
        }
        this.renderInitialHtml();
    };
    return SheetEditor;
}());
export default SheetEditor;
