import { __assign } from "tslib";
import { shuffleArray } from './actions';
import constants from './constants';
import { getGameInstance } from './game-page';
import { PileFacing, PlaceholderStyle, UXMode } from './types';
import { gameFont, roundedRect } from './utils';
var CardPile = /** @class */ (function () {
    function CardPile(id, posX, posY, pileStyle, sizeHint) {
        this.renderPriority = 0;
        this.hidden = false;
        this.styleOverridden = false;
        this.cards = [];
        this.shuffleAnimState = 0;
        this.expandPileTimer = 0;
        this.highlightColor = '';
        this.cardScaleX = 1;
        this.cardScaleY = 1;
        this.rotation = -1;
        this.labelSize = 0;
        this.dealSettings = Array();
        this.lastEditTimestamp = 0;
        this.id = id;
        this.posX = posX;
        this.posY = posY;
        if (pileStyle) {
            this.pileStyle = __assign({}, pileStyle);
        }
        else {
            this.pileStyle = {
                id: '',
                name: 'Fallback Default',
                description: 'If this is visible, pile styles could not be loaded.',
                splayPctX: constants.FALLBACK_DEFAULT_SPLAY_PCT_X,
                splayPctY: constants.FALLBACK_DEFAULT_SPLAY_PCT_Y,
                splayMult: 1,
                splayMax: 0,
                facing: PileFacing.Any,
                placeholder: PlaceholderStyle.None,
                uxMode: UXMode.Default,
                layer: 0,
                hideFromList: true,
            };
        }
        this.sizeHintOrig = sizeHint;
        this.sizeHint = this.sizeHintOrig;
    }
    CardPile.prototype.setRotation = function (r) {
        this.rotation = r;
        if (this.sizeHintOrig) {
            this.sizeHint = (this.rotation == 90 || this.rotation == 270) ?
                { width: this.sizeHintOrig.height, height: this.sizeHintOrig.width } : __assign({}, this.sizeHintOrig);
        }
    };
    CardPile.prototype.getRotation = function () {
        return this.rotation;
    };
    CardPile.prototype.addCard = function (card) {
        this.cards.push(card);
        if (this.pileStyle.facing != PileFacing.Any) {
            card.setFaceUp(this.pileStyle.facing == PileFacing.ForceFaceUp);
        }
        if (this.rotation != -1) {
            card.setRotation(this.rotation);
        }
        if (card.isDragEnding() > 0 &&
            (this.lastEditTimestamp > card.lastEditTimestamp ||
                card.lastEditTimestamp == 0)) {
            // If it was already dropped by another player since we last updated the
            // card, make the drop immediate.
            card.clearDragPosition();
        }
        if (this.id == '_drag') {
            card.isBeingDragged = true;
        }
        else {
            card.cardPile = this;
        }
        card.lastEditTimestamp = this.lastEditTimestamp;
    };
    CardPile.prototype.removeCard = function (card) {
        this.cards.splice(this.cards.indexOf(card), 1);
        if (this.id == '_drag') {
            // Don't allow rotation or flipping immediately after dragging.
            // TODO: This issue could be fixed if, instead of replacing the
            // whole `piles` object, we actually just modified things in the
            // transaction.
            card.pauseRotation();
            card.isBeingDragged = false;
        }
        else {
            card.cardPile = undefined;
        }
    };
    CardPile.prototype.saveAnimState = function () {
        // Save this pile's animation state before destroying it.
        return {
            state: this.shuffleAnimState,
            by: this.shufflingBy,
            count: this.cards.length,
        };
    };
    CardPile.prototype.restoreAnimState = function (data) {
        // Restore this pile's animation state after recreating it. Only if the # of
        // cards is unchanged.
        if (this.cards.length == data.count) {
            this.shuffleAnimState = data.state;
            this.shufflingBy = data.by;
        }
    };
    CardPile.prototype.removeAllCards = function () {
        while (this.cards.length > 0) {
            this.removeCard(this.cards[0]);
        }
    };
    CardPile.prototype.getCard = function (idx) {
        return this.cards[idx];
    };
    CardPile.prototype.numCards = function () {
        return this.cards.length;
    };
    CardPile.prototype.findCard = function (id) {
        // returns index or -1
        for (var i = 0; i < this.cards.length; i++) {
            if (this.cards[i].id == id)
                return i;
        }
        return -1;
    };
    CardPile.prototype.hasEntirelyFaceUp = function (b) {
        for (var i = 0; i < this.cards.length; i++) {
            if (this.cards[i].isFaceUp() != b)
                return false;
        }
        return true;
    };
    CardPile.prototype.canAcceptCards = function () {
        if (this.pileStyle.uxMode & UXMode.Locked)
            return false;
        if ((this.pileStyle.uxMode & UXMode.OneCard) && this.numCards() == 1)
            return false;
        return true;
    };
    CardPile.prototype.canReleaseCards = function () {
        if (this.pileStyle.uxMode & UXMode.Locked)
            return false;
        return true;
    };
    CardPile.prototype.getCardIds = function () {
        var ids = new Array();
        for (var i = 0; i < this.numCards(); i++) {
            ids.push(this.getCard(i).id);
        }
        return ids;
    };
    CardPile.prototype.centerPos = function () {
        return {
            x: this.posX + this.sizeHint.width / 2,
            y: this.posY + this.sizeHint.height / 2
        };
    };
    CardPile.prototype.drawXY = function (ctx, x, y, highlightOnly, drawAnimatingCards) {
        if (highlightOnly === void 0) { highlightOnly = false; }
        if (drawAnimatingCards === void 0) { drawAnimatingCards = false; }
        var needRefresh = false;
        var expandPileMult = 1;
        var splayMult = this.pileStyle.splayMult;
        var splayMax = this.pileStyle.splayMax;
        if (this.expandPileTimer > 0) {
            splayMax = 0;
            splayMult = 1;
            expandPileMult = 6;
            if (!highlightOnly)
                this.expandPileTimer--;
            needRefresh = true;
        }
        var start = 0;
        if (splayMax > 0) {
            start = this.cards.length - splayMax * splayMult;
            if (start < 0) {
                start = 0;
            }
        }
        var cardList = new Array();
        cardList = cardList.concat(this.cards);
        if (this.shuffleAnimState > 0) {
            needRefresh = true;
            if (!highlightOnly)
                this.shuffleAnimState--;
            if (this.shuffleAnimState <= 0) {
                this.shuffleAnimState = 0;
                if (getGameInstance().animation && this.shufflingBy) {
                    getGameInstance().animation.uploadPileShuffling(this.shufflingBy, this.id, false);
                }
                this.shufflingBy = undefined;
            }
            else {
                cardList = shuffleArray(cardList);
            }
        }
        // Adjust the starting X/Y so that the card appears correctly on the pile
        // placeholder.
        var splayPosX = this.sizeHint ?
            -.5 * (this.sizeHint.width / this.cardScaleX - this.sizeHint.width) :
            0;
        var splayPosY = this.sizeHint ? -.5 *
            (this.sizeHint.height / this.cardScaleY - this.sizeHint.height) :
            0;
        if (this.rotation == 90 || this.rotation == 270 && cardList.length > 0) {
            // Adjust card position because a rotated pile is off-center.
            splayPosX += cardList[0].renderSize().height / 2 -
                cardList[0].renderSize().width / 2;
            splayPosY += cardList[0].renderSize().width / 2 -
                cardList[0].renderSize().height / 2;
        }
        for (var i = 0; i < cardList.length; i++) {
            var card = cardList[i];
            if (this.pileStyle.uxMode & UXMode.FlipTopCard) {
                if (i == cardList.length - 1) {
                    // Flip top card the reverse of what it's supposed to be.
                    if (!card.isFaceUp() &&
                        this.pileStyle.facing == PileFacing.ForceFaceDown) {
                        card.setFaceUp(true);
                    }
                    else if (card.isFaceUp() &&
                        this.pileStyle.facing == PileFacing.ForceFaceUp) {
                        card.setFaceUp(false);
                    }
                }
                else {
                    // Flip remaining cards to the correct orientation.
                    if (card.isFaceUp() &&
                        this.pileStyle.facing == PileFacing.ForceFaceDown) {
                        card.setFaceUp(false);
                    }
                    else if (!card.isFaceUp() &&
                        this.pileStyle.facing == PileFacing.ForceFaceUp) {
                        card.setFaceUp(true);
                    }
                }
            }
            var splayX = 0;
            var splayY = 0;
            var origWidth = card.displaySize().width / card.getCardScale().x * this.cardScaleX;
            var origHeight = card.displaySize().height / card.getCardScale().x * this.cardScaleY;
            if (card.getRotation() == 90 || card.getRotation() == 270) {
                var tmp = origWidth;
                origWidth = origHeight;
                origHeight = tmp;
            }
            var maxSplayX = Math.round(origWidth + (card.displaySize().width - origWidth) / 2);
            if (this.pileStyle.splayPctX > 100) {
                maxSplayX *= this.pileStyle.splayPctX / 100;
            }
            var maxSplayY = Math.round(origHeight + (card.displaySize().height - origHeight) / 2);
            if (this.pileStyle.splayPctY > 100) {
                maxSplayY *= this.pileStyle.splayPctY / 100;
            }
            if (!card.peek) {
                if (this.shuffleAnimState > 0) {
                    expandPileMult = Math.max(50 /
                        (1 +
                            Math.max(Math.abs(this.pileStyle.splayPctX), Math.abs(this.pileStyle.splayPctY))), 1);
                }
                splayX = Math.round(expandPileMult * this.pileStyle.splayPctX *
                    card.displaySize().width / 100);
                if (splayX > maxSplayX)
                    splayX = maxSplayX;
                splayY = Math.round(expandPileMult * this.pileStyle.splayPctY *
                    card.displaySize().height / 100);
                if (splayY > maxSplayY)
                    splayY = maxSplayY;
            }
            else {
                if (this.pileStyle.splayPctX >= this.pileStyle.splayPctY) {
                    splayX = maxSplayX;
                }
                else {
                    splayY = maxSplayY;
                }
            }
            if (!this.sizeHintOrig) {
                // If we don't know the card size, grab it now.
                this.sizeHintOrig = {
                    width: card.renderSize().width,
                    height: card.renderSize().height,
                };
            }
            if (!this.sizeHint) {
                this.sizeHint = (this.rotation == 90 || this.rotation == 270) ?
                    {
                        width: this.sizeHintOrig.height,
                        height: this.sizeHintOrig.width
                    } : __assign({}, this.sizeHintOrig);
            }
            var pos = i - start;
            var skipDraw = false;
            if (drawAnimatingCards == card.isDragBeingAnimated()) {
                if (highlightOnly) {
                    var oldAnimState = card.saveAnimState();
                    card.movePosition(x + splayPosX, //(Math.round(pos / splayMult) * splayX),
                    y + splayPosY); //(Math.round(pos / splayMult) * splayY));
                    card.draw(ctx, true);
                    var color = this.highlightColor;
                    // if (drawAnimatingCards && card.getHighlightedBy()) {
                    // color =
                    // getGameInstance().players.getPlayerColor(card.getHighlightedBy()!);
                    //}
                    if (color != '') {
                        card.drawHighlight(ctx, 4, 8, color, color);
                    }
                    card.restoreAnimState(oldAnimState);
                }
                else {
                    card.movePosition(x + splayPosX, //(Math.round(pos / splayMult) * splayX),
                    y + splayPosY); //(Math.round(pos / splayMult) * splayY));
                    if (card.isBeingDragged && this.id != '_drag') {
                        // Skip the cards we are currently dragging, they will be rendered
                        // in the internalPile..
                        skipDraw = true;
                    }
                    if (!skipDraw) {
                        var resetHighlight = false;
                        if (card.getHighlightedBy() == undefined) {
                            resetHighlight = true;
                            card.setHighlightedBy(this.shufflingBy);
                        }
                        needRefresh = card.draw(ctx) || needRefresh;
                        if (resetHighlight) {
                            card.setHighlightedBy(undefined);
                        }
                    }
                }
            }
            if (pos >= 0 && pos % splayMult == 0) {
                splayPosX = splayPosX + splayX;
                splayPosY = splayPosY + splayY;
            }
        }
        return needRefresh;
    };
    CardPile.prototype.drawPlaceholder = function (ctx, dark) {
        var _this = this;
        if (dark === void 0) { dark = false; }
        var color = dark ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.1)';
        var lineWidth = 1;
        var lineColor = 'rgba(0, 0, 0, 0.2)';
        var editingPileId = getGameInstance().editClickStatePileId;
        var editingPile = editingPileId ? getGameInstance().piles.get(editingPileId) : undefined;
        if (editingPileId == this.id) {
            lineWidth = 3;
            lineColor = 'yellow';
        }
        else if (editingPile &&
            editingPile.dealSettings.find(function (setting) { return setting.fromPile == _this.id; })) {
            if (getGameInstance().buttons.getViewingDealSource() != this.id) {
                lineWidth = 2;
                lineColor = '#00ffffd0';
            }
            else {
                lineWidth = 4;
                lineColor = '#00ffffff';
            }
        }
        else if (!dark && this.visibilityList && this.visibilityList.length == 1) {
            color = getGameInstance().players.getPlayerColor(this.visibilityList[0]) +
                '80';
            lineWidth = 3;
            lineColor =
                getGameInstance().players.getPlayerColor(this.visibilityList[0]) +
                    'C0';
        }
        ctx.save();
        ctx.fillStyle = color;
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = lineColor;
        roundedRect(ctx, this.posX, this.posY, this.sizeHint.width, this.sizeHint.height, 8);
        ctx.restore();
        if (this.collisionManager != null) {
            this.collisionManager.addPilePlaceholder(this.posX, this.posX + this.sizeHint.width, this.posY, this.posY + this.sizeHint.height, this);
        }
    };
    CardPile.prototype.isPlaceholderVisible = function () {
        return (this.pileStyle.placeholder != PlaceholderStyle.None && this.sizeHint &&
            (this.cards.length == 0 || this.getCard(0).isBeingDragged ||
                this.getCard(0).isDragBeingAnimated() ||
                this.getCard(0).isAnimating() ||
                this.getCard(0).isRotationAnimating() ||
                (this.getCard(0).getRotation() != 0 &&
                    this.getCard(0).getRotation() != 180)));
    };
    CardPile.prototype.animateShuffle = function (uid, timeout) {
        var _this = this;
        if (timeout === void 0) { timeout = 0; }
        this.shuffleAnimState = constants.SHUFFLE_ANIM_FRAMES;
        this.shufflingBy = uid;
        if (timeout) {
            setTimeout(function () {
                if (_this.shuffleAnimState > 0)
                    _this.shuffleAnimState = 1;
            }, timeout);
        }
    };
    CardPile.prototype.isExpandingPile = function () {
        return (this.expandPileTimer > 0);
    };
    CardPile.prototype.expandPile = function () {
        if (this.expandPileTimer > 0)
            this.expandPileTimer = 0;
        else
            this.expandPileTimer = constants.EXPAND_PILE_TIME_FRAMES;
    };
    CardPile.prototype.wrapText = function (ctx, text, maxWidth) {
        if (!text)
            text = '';
        var words = text.split(' ');
        var lines = [];
        var currentLine = words[0];
        for (var i = 1; i < words.length; i++) {
            var word = words[i];
            var width = ctx.measureText(currentLine + ' ' + word).width;
            if (width < maxWidth) {
                currentLine += ' ' + word;
            }
            else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);
        return lines;
    };
    CardPile.prototype.drawEditMode = function (ctx) {
        var _this = this;
        if (this.pileStyle.placeholder != PlaceholderStyle.None) {
            var margin_1 = 4;
            this.drawPlaceholder(ctx, true);
            ctx.save();
            ctx.fillStyle = 'rgba(255, 255, 255, 1)';
            ctx.lineWidth = 0;
            ctx.strokeStyle = 'solid';
            ctx.font = '13px ' + gameFont;
            ctx.textAlign = 'center';
            var pileStyleName = getGameInstance().pileStyles.get(this.pileStyle.id).name;
            var nameLines = this.wrapText(ctx, pileStyleName, this.sizeHint.width - 2 * margin_1);
            for (var i = 0; i < nameLines.length; i++) {
                var line = nameLines[i];
                ctx.fillText(line, this.posX + this.sizeHint.width / 2, this.posY + (i + 1) * 14 + margin_1 - 2);
            }
            var pileStyleDesc = getGameInstance().pileStyles.get(this.pileStyle.id).description;
            var descStartY = (nameLines.length + 1) * 14 + margin_1 - 4;
            ctx.textAlign = 'left';
            ctx.font = '10px ' + gameFont;
            var descLines = this.wrapText(ctx, this.pileStyle.description, this.sizeHint.width - 2 * margin_1);
            for (var i = 0; i < descLines.length; i++) {
                var line = descLines[i];
                ctx.fillText(line, this.posX + margin_1, this.posY + descStartY + margin_1 + i * 12);
            }
            var descEndY_1 = descStartY + (descLines.length) * 12 + margin_1;
            if (this.styleOverridden) {
                ctx.font = '9px ' + gameFont;
                ctx.fillText('⚙️ Custom pile style', this.posX + margin_1, this.posY + descEndY_1);
                descEndY_1 += 12;
            }
            if (this.visibilityList && this.visibilityList.length > 0) {
                ctx.font = '9px ' + gameFont;
                ctx.fillText('🔒 Visibility locked', this.posX + margin_1, this.posY + descEndY_1);
                descEndY_1 += 12;
            }
            getGameInstance().piles.forEach(function (pile) {
                pile.dealSettings.forEach(function (dealSetting, idx) {
                    if (dealSetting.fromPile == _this.id &&
                        getGameInstance().editClickStatePileId == pile.id) {
                        var num = dealSetting.numCards;
                        var cards = num == 1 ? 'card' : 'cards';
                        var visLock = dealSetting.onlyIfAssignedPlayer ? '🔓' : '';
                        var userLockDesc = '';
                        if (dealSetting.onlyIfPlayerCountAtLeast ||
                            dealSetting.onlyIfPlayerCountAtMost) {
                            if (dealSetting.onlyIfPlayerCountAtLeast ==
                                dealSetting.onlyIfPlayerCountAtMost) {
                                userLockDesc = "" + dealSetting.onlyIfPlayerCountAtLeast;
                            }
                            else if (!dealSetting.onlyIfPlayerCountAtLeast)
                                userLockDesc = "\u2264" + dealSetting.onlyIfPlayerCountAtMost;
                            else if (!dealSetting.onlyIfPlayerCountAtMost)
                                userLockDesc = "\u2265" + dealSetting.onlyIfPlayerCountAtLeast;
                            else
                                userLockDesc = dealSetting.onlyIfPlayerCountAtLeast + "-" + dealSetting.onlyIfPlayerCountAtMost;
                        }
                        var userLock = userLockDesc != '' ? "\uD83D\uDC64" + userLockDesc : '';
                        var ifText = (visLock != '' || userLock != '') ? ' if ' : '';
                        ctx.font = '9px ' + gameFont;
                        ctx.save();
                        ctx.fillStyle = '#00ffffd0';
                        if (idx == getGameInstance().buttons.showDealSettingsNum) {
                            ctx.font = 'bold 9px ' + gameFont;
                            ctx.fillStyle = '#00ffffff';
                        }
                        ctx.fillText("\uD83C\uDCCF Deal " + num + ifText + visLock + userLock, _this.posX + margin_1, _this.posY + descEndY_1);
                        ctx.restore();
                        descEndY_1 += 12;
                    }
                });
            });
            if (this.dealSettings.length > 0) {
                ctx.font = '9px ' + gameFont;
                var visLock_1 = false;
                var playerLimit_1 = false;
                this.dealSettings.forEach(function (setting) {
                    if (setting.onlyIfAssignedPlayer)
                        visLock_1 = true;
                    if (setting.onlyIfPlayerCountAtLeast ||
                        setting.onlyIfPlayerCountAtMost)
                        playerLimit_1 = true;
                });
                ctx.fillText("\uD83C\uDCCF Is dealt cards" + (visLock_1 ? ' 🔓' : '') +
                    (playerLimit_1 ? ' 👤' : ''), this.posX + margin_1, this.posY + descEndY_1);
                descEndY_1 += 12;
            }
            ctx.restore();
        }
    };
    CardPile.prototype.drawHighlight = function (ctx, drawAnimatingCards) {
        if (drawAnimatingCards === void 0) { drawAnimatingCards = false; }
        if (this.hidden)
            return;
        if (this.cards.length > 0) {
            return this.drawXY(ctx, this.posX, this.posY, true, drawAnimatingCards);
        }
    };
    CardPile.prototype.drawLabel = function (ctx) {
        ctx.save();
        if (this.label) {
            var label = this.label;
            if (!getGameInstance().editMode && label.indexOf('$') != -1) {
                // Text substitutions:
                //   $numCards: replaced with '_ card or _ cards'
                //   $num: replaced with just the number.
                var sub = this.numCards() + " card";
                if (this.numCards() != 1)
                    sub += 's';
                label = label.replace(/\$numCards/, sub);
                label = label.replace(/\$num/, "" + this.numCards());
            }
            // if (this.visibilityList && this.visibilityList.length > 0) {
            // label = '🔒 ' + label;
            //}
            var color = 'rgba(255, 255, 255, 0.7)';
            ctx.fillStyle = color;
            ctx.lineWidth = 0;
            ctx.strokeStyle = 'solid';
            var fontSize = this.labelSize > 0 ? this.labelSize : 14;
            ctx.font = fontSize + "px " + gameFont;
            ctx.textAlign = 'left';
            ctx.fillText(label, this.posX, this.posY - Math.ceil(fontSize / 4));
        }
        // For nice visuals, sort shortest to longest name.
        var visibilityFont = '12px ' + gameFont;
        if (this.visibilityList && this.visibilityList.length > 0) {
            var sortedVisList = this.visibilityList
                .filter(function (uid) {
                return getGameInstance().players.getDisplayName(uid) &&
                    getGameInstance().players.getDisplayName(uid) != '';
            })
                .sort(function (uid1, uid2) {
                ctx.font = visibilityFont;
                var p1 = ctx.measureText(getGameInstance().players.getDisplayName(uid1))
                    .width;
                var p2 = ctx.measureText(getGameInstance().players.getDisplayName(uid2))
                    .width;
                if (p1 < p2)
                    return -1;
                if (p2 < p1)
                    return 1;
                return 0;
            });
            for (var i = 0; i < sortedVisList.length; i++) {
                var uid = sortedVisList[i];
                var playerName = getGameInstance().players.getDisplayName(uid);
                var playerColor = getGameInstance().players.getPlayerColor(uid) + 'E0';
                var label = '🔓 ' + playerName;
                ctx.save();
                ctx.translate(this.posX, this.posY);
                ctx.rotate(-Math.PI / 2);
                ctx.fillStyle = playerColor;
                ctx.lineWidth = 0;
                ctx.strokeStyle = 'solid';
                ctx.font = visibilityFont;
                ctx.textAlign = 'left';
                ctx.fillText(label, -this.sizeHint.height + 4, -5 - 15 * (sortedVisList.length - i - 1));
                ctx.restore();
            }
        }
        ctx.restore();
    };
    CardPile.prototype.draw = function (ctx, drawAnimatingCards) {
        if (drawAnimatingCards === void 0) { drawAnimatingCards = false; }
        if (this.hidden)
            return;
        if (this.pileStyle.uxMode & UXMode.Hidden)
            return;
        if (this.cards.length > 0) {
            return this.drawXY(ctx, this.posX, this.posY, false, drawAnimatingCards);
        }
        return false;
    };
    CardPile.prototype.drawPlaceholderIfPresent = function (ctx, drawAnimatingCards) {
        if (drawAnimatingCards === void 0) { drawAnimatingCards = false; }
        if (this.hidden)
            return;
        if (this.pileStyle.uxMode & UXMode.Hidden)
            return;
        if (this.isPlaceholderVisible()) {
            if (!drawAnimatingCards) {
                this.drawPlaceholder(ctx);
            }
        }
    };
    return CardPile;
}());
export default CardPile;
